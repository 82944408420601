import * as Types from '../../types';

import { gql } from '@apollo/client';
import { SupportTicketMessageInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_One_Support_Ticket_MessageMutationVariables = Types.Exact<{
  input: Types.CreateOneSupportTicketMessageInput;
}>;


export type Create_One_Support_Ticket_MessageMutation = { __typename?: 'Mutation', createOneSupportTicketMessage: { __typename?: 'SupportTicketMessage', id: string, idSupportTicket: string, idSender: string, message: string, status: string, createdAt: any, sender: { __typename?: 'User', id: string, role: { __typename?: 'Role', id: string, code: string } } } };

export type Update_Many_Support_Ticket_MessagesMutationVariables = Types.Exact<{
  input: Types.UpdateManySupportTicketMessagesInput;
}>;


export type Update_Many_Support_Ticket_MessagesMutation = { __typename?: 'Mutation', updateManySupportTicketMessages: { __typename?: 'UpdateManyResponse', updatedCount: number } };


export const Create_One_Support_Ticket_MessageDocument = gql`
    mutation CREATE_ONE_SUPPORT_TICKET_MESSAGE($input: CreateOneSupportTicketMessageInput!) {
  createOneSupportTicketMessage(input: $input) {
    ...SupportTicketMessageInfo
  }
}
    ${SupportTicketMessageInfoFragmentDoc}`;
export type Create_One_Support_Ticket_MessageMutationFn = Apollo.MutationFunction<Create_One_Support_Ticket_MessageMutation, Create_One_Support_Ticket_MessageMutationVariables>;

/**
 * __useCreate_One_Support_Ticket_MessageMutation__
 *
 * To run a mutation, you first call `useCreate_One_Support_Ticket_MessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_One_Support_Ticket_MessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneSupportTicketMessageMutation, { data, loading, error }] = useCreate_One_Support_Ticket_MessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_One_Support_Ticket_MessageMutation(baseOptions?: Apollo.MutationHookOptions<Create_One_Support_Ticket_MessageMutation, Create_One_Support_Ticket_MessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_One_Support_Ticket_MessageMutation, Create_One_Support_Ticket_MessageMutationVariables>(Create_One_Support_Ticket_MessageDocument, options);
      }
export type Create_One_Support_Ticket_MessageMutationHookResult = ReturnType<typeof useCreate_One_Support_Ticket_MessageMutation>;
export type Create_One_Support_Ticket_MessageMutationResult = Apollo.MutationResult<Create_One_Support_Ticket_MessageMutation>;
export type Create_One_Support_Ticket_MessageMutationOptions = Apollo.BaseMutationOptions<Create_One_Support_Ticket_MessageMutation, Create_One_Support_Ticket_MessageMutationVariables>;
export const Update_Many_Support_Ticket_MessagesDocument = gql`
    mutation UPDATE_MANY_SUPPORT_TICKET_MESSAGES($input: UpdateManySupportTicketMessagesInput!) {
  updateManySupportTicketMessages(input: $input) {
    updatedCount
  }
}
    `;
export type Update_Many_Support_Ticket_MessagesMutationFn = Apollo.MutationFunction<Update_Many_Support_Ticket_MessagesMutation, Update_Many_Support_Ticket_MessagesMutationVariables>;

/**
 * __useUpdate_Many_Support_Ticket_MessagesMutation__
 *
 * To run a mutation, you first call `useUpdate_Many_Support_Ticket_MessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Many_Support_Ticket_MessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManySupportTicketMessagesMutation, { data, loading, error }] = useUpdate_Many_Support_Ticket_MessagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_Many_Support_Ticket_MessagesMutation(baseOptions?: Apollo.MutationHookOptions<Update_Many_Support_Ticket_MessagesMutation, Update_Many_Support_Ticket_MessagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Many_Support_Ticket_MessagesMutation, Update_Many_Support_Ticket_MessagesMutationVariables>(Update_Many_Support_Ticket_MessagesDocument, options);
      }
export type Update_Many_Support_Ticket_MessagesMutationHookResult = ReturnType<typeof useUpdate_Many_Support_Ticket_MessagesMutation>;
export type Update_Many_Support_Ticket_MessagesMutationResult = Apollo.MutationResult<Update_Many_Support_Ticket_MessagesMutation>;
export type Update_Many_Support_Ticket_MessagesMutationOptions = Apollo.BaseMutationOptions<Update_Many_Support_Ticket_MessagesMutation, Update_Many_Support_Ticket_MessagesMutationVariables>;