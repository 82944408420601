import { BackdropLoader } from "@components/BackdropLoader";
import { FormWrapper } from "@components/Form/Form";
import { MessageBox } from "@components/MessageBox";
import {
  useVerify_TokenQuery,
  useRegenerate_TokenMutation,
  useCreate_User_PasswordMutation,
  useChange_User_PasswordMutation,
} from "@graphql/";
import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
import { useApplicationContext } from "../../context";
import * as yup from "yup";
import { InputProps } from "@src/types";
import { useStyles } from "./styles";
import { passwordRegExp } from "@src/constants";
import { aesEncrypt } from "@utils/encrypt";

type State = "LOADING" | "VALID_TOKEN" | "INVALID_TOKEN" | "REGENERATED_TOKEN";
export const AccountVerification: FC = () => {
  const { t } = useTranslation();
  const { notify } = useApplicationContext();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get("token");
  const action = query.get("action");
  const [state, setState] = useState<State>("LOADING");
  const classes = useStyles();
  const [regenerateToken] = useRegenerate_TokenMutation({
    onCompleted: () => {
      setState("REGENERATED_TOKEN");
      notify({
        message: t("accountVerification.success.sendToken"),
        type: "success",
      });
    },
    onError: () => {
      notify({
        message: t("commons.errorOccured"),
        type: "error",
      });
    },
  });

  const [createUserPassword] = useCreate_User_PasswordMutation({
    onCompleted: () => {
      navigate("/signin");
      notify({
        message: t("accountVerification.success.update"),
        type: "success",
      });
    },
    onError: () => {
      notify({
        message: t("commons.errorOccured"),
        type: "error",
      });
    },
  });

  const [changeUserPassword] = useChange_User_PasswordMutation({
    onCompleted: () => {
      navigate("/signin");
      notify({
        message: t("accountVerification.success.update"),
        type: "success",
      });
    },
    onError: () => {
      notify({
        message: t("commons.errorOccured"),
        type: "error",
      });
    },
  });

  const initialCreateInput = {
    password: "",
    confirmPassword: "",
  };

  const schema = yup
    .object({
      password: yup
        .string()
        .matches(passwordRegExp, t("accountVerification.label.weakPassword"))
        .required(t("accountVerification.label.passwordRequired")),
      confirmPassword: yup
        .string()
        .required(t("accountVerification.label.confirmPasswordRequired")),
    })
    .required();

  const inputs: InputProps[] = [
    {
      name: "password",
      label: t("accountVerification.label.password"),
      type: "password",
      placeholder: t("accountVerification.label.password"),
    },
    {
      name: "confirmPassword",
      label: t("accountVerification.label.confirmPassword"),
      type: "password",
      placeholder: t("accountVerification.label.confirmPassword"),
    },
  ];

  const verifyingTokenQuery = useVerify_TokenQuery({
    skip: !token || !action,
    variables: {
      token: token || "",
      action: action || "",
    },
    onCompleted: () => {
      setState("VALID_TOKEN");
    },
    onError: () => {
      setState("INVALID_TOKEN");
    },
  });

  const handleRegenerateToken = () => {
    setState("LOADING");
    regenerateToken({ variables: { token: token || "" } });
  };

  const handlePasswordCreation = (state: any) => {
    if (state.password !== state.confirmPassword) {
      notify({
        message: t("accountVerification.errors.notTheSamePassword"),
        type: "error",
      });
      return;
    }
    if (verifyingTokenQuery.data?.verifyToken.id && token) {
      switch (action) {
        case "SIGNUP":
        case "FORGOT_PASSWORD":
          createUserPassword({
            variables: {
              idUser: verifyingTokenQuery.data?.verifyToken.id,
              password: aesEncrypt(state.password),
              token,
              type: action,
            },
          });
          break;
        case "CHANGE_PASSWORD":
        case "PASSWORD_EXPIRED":
          changeUserPassword({
            variables: { password: aesEncrypt(state.password) },
          });
          break;

        default:
          break;
      }
    }
  };

  const renders = {
    LOADING: "",
    INVALID_TOKEN: (
      <MessageBox
        message={t("accountVerification.invalidToken")}
        actionBtn={{
          onClick: handleRegenerateToken,
          children: t("accountVerification.regenerateNewToken"),
        }}
      />
    ),
    VALID_TOKEN: (
      <Box className={classes.root}>
        <Card
          className={classes.card}
          sx={{ maxWidth: { lg: "25%", xs: "90%" } }}
        >
          <img className={classes.logo} src={Logo} />
          <CardContent className={classes.cardContent}>
            <Typography variant="h3" mb={3}>
              {action === "CHANGE_PASSWORD"
                ? t("accountVerification.passwordCreation.title")
                : action === "FORGOT_PASSWORD"
                ? t("accountVerification.forgotPassword.title")
                : t("accountVerification.passwordExpired.title")}
            </Typography>
            <Typography variant="subtitle1" mb={2}>
              {t("accountVerification.subtitle")}
            </Typography>
            <FormWrapper
              initialCreateInput={initialCreateInput}
              onSubmit={handlePasswordCreation}
              schema={schema}
              loading={false}
              submitBtnLabel={t("accountVerification.label.createPassword")}
              inputs={inputs}
            />
          </CardContent>
        </Card>
      </Box>
    ),
    REGENERATED_TOKEN: (
      <MessageBox message={t("accountVerification.success.sendToken")} />
    ),
  };

  return (
    <>
      <BackdropLoader open={state === "LOADING"} />
      {renders[state]}
    </>
  );
};
