import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

export const initI18n = () => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(HttpApi)
    .init({
      lng: "fr",
      fallbackLng: "en",
      preload: ["fr"],
      ns: ["ns"],
      defaultNS: "ns",
      backend: {
        loadPath: `https://graphql.fairvisit.fr/locales/{{lng}}/{{ns}}.json`,
      },
      react: {
        useSuspense: true
      },
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });
};
