import * as Types from '../../types';

import { gql } from '@apollo/client';
import { FileInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Update_One_FileMutationVariables = Types.Exact<{
  input: Types.UpdateOneFileInput;
}>;


export type Update_One_FileMutation = { __typename?: 'Mutation', updateOneFile: { __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null } };

export type Update_Many_FilesMutationVariables = Types.Exact<{
  input: Types.UpdateManyFilesInput;
}>;


export type Update_Many_FilesMutation = { __typename?: 'Mutation', updateManyFiles: { __typename?: 'UpdateManyResponse', updatedCount: number } };


export const Update_One_FileDocument = gql`
    mutation UPDATE_ONE_FILE($input: UpdateOneFileInput!) {
  updateOneFile(input: $input) {
    ...FileInfo
  }
}
    ${FileInfoFragmentDoc}`;
export type Update_One_FileMutationFn = Apollo.MutationFunction<Update_One_FileMutation, Update_One_FileMutationVariables>;

/**
 * __useUpdate_One_FileMutation__
 *
 * To run a mutation, you first call `useUpdate_One_FileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_One_FileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneFileMutation, { data, loading, error }] = useUpdate_One_FileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_One_FileMutation(baseOptions?: Apollo.MutationHookOptions<Update_One_FileMutation, Update_One_FileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_One_FileMutation, Update_One_FileMutationVariables>(Update_One_FileDocument, options);
      }
export type Update_One_FileMutationHookResult = ReturnType<typeof useUpdate_One_FileMutation>;
export type Update_One_FileMutationResult = Apollo.MutationResult<Update_One_FileMutation>;
export type Update_One_FileMutationOptions = Apollo.BaseMutationOptions<Update_One_FileMutation, Update_One_FileMutationVariables>;
export const Update_Many_FilesDocument = gql`
    mutation UPDATE_MANY_FILES($input: UpdateManyFilesInput!) {
  updateManyFiles(input: $input) {
    updatedCount
  }
}
    `;
export type Update_Many_FilesMutationFn = Apollo.MutationFunction<Update_Many_FilesMutation, Update_Many_FilesMutationVariables>;

/**
 * __useUpdate_Many_FilesMutation__
 *
 * To run a mutation, you first call `useUpdate_Many_FilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Many_FilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyFilesMutation, { data, loading, error }] = useUpdate_Many_FilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_Many_FilesMutation(baseOptions?: Apollo.MutationHookOptions<Update_Many_FilesMutation, Update_Many_FilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Many_FilesMutation, Update_Many_FilesMutationVariables>(Update_Many_FilesDocument, options);
      }
export type Update_Many_FilesMutationHookResult = ReturnType<typeof useUpdate_Many_FilesMutation>;
export type Update_Many_FilesMutationResult = Apollo.MutationResult<Update_Many_FilesMutation>;
export type Update_Many_FilesMutationOptions = Apollo.BaseMutationOptions<Update_Many_FilesMutation, Update_Many_FilesMutationVariables>;