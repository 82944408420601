import * as Types from '../../types';

import { gql } from '@apollo/client';
import { OrderItemInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_One_Order_ItemMutationVariables = Types.Exact<{
  input: Types.CreateOneOrderItemInput;
}>;


export type Create_One_Order_ItemMutation = { __typename?: 'Mutation', createOneOrderItem: { __typename?: 'OrderItem', id: string, idOrder: string, libelle: string, description: string, quantity: number, unitPrice: number, totalPrice: number, refund: number, createdAt: any, updatedAt: any } };

export type Update_One_Order_ItemMutationVariables = Types.Exact<{
  input: Types.UpdateOneOrderItemInput;
}>;


export type Update_One_Order_ItemMutation = { __typename?: 'Mutation', updateOneOrderItem: { __typename?: 'OrderItem', id: string, idOrder: string, libelle: string, description: string, quantity: number, unitPrice: number, totalPrice: number, refund: number, createdAt: any, updatedAt: any } };

export type Delete_One_Order_ItemMutationVariables = Types.Exact<{
  input: Types.DeleteOneOrderItemInput;
}>;


export type Delete_One_Order_ItemMutation = { __typename?: 'Mutation', deleteOneOrderItem: { __typename?: 'OrderItemDeleteResponse', id?: string | null } };


export const Create_One_Order_ItemDocument = gql`
    mutation CREATE_ONE_ORDER_ITEM($input: CreateOneOrderItemInput!) {
  createOneOrderItem(input: $input) {
    ...OrderItemInfo
  }
}
    ${OrderItemInfoFragmentDoc}`;
export type Create_One_Order_ItemMutationFn = Apollo.MutationFunction<Create_One_Order_ItemMutation, Create_One_Order_ItemMutationVariables>;

/**
 * __useCreate_One_Order_ItemMutation__
 *
 * To run a mutation, you first call `useCreate_One_Order_ItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_One_Order_ItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneOrderItemMutation, { data, loading, error }] = useCreate_One_Order_ItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_One_Order_ItemMutation(baseOptions?: Apollo.MutationHookOptions<Create_One_Order_ItemMutation, Create_One_Order_ItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_One_Order_ItemMutation, Create_One_Order_ItemMutationVariables>(Create_One_Order_ItemDocument, options);
      }
export type Create_One_Order_ItemMutationHookResult = ReturnType<typeof useCreate_One_Order_ItemMutation>;
export type Create_One_Order_ItemMutationResult = Apollo.MutationResult<Create_One_Order_ItemMutation>;
export type Create_One_Order_ItemMutationOptions = Apollo.BaseMutationOptions<Create_One_Order_ItemMutation, Create_One_Order_ItemMutationVariables>;
export const Update_One_Order_ItemDocument = gql`
    mutation UPDATE_ONE_ORDER_ITEM($input: UpdateOneOrderItemInput!) {
  updateOneOrderItem(input: $input) {
    ...OrderItemInfo
  }
}
    ${OrderItemInfoFragmentDoc}`;
export type Update_One_Order_ItemMutationFn = Apollo.MutationFunction<Update_One_Order_ItemMutation, Update_One_Order_ItemMutationVariables>;

/**
 * __useUpdate_One_Order_ItemMutation__
 *
 * To run a mutation, you first call `useUpdate_One_Order_ItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_One_Order_ItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneOrderItemMutation, { data, loading, error }] = useUpdate_One_Order_ItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_One_Order_ItemMutation(baseOptions?: Apollo.MutationHookOptions<Update_One_Order_ItemMutation, Update_One_Order_ItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_One_Order_ItemMutation, Update_One_Order_ItemMutationVariables>(Update_One_Order_ItemDocument, options);
      }
export type Update_One_Order_ItemMutationHookResult = ReturnType<typeof useUpdate_One_Order_ItemMutation>;
export type Update_One_Order_ItemMutationResult = Apollo.MutationResult<Update_One_Order_ItemMutation>;
export type Update_One_Order_ItemMutationOptions = Apollo.BaseMutationOptions<Update_One_Order_ItemMutation, Update_One_Order_ItemMutationVariables>;
export const Delete_One_Order_ItemDocument = gql`
    mutation DELETE_ONE_ORDER_ITEM($input: DeleteOneOrderItemInput!) {
  deleteOneOrderItem(input: $input) {
    id
  }
}
    `;
export type Delete_One_Order_ItemMutationFn = Apollo.MutationFunction<Delete_One_Order_ItemMutation, Delete_One_Order_ItemMutationVariables>;

/**
 * __useDelete_One_Order_ItemMutation__
 *
 * To run a mutation, you first call `useDelete_One_Order_ItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_One_Order_ItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneOrderItemMutation, { data, loading, error }] = useDelete_One_Order_ItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDelete_One_Order_ItemMutation(baseOptions?: Apollo.MutationHookOptions<Delete_One_Order_ItemMutation, Delete_One_Order_ItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_One_Order_ItemMutation, Delete_One_Order_ItemMutationVariables>(Delete_One_Order_ItemDocument, options);
      }
export type Delete_One_Order_ItemMutationHookResult = ReturnType<typeof useDelete_One_Order_ItemMutation>;
export type Delete_One_Order_ItemMutationResult = Apollo.MutationResult<Delete_One_Order_ItemMutation>;
export type Delete_One_Order_ItemMutationOptions = Apollo.BaseMutationOptions<Delete_One_Order_ItemMutation, Delete_One_Order_ItemMutationVariables>;