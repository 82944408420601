import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTypography-root": {
        marginBottom: theme.spacing(1),
      },
      "& .MuiTypography-root span": {
        fontWeight: "bold",
      },
    },
  })
);
export { useStyles };
