import { DEFAULT_PAGING_LIMIT } from "@utils/constants";
import { useState } from "react";

export const useFetchMore = () => {
  const [offsetPaging, setOffsetPaging] = useState<{
    limit: number;
    offset: number;
  }>({
    limit: DEFAULT_PAGING_LIMIT,
    offset: 0,
  });

  const [totalCount, setTotalCount] = useState<number>(-1);
  return {
    offsetPaging,
    setOffsetPaging,
    totalCount,
    setTotalCount,
  };
};
