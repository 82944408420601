import React, { FC } from "react";
import Echarts from "echarts-for-react";

interface LineChartProps {
  labels: string[];
  values: number[];
}

export const LineChart: FC<LineChartProps> = ({ labels, values }) => {
  const option = {
    xAxis: {
      type: "category",
      data: labels,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: values,
        type: "line",
      },
    ],
  };
  return <Echarts option={option} />;
};
