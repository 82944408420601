import { useApplicationContext } from "@src/context";
import { UrlParams } from "@src/types";
import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

export const useSupportTicketsFilter = (params: UrlParams) => {
  const { connectedUser, connectedUserType } = useApplicationContext();
  const term = params.term;
  const supportTicketRef = params.supportTicketRef;
  const getSupportTicketsFilter = () => {
    if (supportTicketRef) {
      return {
        ref: { eq: supportTicketRef },
      };
    }
    switch (connectedUserType) {
      case "INDIVIDUAL":
      case "PROFESSIONNAL":
        return _getCustomerFilter();
      case "SERVICE_CUSTOMER":
        return _getServiceCustomerFilter();
      default:
        break;
    }
  };

  const _getCustomerFilter = () => {
    let filter = {
      idCustomer: { eq: connectedUser?.id },
    };
    if (term) {
      filter = { ...filter, ..._getTermFilter() };
    }
    return filter;
  };

  const _getServiceCustomerFilter = () => {
    let filter = {
      idServiceCustomer: params.status
        ? { eq: params.status === "NOT_ASSIGNED" ? null : connectedUser?.id }
        : undefined,
      status:
        params.status === "ALL_TICKETS"
          ? undefined
          : { eq: params.status === "NOT_ASSIGNED" ? "OPENED" : params.status },
    };
    if (term) {
      filter = { ...filter, ..._getTermFilter() };
    }
    return filter;
  };

  const _getTermFilter = () => {
    if (!term || term.length < 3) {
      return {};
    }
    if (moment(term, "DD/MM/YYYY", true).isValid()) {
      return {
        createdAt: {
          between: {
            lower: moment(term, "DD/MM/YYYY").startOf("d").toISOString(),
            upper: moment(term, "DD/MM/YYYY").endOf("d").toISOString(),
          },
        },
      };
    }
    const filter = {
      or: [
        {
          ref: {
            iLike: `%${term}%`,
          },
        },
        {
          subject: {
            iLike: `%${term}%`,
          },
        },
        {
          description: {
            iLike: `%${term}%`,
          },
        },
        {
          customer: {
            or: [
              {
                firstName: {
                  iLike: `%${term}%`,
                },
              },
              {
                lastName: {
                  iLike: `%${term}%`,
                },
              },
            ],
          },
        },
        {
          serviceCustomer: {
            or: [
              {
                firstName: {
                  iLike: `%${term}%`,
                },
              },
              {
                lastName: {
                  iLike: `%${term}%`,
                },
              },
            ],
          },
        },
      ],
    };
    return filter;
  };
  return { getSupportTicketsFilter };
};
