import * as Types from '../../types';

import { gql } from '@apollo/client';
export type OrderAssignmentInfoFragment = { __typename?: 'OrderAssignment', id: string, idOrder: string, idDeliveryMan: string, status: string };

export const OrderAssignmentInfoFragmentDoc = gql`
    fragment OrderAssignmentInfo on OrderAssignment {
  id
  idOrder
  idDeliveryMan
  status
}
    `;