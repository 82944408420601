import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Find_Opened_EntityQueryVariables = Types.Exact<{
  day: Types.Scalars['Float'];
  time: Types.Scalars['String'];
  idOriginAddress: Types.Scalars['String'];
  term?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type Find_Opened_EntityQuery = { __typename?: 'Query', findOpenedEntity: Array<{ __typename?: 'InfoLegale', id: string, name: string, addresses?: Array<{ __typename?: 'Address', id: string, name: string, city: string, zipcode: string }> | null, user: { __typename?: 'User', id: string } }> };


export const Find_Opened_EntityDocument = gql`
    query FIND_OPENED_ENTITY($day: Float!, $time: String!, $idOriginAddress: String!, $term: String) {
  findOpenedEntity(
    day: $day
    time: $time
    idOriginAddress: $idOriginAddress
    term: $term
  ) {
    id
    name
    addresses {
      id
      name
      city
      zipcode
    }
    user {
      id
    }
  }
}
    `;

/**
 * __useFind_Opened_EntityQuery__
 *
 * To run a query within a React component, call `useFind_Opened_EntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useFind_Opened_EntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFind_Opened_EntityQuery({
 *   variables: {
 *      day: // value for 'day'
 *      time: // value for 'time'
 *      idOriginAddress: // value for 'idOriginAddress'
 *      term: // value for 'term'
 *   },
 * });
 */
export function useFind_Opened_EntityQuery(baseOptions: Apollo.QueryHookOptions<Find_Opened_EntityQuery, Find_Opened_EntityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Find_Opened_EntityQuery, Find_Opened_EntityQueryVariables>(Find_Opened_EntityDocument, options);
      }
export function useFind_Opened_EntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Find_Opened_EntityQuery, Find_Opened_EntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Find_Opened_EntityQuery, Find_Opened_EntityQueryVariables>(Find_Opened_EntityDocument, options);
        }
export type Find_Opened_EntityQueryHookResult = ReturnType<typeof useFind_Opened_EntityQuery>;
export type Find_Opened_EntityLazyQueryHookResult = ReturnType<typeof useFind_Opened_EntityLazyQuery>;
export type Find_Opened_EntityQueryResult = Apollo.QueryResult<Find_Opened_EntityQuery, Find_Opened_EntityQueryVariables>;