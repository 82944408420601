import * as Types from '../../types';

import { gql } from '@apollo/client';
import { FileInfoFragmentDoc } from '../../File/__generated__/fragment';
export type VehicleInfoFragment = { __typename?: 'Vehicle', id: string, name: string, code: string, files: Array<{ __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null }> };

export const VehicleInfoFragmentDoc = gql`
    fragment VehicleInfo on Vehicle {
  id
  name
  code
  files {
    ...FileInfo
  }
}
    ${FileInfoFragmentDoc}`;