import { Modal } from "@components/Modal";
import { Order, useCreate_Payzen_PaymentQuery } from "@graphql/";
import { Box, CircularProgress } from "@mui/material";
import KRGlue from "@lyracom/embedded-form-glue";
import React, { FC, useEffect } from "react";
import { useApplicationContext } from "@src/context";
import { useOrderMutations } from "@components/CreateOrderStepper/useOrderMutations";

interface PayzenFormProps {
  order?: Order;
  open: boolean;
  onClose: () => void;
}

export const PayzenForm: FC<PayzenFormProps> = ({ order, open, onClose }) => {
  const { updateOneOrder } = useOrderMutations();
  const { notify } = useApplicationContext();
  const createPayenPaymentQuery = useCreate_Payzen_PaymentQuery({
    fetchPolicy: "network-only",
    skip:  !open || !order?.id,
    variables: { idOrder: order?.id || "" },
  });
  const formToken =
    createPayenPaymentQuery.data?.createPayzenPayment.answer.formToken;

  useEffect(() => {
    const initPayzenForm = async () => {
      const { KR } = await KRGlue.loadLibrary(
        "https://api.payzen.eu/api-payment/V4/Charge/CreatePayment",
        process.env.REACT_APP_PAYZEN_PUBLIC_KEY as string
      );

      await KR.setFormConfig({
        formToken: formToken,
        "kr-language": "fr-FR",
      });

      await KR.onSubmit((paymentData) => {
        if (paymentData.clientAnswer.orderStatus === "PAID") {
          updateOneOrder({
            variables: {
              input: {
                id: order?.id || "",
                update: { status: "PAID" },
              },
            },
          });
          notify({
            message: "Votre commande a bien été payé",
            type: "success",
          });
        } else {
          notify({
            message:
              "Une erreur s'est produite lors du payement de votre commande",
            type: "error",
          });
        }
        onClose();
      });

      const { result } = await KR.attachForm("#myPaymentForm");

      await KR.showForm(result.formId);
    };
    if (formToken) {
      initPayzenForm();
    }
  }, [formToken]);
  return (
    <Modal
      open={open}
      title={`Payement de la commande n° ${order?.ref}`}
      onClose={onClose}
      maxWidth="sm"
    >
      <Box display="flex" justifyContent="center">
        {formToken ? (
          <div id="myPaymentForm">
            <div className="kr-smart-form" kr-card-form-expanded="true" kr-hide-debug-toolbar="false"></div>
          </div>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Modal>
  );
};
