import { FormWrapper } from "@components/Form/Form";
import {
  BankAccount as BankAccountType,
  useBank_AccountsQuery,
  File as FileType,
} from "@graphql/";
import { Box, Grid, Typography } from "@mui/material";
import { InputProps } from "@src/types";
import React, { ChangeEvent, FC, useState } from "react";
import * as yup from "yup";
import { useBankAccountMutations } from "./useBankAccountMutations";
import { useFilesMutations } from "@hooks/";
import { FileInput } from "@components/FileInput";
import { useApplicationContext } from "@src/context";
import { ibanRegExp } from "@src/constants";
import { useTranslation } from "react-i18next";

interface BankAccountProps {}

interface FileState {
  kbis: File | FileType | undefined;
  contrat: File | FileType | undefined;
  rib: File | FileType | undefined;
}

export const BankAccount: FC<BankAccountProps> = ({}) => {
  const { t } = useTranslation();

  const [bankAccount, setBankAccount] = useState<BankAccountType | null>(null);
  const initialFiles = {
    kbis: undefined,
    contrat: undefined,
    rib: undefined,
  };
  const [files, setFiles] = useState<FileState>(initialFiles);
  const { connectedUser, refetchConnectedUser } = useApplicationContext();

  const {
    createOneBankAccount,
    updateOneBankAccount,
    creatingOneBankAccount,
    updatingOneBankAccount,
  } = useBankAccountMutations();

  const { uploadFiles, isUploading } = useFilesMutations();

  const loading =
    creatingOneBankAccount.loading ||
    updatingOneBankAccount.loading ||
    isUploading;

  const idType = bankAccount?.id || "";

  useBank_AccountsQuery({
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data.bankAccounts.nodes[0]) {
        const bankAccount = data.bankAccounts.nodes[0];
        const files = bankAccount?.files;
        setBankAccount(bankAccount as BankAccountType);
        if (files.length) {
          const kbis = files.find((file) => file.name === "KBIS");
          const contrat = files.find((file) => file.name === "CONTRAT");
          const rib = files.find((file) => file.name === "RIB");

          setFiles({
            kbis: kbis && { ...kbis, name: kbis.filename },
            contrat: contrat && { ...contrat, name: contrat.filename },
            rib: rib && {
              ...rib,
              name: rib.filename,
            },
          });
        }
      }
    },
  });

  const isInstanceOfFile = (
    key: keyof FileState,
    files: FileState,
    name: string,
    idType: string
  ) => {
    return files[key] instanceof File
      ? [
          {
            file: files[key],
            name,
            filename: files[key]?.name || "",
            type: "BANK_ACCOUNT",
            idType,
            idUser: connectedUser?.id,
            status: "NOT_VERIFIED",
          },
        ]
      : [];
  };

  const handleSubmitBankAccount = async (state: any) => {
    if (bankAccount) {
      const toUploadFiles = [
        ...isInstanceOfFile("kbis", files, "KBIS", idType),
        ...isInstanceOfFile("contrat", files, "CONTRAT", idType),
        ...isInstanceOfFile("rib", files, "RIB", idType),
      ];

      if (toUploadFiles.length) {
        await uploadFiles(toUploadFiles);
      }
      await updateOneBankAccount({
        variables: {
          input: {
            id: bankAccount.id,
            update: {
              iban: state.iban,
              swift: state.swift,
            },
          },
        },
      });
    } else {
      createOneBankAccount({
        variables: {
          input: {
            bankAccount: {
              iban: state.iban,
              swift: state.swift,
              idUser: connectedUser?.id,
            },
          },
        },
      }).then((response) => {
        const idType = response.data?.createOneBankAccount.id;
        if (idType) {
          const toUploadFiles = [
            ...isInstanceOfFile("kbis", files, "KBIS", idType),
            ...isInstanceOfFile("contrat", files, "CONTRAT", idType),
            ...isInstanceOfFile("rib", files, "RIB", idType),
          ];

          if (toUploadFiles.length) {
            uploadFiles(toUploadFiles);
          }
        }
      });
    }
    refetchConnectedUser();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files, name } = e.target;
    setFiles((prev) => ({ ...prev, [name]: files && files[0] }));
  };

  const initialCreateInput = {
    iban: "",
    swift: "",
    payoutFrequency: "WEEKLY",
  };

  const schema = yup
    .object({
      iban: yup
        .string()
        .matches(ibanRegExp, t("bankAccount.errors.incorrectIban"))
        .required(t("bankAccount.errors.requiredIban")),
      swift: yup.string().required(t("bankAccount.errors.swiftIsRequired")),
    })
    .required();

  const inputs: InputProps[] = [
    {
      name: "iban",
      label: t("bankAccount.label.iban"),
      type: "text",
      placeholder: "FR1420041010050500013M02606",
      disabled: bankAccount?.iban !== undefined,
    },
    {
      name: "swift",
      label: t("bankAccount.label.swift"),
      type: "text",
      placeholder: "SWIFT",
    },
    // {
    //   name: "payoutFrequency",
    //   label: "Fréquence de paiement",
    //   type: "select",
    //   placeholder: "Fréquence de paiement",
    //   options: payoutFrequencies,
    // },
  ];

  return (
    <Grid container display="flex" justifyContent="center">
      <Grid item xs={12} sm={6}>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" textAlign="center" color="primary" mb={2}>
            {t("bankAccount.title")}
          </Typography>
          <Typography>{t("bankAccount.description")}:</Typography>
          <FileInput
            file={files.contrat}
            label={t("bankAccount.label.contract")}
            name="contrat"
            onFileChange={handleFileChange}
          />
          <FileInput
            file={files.kbis}
            label={t("bankAccount.label.kbis")}
            name="kbis"
            onFileChange={handleFileChange}
          />
          <FileInput
            file={files.rib}
            label={t("bankAccount.label.rib")}
            name="rib"
            onFileChange={handleFileChange}
          />
        </Box>
        <FormWrapper
          element={bankAccount}
          initialCreateInput={initialCreateInput}
          onSubmit={handleSubmitBankAccount}
          schema={schema}
          loading={loading}
          inputs={inputs}
          submitBtnLabel={t("commons.save")}
        />
      </Grid>
    </Grid>
  );
};
