import React, { FC } from "react";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

interface StatusChipProps {
  status: string;
}

export const StatusChip: FC<StatusChipProps> = ({ status }) => {
  const { t } = useTranslation();
  const statuses = {
    NOT_VERIFIED: {
      label: t("file.statuses.NOT_VERIFIED"),
      color: "#9e9e9e",
    },
    VALID: {
      label: t("file.statuses.VALID"),
      color: "#4caf50",
    },
    INVALID: {
      label: t("file.statuses.INVALID"),
      color: "#f44336",
    },
  };

  return (
    <Chip
      label={statuses[status as keyof typeof statuses].label}
      sx={{
        color: "#FFFFFF",
        background: statuses[status as keyof typeof statuses].color,
      }}
    />
  );
};
