import * as Types from '../../types';

import { gql } from '@apollo/client';
import { OpeningTimeInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_One_Opening_TimeMutationVariables = Types.Exact<{
  input: Types.CreateOneOpeningTimeInput;
}>;


export type Create_One_Opening_TimeMutation = { __typename?: 'Mutation', createOneOpeningTime: { __typename?: 'OpeningTime', id: string, days: any } };


export const Create_One_Opening_TimeDocument = gql`
    mutation CREATE_ONE_OPENING_TIME($input: CreateOneOpeningTimeInput!) {
  createOneOpeningTime(input: $input) {
    ...OpeningTimeInfo
  }
}
    ${OpeningTimeInfoFragmentDoc}`;
export type Create_One_Opening_TimeMutationFn = Apollo.MutationFunction<Create_One_Opening_TimeMutation, Create_One_Opening_TimeMutationVariables>;

/**
 * __useCreate_One_Opening_TimeMutation__
 *
 * To run a mutation, you first call `useCreate_One_Opening_TimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_One_Opening_TimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneOpeningTimeMutation, { data, loading, error }] = useCreate_One_Opening_TimeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_One_Opening_TimeMutation(baseOptions?: Apollo.MutationHookOptions<Create_One_Opening_TimeMutation, Create_One_Opening_TimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_One_Opening_TimeMutation, Create_One_Opening_TimeMutationVariables>(Create_One_Opening_TimeDocument, options);
      }
export type Create_One_Opening_TimeMutationHookResult = ReturnType<typeof useCreate_One_Opening_TimeMutation>;
export type Create_One_Opening_TimeMutationResult = Apollo.MutationResult<Create_One_Opening_TimeMutation>;
export type Create_One_Opening_TimeMutationOptions = Apollo.BaseMutationOptions<Create_One_Opening_TimeMutation, Create_One_Opening_TimeMutationVariables>;