import { AccountLimited } from "@components/AccountLimited/AccountLimited";
import { DeliveryDetails } from "@components/DeliveryDetails";
import { MessagerieDrawer } from "@components/MessagerieDrawer";
import { NavBar } from "@components/NavBar";
import { SideNav } from "@components/SideNav";
import { UserForm } from "@components/UserForm";
import {
  Order,
  useFindDeliveryManPositionSubscription,
  useOrderLazyQuery,
  User,
  useReceiveApplicationSupportSubscription,
  useSend_My_PositionMutation,
} from "@graphql/";
import {
  Box,
  CircularProgress,
  CssBaseline,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useApplicationContext } from "@src/context";
import { useUsersMutations } from "@src/hooks";
import moment from "moment";
import React, { FC, Suspense, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
const Dashboard: FC = () => {
  const token = localStorage.getItem("token");
  const { connectedUser, isLimitedAccount } = useApplicationContext();
  const user = connectedUser?.birthDate
    ? {
        ...connectedUser,
        birthDate: moment(connectedUser.birthDate).format("YYYY-MM-DD"),
      }
    : connectedUser;
  const { updateUser } = useUsersMutations();
  const [order, setOrder] = useState<Order>();
  const [idOrderAssignment, setIdOrderAssignment] = useState<string | null>(
    null
  );
  const [sendMyPosition] = useSend_My_PositionMutation();
  const [getOrder] = useOrderLazyQuery({
    onCompleted: (data) => {
      if (data.order) {
        setOrder(data.order as Order);
        setOpenApplicationSupportForm(true);
      }
    },
  });
  const navigate = useNavigate();
  const [openMobile, setOpenMobile] = React.useState(false);
  const [openWeb, setOpenWeb] = React.useState(true);
  const [openUserForm, setOpenUserForm] = useState<boolean>(false);
  const [openApplicationSupportForm, setOpenApplicationSupportForm] =
    useState<boolean>(false);
  const theme = useTheme();
  const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useFindDeliveryManPositionSubscription({
    variables: {
      idUser: connectedUser?.id,
    },
    onSubscriptionData: (data) => {
      const idOrder = data.subscriptionData.data?.findDeliveryManPosition.id;
      if (idOrder) {
        navigator.geolocation.getCurrentPosition((position) => {
          /*
          Tour Eiffel => Elisée = 4.5 km (48.8749504, 2.3194796)
          Tour Eiffel => E.leclerc Nanterre = 10.0 km (48.8831329, 2.2023615)
          */
          sendMyPosition({
            variables: {
              payload: {
                idOrder: idOrder,
                latitude: 48.8749504, // position.coords.latitude,
                longitude: 2.3194796, // position.coords.longitude,
              },
            },
          });
        });
      }
    },
    skip: !connectedUser || connectedUser.entity?.code !== "LIVREUR",
  });

  useReceiveApplicationSupportSubscription({
    onSubscriptionData: (data) => {
      const { idOrder, idDeliveryMan, idOrderAssignment } =
        data.subscriptionData.data?.receiveApplicationSupport;
      if (idDeliveryMan === connectedUser?.id) {
        setIdOrderAssignment(idOrderAssignment);
        getOrder({ variables: { id: idOrder } });
      }
    },
    skip: !connectedUser || connectedUser.entity?.code !== "LIVREUR",
  });

  const handleDrawerToggle = () => {
    setOpenMobile(!openMobile);
    setOpenWeb(!openWeb);
  };

  const handleUserFormToogle = () => {
    setOpenUserForm(!openUserForm);
    handleDrawerToggle();
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/signin");
    window.location.reload();
    updateUser({
      variables: {
        input: {
          id: user?.id as string,
          update: {
            fcmToken: null,
            deliveryAvailability:
              connectedUser?.entity?.code === "LIVREUR"
                ? "UNAVAILABLE"
                : undefined,
          },
        },
      },
    });
  };

  const handleCloseApplicationForm = () => {
    setOpenApplicationSupportForm(false);
  };

  return !token ? (
    <Navigate to="/signin" />
  ) : (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar
        onDrawerToggle={handleDrawerToggle}
        onUserFormToggle={handleUserFormToogle}
        onLogout={handleLogout}
      />
      <SideNav
        onDrawerToggle={handleDrawerToggle}
        onUserFormToggle={handleUserFormToogle}
        openMobile={openMobile}
        openWeb={openWeb}
        onLogout={handleLogout}
      />
      <MessagerieDrawer />
      <Box
        component="main"
        sx={{
          p: (theme) => (theme.breakpoints.down("sm") ? 3 : 6),
          width: "100vw",
          height: "100vh",
          marginLeft: onlyMobile || openWeb ? "unset" : `-300px`,
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              height="100%"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          }
        >
          <Outlet />
        </Suspense>
      </Box>
      {isLimitedAccount && <AccountLimited />}
      <UserForm
        user={user as User}
        open={openUserForm}
        onClose={handleUserFormToogle}
      />
      <DeliveryDetails
        idOrderAssignment={idOrderAssignment}
        isApplicationSupport={true}
        show={openApplicationSupportForm}
        onClose={handleCloseApplicationForm}
        order={order}
      />
    </Box>
  );
};

export { Dashboard };
