import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./styles";
import Logo from "../../assets/img/logo.png";
import {
  User,
  useResend_2Fa_CodeLazyQuery,
  useVerify_2Fa_CodeLazyQuery,
} from "@graphql/";
import { useApplicationContext } from "@src/context";
import { postSigninNavigation } from "@utils/postSigninNavigation";
import { useTranslation } from "react-i18next";

export const Authentification2fa: FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const classes = useStyles();
  const query = new URLSearchParams(search);
  const idUser = query.get("idUser");
  const navigate = useNavigate();
  const [code, setCode] = useState<string>(query.get("code") || "");
  const { notify, updateConnectedUser, updateToken } = useApplicationContext();
  const [verify2faCode, { loading: verify2faCodeLoading }] =
    useVerify_2Fa_CodeLazyQuery({
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setCode("");
        const token = data?.verify2faCode.token;
        const user = data?.verify2faCode.user;
        if (token && user) {
          updateConnectedUser(user);
          updateToken(token);
          localStorage.setItem("token", token);
          if (
            user.status === "CHANGE_PASSWORD" ||
            user.status === "PASSWORD_EXPIRED"
          ) {
            navigate(
              `/account-verification?token=${token}&action=${user.status}`
            );
          } else {
            const path = postSigninNavigation(user as User);
            navigate(path || "/");
          }
        }
      },
      onError: (error) => {
        notify({
          message: t("auth2fa.errors.invalidCode"),
          type: "error",
        });
      },
    });

  const [resend2faCode, { loading: resend2faCodeLoading }] =
    useResend_2Fa_CodeLazyQuery({
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setCode("");
        if (data.resend2faCode) {
          notify({
            message: t("auth2fa.success"),
            type: "success",
          });
        }
      },
      onError: (error) => {
        notify({
          message: error.message,
          type: "error",
        });
        navigate("/signin");
      },
    });

  const handleClickCancel = () => {
    navigate("/signin");
  };

  const handleClickSubmit = () => {
    if (!idUser) {
      throw "idUser is not defined";
    }
    verify2faCode({
      variables: {
        idUser,
        code,
      },
    });
  };

  const loading = verify2faCodeLoading || resend2faCodeLoading;

  const handleCodeChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCode(e.target.value);
  };

  const handleClickResendCode = () => {
    if (!idUser) {
      throw "idUser is not defined";
    }
    resend2faCode({ variables: { idUser } });
  };

  if (!idUser) {
    navigate("/signin");
  }
  return (
    <Box className={classes.root}>
      <Card
        className={classes.card}
        sx={{ maxWidth: { lg: "25%", xs: "90%" } }}
      >
        <img className={classes.logo} src={Logo} />
        <CardContent className={classes.cardContent}>
          <Typography variant="h3" mb={3}>
            {t("auth2fa.title")}
          </Typography>
          <Typography variant="subtitle1" mb={2}>
            {t("auth2fa.subTitle")}:
          </Typography>
          <TextField
            data-cy="code"
            onChange={handleCodeChange}
            fullWidth
            sx={{ mb: 1 }}
            value={code}
          />
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
          >
            <Button
              variant="contained"
              color="warning"
              sx={{ mr: 1 }}
              onClick={handleClickCancel}
              disabled={loading}
            >
              {t("commons.cancel")}
            </Button>
            <Button
              data-cy="submit2faCode"
              variant="contained"
              color="success"
              onClick={handleClickSubmit}
              disabled={loading || code === ""}
            >
              {t("commons.send")}
            </Button>
          </Box>
          <Typography
            color="primary"
            sx={{
              cursor: "pointer",
              mt: 3,
              mb: 1,
              textDecoration: "underline",
            }}
            variant="subtitle2"
            onClick={handleClickResendCode}
            data-cy="resendCodeBtn"
          >
            {t("auth2fa.resendCode")}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};
