import * as Types from '../../types';

import { gql } from '@apollo/client';
export type StripePayoutInfoFragment = { __typename?: 'StripePayout', id: string, idOrder: string, idUser?: string | null, idStripeAccount?: string | null, idPayout: string, data: any, createdAt: any, updatedAt: any };

export const StripePayoutInfoFragmentDoc = gql`
    fragment StripePayoutInfo on StripePayout {
  id
  idOrder
  idUser
  idStripeAccount
  idPayout
  data
  createdAt
  updatedAt
}
    `;