import * as Types from '../../types';

import { gql } from '@apollo/client';
import { AddressInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddressesQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.AddressFilter>;
  sorting?: Types.InputMaybe<Array<Types.AddressSort> | Types.AddressSort>;
}>;


export type AddressesQuery = { __typename?: 'Query', addresses: { __typename?: 'AddressConnection', totalCount: number, nodes: Array<{ __typename?: 'Address', id: string, name: string, zipcode: string, country: string, city: string, latitude: number, longitude: number, orders?: { __typename?: 'AddressOrdersConnection', totalCount: number } | null }> } };


export const AddressesDocument = gql`
    query ADDRESSES($paging: OffsetPaging, $filter: AddressFilter, $sorting: [AddressSort!]) {
  addresses(paging: $paging, filter: $filter, sorting: $sorting) {
    nodes {
      ...AddressInfo
    }
    totalCount
  }
}
    ${AddressInfoFragmentDoc}`;

/**
 * __useAddressesQuery__
 *
 * To run a query within a React component, call `useAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressesQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useAddressesQuery(baseOptions?: Apollo.QueryHookOptions<AddressesQuery, AddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddressesQuery, AddressesQueryVariables>(AddressesDocument, options);
      }
export function useAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressesQuery, AddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddressesQuery, AddressesQueryVariables>(AddressesDocument, options);
        }
export type AddressesQueryHookResult = ReturnType<typeof useAddressesQuery>;
export type AddressesLazyQueryHookResult = ReturnType<typeof useAddressesLazyQuery>;
export type AddressesQueryResult = Apollo.QueryResult<AddressesQuery, AddressesQueryVariables>;