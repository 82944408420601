import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      justifyContent: "center",
      alignItems: "center",
      "& .MuiMobileStepper-dots": {
        display: "none",
      },
    },
    stepperBox: {
      marginTop: theme.spacing(3),
      width: "80%",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
  })
);
