import { ChipSelect } from "@components/ChipSelect";
import { MoreHorizMenu } from "@components/MoreHorizMenu";
import { SupportTicketForm } from "@components/SupportTicketForm";
import { Column, Table } from "@components/Table";
import { SupportTicket, useSupport_TicketsQuery } from "@graphql/";
import { Mail } from "@mui/icons-material";
import { Typography, Box, Grid, Button, IconButton } from "@mui/material";
import { useApplicationContext } from "@src/context";
import { usePagination, useSupportTicketsMutations } from "@src/hooks";
import moment from "moment";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

export const NewTickets: FC = () => {
  const { t } = useTranslation();
  const moreHorizMenus = [
    {
      label: t("commons.assignItToMe"),
      value: "ASSIGN",
    },
  ];
  const [showForm, setShowForm] = useState<boolean>(false);
  const [supportTicket, setSupportTicket] = useState<SupportTicket>();
  const pagination = usePagination(false);
  const { updateOneSupportTicket } = useSupportTicketsMutations();
  const supportTicketStatuses = [
    { value: "OPENED", label: "Ouvert", color: "#1246B8" },
  ];
  const { connectedUser, isCustomer } = useApplicationContext();
  const supportTicketsQuery = useSupport_TicketsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        idServiceCustomer: { eq: null },
        status: { eq: "OPENED" },
      },
      paging: pagination.offsetPaging,
    },
    skip: !connectedUser,
    onCompleted: (data) => {
      pagination.setTotalCount(data.supportTickets.totalCount);
    },
  });

  const supportTickets = supportTicketsQuery.data?.supportTickets.nodes || [];

  const handleAssignTicket = (id: string) => {
    updateOneSupportTicket({
      variables: {
        input: {
          id,
          update: {
            idServiceCustomer: connectedUser?.id,
          },
        },
      },
    });
  };

  const columns: Column[] = [
    {
      label: t("supportTicket.list.label.ticketRef"),
      name: "ref",
    },
    {
      label: t("supportTicket.list.label.orderRef"),
      name: "",
      renderer: (row: SupportTicket) => {
        return row.order?.ref || "-";
      },
    },
    {
      label: t("supportTicket.list.label.subject"),
      name: "subject",
    },
    {
      label: t("supportTicket.list.label.dateAdded"),
      name: "createdAt",
      renderer: (row: SupportTicket) => {
        return moment(row.createdAt).format("DD/MM/YYYY à hh:mm");
      },
      breaking: true,
    },
    {
      label: t("supportTicket.list.label.customer"),
      name: "",
      renderer: (row: SupportTicket) => {
        return row.customer
          ? `${row.customer?.firstName} ${row.customer?.lastName}`
          : t("commons.deletedUser");
      },
      breaking: true,
    },
    {
      label: t("supportTicket.list.label.status"),
      name: "status",
      renderer: (row: SupportTicket) => {
        const handleChange = (value: string) => {
          // NOTE: do nothing
        };
        return (
          <ChipSelect
            menuItems={supportTicketStatuses}
            onChange={handleChange}
            value={row.status}
            disabled={true}
            width={100}
          />
        );
      },
    },
    {
      label: "Actions",
      name: "",
      renderer: (row: SupportTicket) => {
        const handleSelect = (selected: string) => {
          setSupportTicket(row);
          switch (selected) {
            case "ASSIGN":
              handleAssignTicket(row.id);
              break;
          }
        };
        return (
          <>
            <IconButton>
              <Mail />
            </IconButton>
            <MoreHorizMenu
              disabled={!moreHorizMenus.length}
              items={moreHorizMenus}
              onSelect={handleSelect}
            />
          </>
        );
      },
    },
  ];

  const handleCloseForm = () => {
    setSupportTicket(undefined);
    setShowForm(false);
  };

  return (
    <Box>
      <Table
        title={t("supportTicket.list.newTickets")}
        columns={columns}
        data={supportTickets}
        toolbar={
          <Grid container spacing={2} justifyContent="end">
            {isCustomer && (
              <Grid item xs={12} lg={6}>
                <Button
                  variant="outlined"
                  onClick={() => setShowForm(true)}
                  fullWidth
                >
                  {t("supportTicket.list.addI")}
                </Button>
              </Grid>
            )}
          </Grid>
        }
        pagination={pagination}
        loading={supportTicketsQuery.loading}
      />
      {showForm && (
        <SupportTicketForm
          open={showForm}
          onClose={handleCloseForm}
          supportTicket={supportTicket}
        />
      )}
    </Box>
  );
};
