import { FileInput } from "@components/FileInput";
import { Box, List, ListItem, ListItemButton, Typography } from "@mui/material";
import { useApplicationContext } from "@src/context";
import { useFilesMutations } from "@src/hooks";
import React, { ChangeEvent, FC } from "react";
import { File as FileType } from "@graphql/";
import { useTranslation } from "react-i18next";

export const AccountLimited: FC = () => {
  const { t } = useTranslation();

  const { connectedUser, refetchConnectedUser, connectedUserType } =
    useApplicationContext();
  const { uploadFiles } = useFilesMutations();
  const bankAccountFiles = connectedUser?.bankAccountFiles || [];
  const vehicleFiles = connectedUser?.vehicleFiles || [];
  const fileNames = {
    KBIS: t("file.names.KBIS"),
    CONTRAT: t("file.names.CONTRAT"),
    RIB: t("file.names.RIB"),
    CARTE_GRISE: t("file.names.CARTE_GRISE"),
    ASSURANCE: t("file.names.ASSURANCE"),
    VISITE_TECHNIQUE: t("file.names.VISITE_TECHNIQUE"),
  };

  const requiredBankAccountFiles = ["KBIS", "CONTRAT", "RIB"];

  const requiredVehicleFiles = ["CARTE_GRISE", "ASSURANCE", "VISITE_TECHNIQUE"];

  const notVerifiedFiles = [
    ...bankAccountFiles.filter((file) => file.status === "NOT_VERIFIED"),
    ...vehicleFiles.filter((file) => file.status === "NOT_VERIFIED"),
  ];

  const invalideFiles = [
    ...bankAccountFiles.filter((file) => file.status === "INVALID"),
    ...vehicleFiles.filter((file) => file.status === "INVALID"),
  ];

  const requiredFiles = [
    ...requiredBankAccountFiles,
    ...(connectedUserType === "LIVREUR" ? requiredVehicleFiles : []),
  ];

  const missingFiles = requiredFiles.filter((requiredFile) => {
    return ![...bankAccountFiles, ...vehicleFiles].some(
      (file) => file.name === requiredFile
    );
  });

  const addresses = connectedUser?.addresses;
  const infoLegale = connectedUser?.infoLegale;
  const bankAccount = connectedUser?.bankAccount;
  const stripeAccount = connectedUser?.stripeAccount;

  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    originalFile: FileType
  ) => {
    const { files } = e.target;
    if (files?.[0]) {
      uploadFiles([
        {
          file: files[0],
          name: originalFile.name,
          filename: files[0].name,
          type: originalFile.type,
          idType: originalFile.idType,
          idUser: originalFile.idUser,
          status: "NOT_VERIFIED",
        },
      ]).then((data) => {
        if (data.length) {
          refetchConnectedUser();
        }
      });
    }
  };

  return (
    <Box>
      <Typography variant="h5" fontWeight="bold" mb={2}>
        {t("accountLimited.title")}
      </Typography>
      <Typography variant="body1" mb={2}>
        {t("accountLimited.description")}
      </Typography>
      {connectedUser.status === "BEING_DELETED" && (
        <Typography variant="body1" fontWeight="bold" mb={4}>
          - Vous avez demandé d'arrêter de prendre des commandes en vue de
          supprimer votre compte
        </Typography>
      )}
      {!stripeAccount &&
        ["LIVREUR", "PHARMACY"].includes(connectedUserType) && (
          <Typography variant="body1" fontWeight="bold" mb={4}>
            - {t("accountLimited.missingStripeAccount")}
          </Typography>
        )}
      {!addresses?.length && (
        <Typography variant="body1" fontWeight="bold" mb={4}>
          - {t("accountLimited.addAddress")}
        </Typography>
      )}
      {!infoLegale && connectedUserType === "PHARMACY" && (
        <Typography variant="body1" fontWeight="bold" mb={4}>
          - {t("accountLimited.addInfoLegales")}
        </Typography>
      )}
      {!bankAccount && ["LIVREUR", "PHARMACY"].includes(connectedUserType) && (
        <Typography variant="body1" fontWeight="bold" mb={4}>
          - {t("accountLimited.addBankAccount")}
        </Typography>
      )}
      {missingFiles.length > 0 &&
        ["LIVREUR", "PHARMACY"].includes(connectedUserType) && (
          <Box>
            <Typography variant="body1" fontWeight="bold" mb={4}>
              - {t("accountLimited.addMissingFiles")}:{" "}
              {missingFiles
                .map((name) => fileNames[name as keyof typeof fileNames])
                .join(",")}
            </Typography>
          </Box>
        )}
      {notVerifiedFiles.length > 0 &&
        ["LIVREUR", "PHARMACY"].includes(connectedUserType) && (
          <Box mb={4}>
            <Typography variant="body1" fontWeight="bold">
              - {t("accountLimited.filesBeingValidated")}:
            </Typography>
            {notVerifiedFiles.map((file) => (
              <ListItem disablePadding>
                <ListItemButton>
                  <FileInput
                    label={fileNames[file.name as keyof typeof fileNames]}
                    file={{ ...file, name: "" }}
                    name={file.name}
                    onFileChange={(e) => {
                      handleFileChange(e, file);
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
        )}
      {invalideFiles.length > 0 &&
        ["LIVREUR", "PHARMACY"].includes(connectedUserType) && (
          <Box mb={4}>
            <Typography variant="body1" fontWeight="bold">
              - {t("accountLimited.replaceBelowValidFile")}:
            </Typography>
            {invalideFiles.map((file) => (
              <ListItem disablePadding>
                <ListItemButton>
                  <FileInput
                    label={fileNames[file.name as keyof typeof fileNames]}
                    file={{ ...file, name: "" }}
                    name={file.name}
                    onFileChange={(e) => {
                      handleFileChange(e, file);
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
        )}
    </Box>
  );
};
