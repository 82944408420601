import {
  AddShoppingCart,
  Alarm,
  ArrowCircleDown,
  Cancel,
  Check,
  DoDisturb,
  Done,
  FormatListNumbered,
  HighlightOff,
  Moped,
  Paid,
  Restore,
  Savings,
  UpdateDisabled,
} from "@mui/icons-material";

export const API_URL = "";
export const DEFAULT_PAGING_LIMIT = 10;
export const userTypes = [
  "INDIVIDUAL",
  "PROFESSIONNAL",
  "PHARMACY",
  "LIVREUR",
  "SERVICE_CUSTOMER",
];

export const orderStatuses = [
  { status: "NEW", icon: ArrowCircleDown },
  { status: "NOT_VERIFIED", icon: AddShoppingCart },
  { status: "UNPAID", icon: Alarm },
  { status: "PAID", icon: Paid },
  { status: "UNAVAILABLE", icon: DoDisturb },
  { status: "REFUNDED", icon: Savings },
  { status: "READY", icon: Restore },
  { status: "DELIVERED", icon: Done },
  { status: "ASSIGNED", icon: Moped },
  { status: "CANCELED", icon: Cancel },
];

export const supportTicketStatuses = [
  { status: "OPENED", icon: FormatListNumbered },
  { status: "CLOSED", icon: UpdateDisabled },
  { status: "RESOLVED", icon: Check },
  { status: "CANCELED", icon: HighlightOff },
];
