import * as Types from '../../types';

import { gql } from '@apollo/client';
import { OrderHistoryInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Order_HistoriesQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.OrderHistoryFilter>;
  sorting?: Types.InputMaybe<Array<Types.OrderHistorySort> | Types.OrderHistorySort>;
}>;


export type Order_HistoriesQuery = { __typename?: 'Query', orderHistories: { __typename?: 'OrderHistoryConnection', totalCount: number, nodes: Array<{ __typename?: 'OrderHistory', id: string, idOrder: string, action: string, createdAt: any, updatedAt: any }> } };


export const Order_HistoriesDocument = gql`
    query ORDER_HISTORIES($paging: OffsetPaging, $filter: OrderHistoryFilter, $sorting: [OrderHistorySort!]) {
  orderHistories(paging: $paging, filter: $filter, sorting: $sorting) {
    nodes {
      ...OrderHistoryInfo
    }
    totalCount
  }
}
    ${OrderHistoryInfoFragmentDoc}`;

/**
 * __useOrder_HistoriesQuery__
 *
 * To run a query within a React component, call `useOrder_HistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrder_HistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrder_HistoriesQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useOrder_HistoriesQuery(baseOptions?: Apollo.QueryHookOptions<Order_HistoriesQuery, Order_HistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Order_HistoriesQuery, Order_HistoriesQueryVariables>(Order_HistoriesDocument, options);
      }
export function useOrder_HistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Order_HistoriesQuery, Order_HistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Order_HistoriesQuery, Order_HistoriesQueryVariables>(Order_HistoriesDocument, options);
        }
export type Order_HistoriesQueryHookResult = ReturnType<typeof useOrder_HistoriesQuery>;
export type Order_HistoriesLazyQueryHookResult = ReturnType<typeof useOrder_HistoriesLazyQuery>;
export type Order_HistoriesQueryResult = Apollo.QueryResult<Order_HistoriesQuery, Order_HistoriesQueryVariables>;