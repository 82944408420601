import { Box, ToggleButton } from "@mui/material";
import React, { FC } from "react";
import { useStyles } from "./styles";

interface ToggleButtonsProps {
  onChange: (value: string) => void;
  value: string;
  toggleButtons: { label: string; value: string }[];
}

export const ToggleButtons: FC<ToggleButtonsProps> = ({
  onChange,
  value,
  toggleButtons,
}) => {
  const classes = useStyles();
  const handleChange = (value: string) => {
    onChange(value);
  };
  return (
    <Box display="flex">
      {toggleButtons.map((toggleButton, index) => (
        <ToggleButton
          value={value}
          selected={value === toggleButton.value}
          onChange={() => handleChange(toggleButton.value)}
          classes={{ selected: classes.selected, root: classes.root }}
          key={`toggle-button-${index}-${toggleButton.value}`}
        >
          {toggleButton.label}
        </ToggleButton>
      ))}
    </Box>
  );
};
