import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    facturation: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    billing: {
      background: "#1976d2",
      color: theme.palette.common.white,
    },
  })
);
export { useStyles };
