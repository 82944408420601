import { DEFAULT_PAGING_LIMIT } from "@utils/constants";
import { encodeParam } from "@utils/encodeParams";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const usePagination = (onlyMobile?: boolean, useParams?: boolean) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const params = {
    status: query.get("status"),
    offset: query.get("offset"),
    limit: query.get("limit"),
    from: query.get("from"),
    to: query.get("to"),
    tab: query.get("tab"),
    collapsed: query.get("collapsed"),
    term: query.get("term") || ""
  };

  const offsetPaging = {
    limit: params.limit ? Number(params.limit) : DEFAULT_PAGING_LIMIT,
    offset: params.offset ? Number(params.offset) : 0,
  }
  
  const setOffsetPaging = (offsetPaging: { limit: number; offset: number }) => {
    const query = encodeParam({
      ...params,
      offset: offsetPaging.offset,
      limit: offsetPaging.limit,
    });
    navigate(`${pathname}?${query}`);
  }
 


  // useEffect(() => {
  //   if (useParams) {
  //     const query = encodeParam({
  //       ...params,
  //       offset: offsetPaging.offset,
  //       limit: offsetPaging.limit,
  //     });
  //     navigate(`${pathname}?${query}`);
  //   }
  // }, [offsetPaging, useParams, onlyMobile]);
  const [totalCount, setTotalCount] = useState<number>(-1);
  return {
    offsetPaging,
    setOffsetPaging,
    totalCount,
    setTotalCount,
  };
};
