import React from 'react';
import franceFlag from "@src/assets/img/fr.png";
import ukFlag from "@src/assets/img/en.png";
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SwitchLanguage = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = (event: SelectChangeEvent<string>) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <Box sx={{ width: 75 }}>
      <Select value={i18n.language} onChange={toggleLanguage}>
        <MenuItem value="fr">
          <img src={franceFlag} alt="French Flag" style={{ width: '20px', height: 'auto' }} />
        </MenuItem>
        <MenuItem value="en">
          <img src={ukFlag} alt="UK Flag" style={{ width: '20px', height: 'auto' }} />
        </MenuItem>
      </Select>
    </Box>
  );
};

export default SwitchLanguage;
