import * as Types from '../../types';

import { gql } from '@apollo/client';
import { FileInfoFragmentDoc } from '../../File/__generated__/fragment';
export type OrdonnanceInfoFragment = { __typename?: 'Ordonnance', id: string, patient?: string | null, info?: string | null, status: string, validationError?: string | null, file: { __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null } };

export const OrdonnanceInfoFragmentDoc = gql`
    fragment OrdonnanceInfo on Ordonnance {
  id
  patient
  file {
    ...FileInfo
  }
  info
  status
  validationError
}
    ${FileInfoFragmentDoc}`;