import {
  useCreate_One_UserMutation,
  useDelete_One_UserMutation,
  useForgot_PasswordMutation,
  useUpdate_One_UserMutation,
} from "@graphql/";
import { useApplicationContext } from "@src/context";
import { useTranslation } from "react-i18next";

interface Params {
  skipRefetch?: boolean;
}
export const useUsersMutations = (params?: Params) => {
  const { t } = useTranslation();

  const { notify, updateConnectedUser, connectedUser } =
    useApplicationContext();

  const [createUser, creatingUser] = useCreate_One_UserMutation({
    refetchQueries: ["USERS_COUNT", "USERS"],
    onCompleted: () => {
      notify({
        message: t("user.form.success.add"),
        type: "success",
      });
    },
    onError: () => {
      notify({
        message: t("commons.errorOccured"),
        type: "error",
      });
    },
  });
  const [updateUser, updatingUser] = useUpdate_One_UserMutation({
    refetchQueries: !params?.skipRefetch
      ? ["USERS", "ORDERS", "ORDER_STATUSES_COUNTS"]
      : undefined,
    update: (cache, { data }) => {
      if (connectedUser?.id === data?.updateOneUser?.id) {
        updateConnectedUser(data?.updateOneUser);
      }
    },
    onCompleted: () => {},
    onError: () => {
      notify({
        message: t("commons.errorOccured"),
        type: "error",
      });
    },
  });

  const [deleteUser, deletingUser] = useDelete_One_UserMutation({
    refetchQueries: !params?.skipRefetch
      ? ["USERS", "ORDERS", "ORDER_STATUSES_COUNTS"]
      : undefined,
    onCompleted: () => {
      notify({
        message: t("user.form.success.delete"),
        type: "success",
      });
    },
    onError: () => {
      notify({
        message: t("commons.errorOccured"),
        type: "error",
      });
    },
  });
  const [sendForgotPassword, sendingForgotPassword] =
    useForgot_PasswordMutation({
      onCompleted: () => {
        notify({
          message: t("forgotPassword.success"),
          type: "success",
        });
      },
      onError: (error) => {
        if (error.message === "user not found") {
          return notify({
            message: t("forgotPassword.errors.userNotFound"),
            type: "error",
          });
        }
        return notify({
          message: t("commons.errorOccured"),
          type: "error",
        });
      },
    });

  const loading =
    creatingUser.loading ||
    updatingUser.loading ||
    sendingForgotPassword.loading;

  return {
    updateUser,
    updatingUser,
    createUser,
    creatingUser,
    deleteUser,
    deletingUser,
    loading,
    sendForgotPassword,
    sendingForgotPassword,
  };
};
