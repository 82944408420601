import { Order } from "@graphql/*";
import {
  AccessTime,
  CalendarMonth,
  LocalPharmacy,
  Person,
  Place,
} from "@mui/icons-material";
import { Card, Box, Typography, CardContent, Chip } from "@mui/material";
import { useApplicationContext } from "@src/context";
import lodash from "lodash";
import moment from "moment";
import React, { FC } from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

interface OrderMobileRowProps {
  order?: Order;
  actions?: (row: Order) => JSX.Element;
}

export const OrderMobileRow: FC<OrderMobileRowProps> = ({ order, actions }) => {
  const { t } = useTranslation();

  const { connectedUserType } = useApplicationContext();
  const classes = useStyles();
  const getCustomerPrice = () => {
    if (!order) {
      return 0;
    }
    const totalPrice = lodash.sum(
      order?.orderItems?.map((orderItem) => orderItem.totalPrice)
    );
    const refunds = lodash.sum(
      order?.orderItems?.map((orderItem) => orderItem.refund)
    );
    const billing = totalPrice - refunds;

    return billing + order?.deliveryPrice + order?.totalPrice;
  };
  const prixTotal =
    connectedUserType === "LIVREUR"
      ? order?.deliveryPrice
      : connectedUserType === "PHARMACY"
      ? order?.vendorPrice
      : getCustomerPrice();

  const orderStatuses = {
    NEW: { label: t("order.statuses.NEW"), color: "#1AA7EC" },
    NOT_VERIFIED: {
      label: t("order.statuses.NOT_VERIFIED"),
      color: "#1AA7EC",
    },
    UNPAID: { label: t("order.statuses.UNPAID"), color: "#FC7869" },
    PAID: { label: t("order.statuses.PAID"), color: "#2ecc71 " },
    UNAVAILABLE: {
      label: t("order.statuses.UNAVAILABLE"),
      color: "#2C3E50",
    },
    REFUNDED: {
      label: t("order.statuses.REFUNDED"),
      color: "#2ECC71 ",
    },
    READY: { label: t("order.statuses.READY"), color: "#4ADEDE" },
    DELIVERED: {
      label: t("order.statuses.DELIVERED"),
      color: "#2ECC71 ",
    },
    ASSIGNED: {
      label:
        connectedUserType === "LIVREUR"
          ? t("order.statuses.ASSIGNED")
          : t("order.statuses.ASSIGNED_TO_A_DELIVERY"),
      color: "#FFC107",
    },
    CANCELED: {
      label: t("order.statuses.CANCELED"),
      color: "#2C3E50",
    }
  };
  const status = orderStatuses[order?.status as keyof typeof orderStatuses];
  return (
    <Card className={classes.root} sx={{ mb: 3 }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            {order?.ref}
          </Typography>
          {order && actions && actions(order)}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography>
            <CalendarMonth sx={{ mr: 1 }} />
            {moment(order?.datetime).format("DD/MM/YYYY")}
          </Typography>
          <Typography>
            <AccessTime sx={{ mr: 1 }} />
            {moment(order?.datetime).format("hh:mm")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography>
            <Place sx={{ mr: 1 }} />
            {order?.address?.name}
          </Typography>
          <Typography variant="h5" fontWeight="bold">{`${prixTotal?.toFixed(
            2
          )}€`}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          {connectedUserType === "PHARMACY" && (
            <Typography>
              <Person sx={{ mr: 1 }} />
              {order?.customer
                ? `${order?.customer?.firstName} ${order?.customer?.lastName}`
                : t("commons.deletedUser")}
            </Typography>
          )}
          {["INDIVIDUAL", "PROFESSIONNAL", "LIVREUR"].includes(
            connectedUserType
          ) && (
            <Typography>
              <LocalPharmacy sx={{ mr: 1 }} />
              {order?.vendor?.infoLegale?.name}
            </Typography>
          )}
          <Chip
            label={status.label}
            sx={{ color: "#FFFFFF", background: status.color }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
