import React, {
  FC,
  useState,
} from "react";
import {
  Box,
  Checkbox,
  IconButton,
  TableCell,
  TableRow as TableRowComponent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Column } from "../Table";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { green, red } from "@mui/material/colors";

interface TableRowProps {
  rowIndex: number;
  onRowClick?: (row: any) => void;
  columns: Column[];
  row: any;
  handleRowClick: (row: any) => void;
  selected: any;
}
export const TableRow: FC<TableRowProps> = ({
  rowIndex,
  onRowClick,
  columns,
  row,
  handleRowClick,
  selected,
}) => {
  const [showHiddenColumns, setShowHiddenColumns] = useState<boolean>(false);
  const theme = useTheme();
  const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const hiddenColumns = columns.filter((column) => column.breaking);
  const mobileColumns = columns.filter((column) => !column.breaking);
  const shownColumns = onlyMobile ? mobileColumns : columns;

  const toggleHiddenColumns = () => {
    setShowHiddenColumns(!showHiddenColumns);
  };

  const hiddenColumsToggle = showHiddenColumns ? (
    <IconButton onClick={toggleHiddenColumns}>
      <RemoveCircle sx={{ color: red[500] }} />
    </IconButton>
  ) : (
    <IconButton onClick={toggleHiddenColumns}>
      <AddCircle sx={{ color: green[500] }} />
    </IconButton>
  );
  return (
    <>
      <TableRowComponent key={`tr-row-${rowIndex}`}>
        {onRowClick && (
          <TableCell valign="middle" key={`td-checkbox-${rowIndex}`}>
            <Checkbox
              onClick={() => handleRowClick(row)}
              checked={row?.id === selected?.id}
            />
          </TableCell>
        )}
        {shownColumns.map((column, index) => (
          <TableCell valign="middle" key={`td-${column.label}-${index}`}>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {onlyMobile && hiddenColumns.length && index === 0
                  ? hiddenColumsToggle
                  : null}
                {column.renderer
                  ? column.renderer(row, rowIndex)
                  : row[column.name]
                  ? row[column.name]
                  : "-"}
              </Box>
            </Box>
          </TableCell>
        ))}
      </TableRowComponent>
      {showHiddenColumns && (
        <TableRowComponent key={`tr-row-hidden-${rowIndex}`}>
          <TableCell sx={{ minWidth: "100vw" }}>
            {hiddenColumns.map((column, index) => (
              <Box
                key={`td-hidden-${column.label}-${index}`}
                sx={{ display: "flex", mb: 1 }}
              >
                <Typography mr={1} fontWeight="bold">
                  {column.label}:
                </Typography>
                <Typography>
                  {column.renderer
                    ? column.renderer(row, rowIndex)
                    : row[column.name]
                    ? row[column.name]
                    : "-"}
                </Typography>
              </Box>
            ))}
          </TableCell>
        </TableRowComponent>
      )}
    </>
  );
};
