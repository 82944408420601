import { Box, CircularProgress } from "@mui/material";
import React, { FC } from "react";
import LogoGm from "@src/assets/img/logo-gm.png";

export const SplashScreen: FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <img src={LogoGm} height={128} />
      <CircularProgress />
    </Box>
  );
};
