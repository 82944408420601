import * as Types from '../../types';

import { gql } from '@apollo/client';
export type InfoLegaleInfoFragment = { __typename?: 'InfoLegale', id: string, idUser: string, name: string, sirenCode: string, numTva: string };

export const InfoLegaleInfoFragmentDoc = gql`
    fragment InfoLegaleInfo on InfoLegale {
  id
  idUser
  name
  sirenCode
  numTva
}
    `;