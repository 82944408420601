import * as Types from '../../types';

import { gql } from '@apollo/client';
export type OrderItemInfoFragment = { __typename?: 'OrderItem', id: string, idOrder: string, libelle: string, description: string, quantity: number, unitPrice: number, totalPrice: number, refund: number, createdAt: any, updatedAt: any };

export const OrderItemInfoFragmentDoc = gql`
    fragment OrderItemInfo on OrderItem {
  id
  idOrder
  libelle
  description
  quantity
  unitPrice
  totalPrice
  refund
  createdAt
  updatedAt
}
    `;