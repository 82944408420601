import { useOrderMutations } from "@components/CreateOrderStepper/useOrderMutations";
import { MoreHorizMenu } from "@components/MoreHorizMenu";
import { Column } from "@components/Table";
import { Order, User } from "@graphql/";
import { useParams } from "@src/hooks";
import { encodeParam } from "@utils/encodeParams";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

export const usePharmacyProps = ({
  setOrder,
  setShowFileViewer,
  setShowOrderItemsModal,
  entity,
  connectedUser,
  setShowOrderDetails,
}: {
  setShowOrderItemsModal: Dispatch<SetStateAction<boolean>>;
  setShowFileViewer: Dispatch<SetStateAction<boolean>>;
  entity?: { code: string } | null;
  setOrder: Dispatch<SetStateAction<Order | undefined>>;
  connectedUser?: User;
  setShowOrderDetails: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();

  const { updateOneOrder } = useOrderMutations();
  const { requiredParams } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const redirect = (status: string) => {
    const query = encodeParam({
      ...requiredParams,
      status,
      offset: 0,
    });
    navigate(`${pathname}?${query}`);
  };

  const pharmacyActions = (row: Order) => {
    const handleSelect = (selected: string) => {
      setOrder(row);
      switch (selected) {
        case "ASSIGN":
          updateOneOrder({
            variables: {
              input: {
                id: row.id,
                update: {
                  preparer: `${connectedUser?.firstName} ${connectedUser?.lastName}`,
                  status: "NOT_VERIFIED",
                },
              },
            },
          }).then(() => {
            redirect("NOT_VERIFIED");
          });
          break;
        case "FILES":
          setShowFileViewer(true);
          break;
        case "ORDER_ITEM":
          setShowOrderItemsModal(true);
          break;
        case "UNAVAILABLE":
          updateOneOrder({
            variables: {
              input: { id: row.id, update: { status: "UNAVAILABLE" } },
            },
          }).then(() => {
            redirect("UNAVAILABLE");
          });
          break;
        case "UNPAID":
          updateOneOrder({
            variables: {
              input: { id: row.id, update: { status: "UNPAID" } },
            },
          }).then(() => {
            redirect("UNPAID");
          });
          break;
        case "READY":
          updateOneOrder({
            variables: {
              input: { id: row.id, update: { status: "READY" } },
            },
          }).then(() => {
            redirect("READY");
          });
          break;
        case "DETAILS":
          setShowOrderDetails(true);
          break;
      }
    };
    const menus = [
      {
        label: t("commons.assignItToMe"),
        value: "ASSIGN",
        disabled: row.preparer !== undefined,
      },
      {
        label: t("order.list.action.orderDocument"),
        value: "FILES",
      },
      {
        label: t("order.list.action.orderItems"),
        value: "ORDER_ITEM",
        disabled: row.files?.some((file) => file.status !== "VALID"),
      },
      {
        label: t("order.list.action.orderUnavailable"),
        value: "UNAVAILABLE",
        disabled: row.status !== "NOT_VERIFIED",
      },
      {
        label: t("order.list.action.orderPendingPayment"),
        value: "UNPAID",
        disabled: row.status !== "NOT_VERIFIED" || !row.orderItems?.length,
      },
      {
        label: t("order.list.action.readyForDelivery"),
        value: "READY",
        disabled: row.status !== "PAID",
      },
      {
        label: t("commons.seeDetails"),
        value: "DETAILS",
      },
    ].filter((menu) => !menu.disabled);
    return <MoreHorizMenu items={menus} onSelect={handleSelect} />;
  };

  const pharmacyColumns: Column[] =
    entity?.code === "PHARMACY"
      ? [
          {
            label: t("order.list.label.preparer"),
            name: "preparer",
            breaking: true,
          },
          {
            label: t("order.list.label.totalPrice"),
            name: "vendorPrice",
            sortable: true,
            renderer: (row: Order) => {
              return `${Number(row.vendorPrice).toFixed(2)}€`;
            },
          },
        ]
      : [];
  return { pharmacyColumns, pharmacyActions };
};
