import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Update_One_Order_AssignmentMutationVariables = Types.Exact<{
  input: Types.UpdateOneOrderAssignmentInput;
}>;


export type Update_One_Order_AssignmentMutation = { __typename?: 'Mutation', updateOneOrderAssignment: { __typename?: 'OrderAssignment', id: string } };


export const Update_One_Order_AssignmentDocument = gql`
    mutation UPDATE_ONE_ORDER_ASSIGNMENT($input: UpdateOneOrderAssignmentInput!) {
  updateOneOrderAssignment(input: $input) {
    id
  }
}
    `;
export type Update_One_Order_AssignmentMutationFn = Apollo.MutationFunction<Update_One_Order_AssignmentMutation, Update_One_Order_AssignmentMutationVariables>;

/**
 * __useUpdate_One_Order_AssignmentMutation__
 *
 * To run a mutation, you first call `useUpdate_One_Order_AssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_One_Order_AssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneOrderAssignmentMutation, { data, loading, error }] = useUpdate_One_Order_AssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_One_Order_AssignmentMutation(baseOptions?: Apollo.MutationHookOptions<Update_One_Order_AssignmentMutation, Update_One_Order_AssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_One_Order_AssignmentMutation, Update_One_Order_AssignmentMutationVariables>(Update_One_Order_AssignmentDocument, options);
      }
export type Update_One_Order_AssignmentMutationHookResult = ReturnType<typeof useUpdate_One_Order_AssignmentMutation>;
export type Update_One_Order_AssignmentMutationResult = Apollo.MutationResult<Update_One_Order_AssignmentMutation>;
export type Update_One_Order_AssignmentMutationOptions = Apollo.BaseMutationOptions<Update_One_Order_AssignmentMutation, Update_One_Order_AssignmentMutationVariables>;