import moment from "moment";
import React from "react";
import { useLocation } from "react-router-dom";
export const useParams = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const beginningOfMonth = moment().startOf("M").format("YYYY/MM/DD");
  const endOfMonth = moment().endOf("M").format("YYYY/MM/DD");

  const requiredParams = {
    status: query.get("status"),
    offset: query.get("offset") || "0",
    limit: query.get("limit") || "10",
    from: query.get("from") || beginningOfMonth,
    to: query.get("to") || endOfMonth,
    tab: query.get("tab") || "NEW",
    collapsed: query.get("collapsed") || "",
  };

  const optionalParams = {
    term: query.get("term") || "",
    sortFields: query.get("sortFields") || "",
    orderRef: query.get("orderRef") || "",
  };

  const params = { ...requiredParams, ...optionalParams };

  return { params, requiredParams, optionalParams };
};
