import { Autocomplete } from "@components/Autocomplete";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Typography,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  MenuItem,
  InputAdornment,
  Switch,
  FormGroup,
} from "@mui/material";
import React, {
  FC,
  FormEvent,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Control,
  Controller,
  FieldValues,
  useForm,
  UseFormSetValue,
} from "react-hook-form";
import { InputProps } from "src/types";
import { RequiredObjectSchema } from "yup/lib/object";
import { useStyles } from "./styles";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { DatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { Modal } from "@components/Modal";
import moment from "moment";

interface FormWrapperProps {
  cancelBtnDatacy?: string
  submitBtnDataCy?: string;
  element?: any;
  onSubmit: (state: any, id?: string) => void;
  onCancel?: () => void;
  loading: boolean;
  inputs: InputProps[];
  initialCreateInput: any;
  submitBtnLabel?: string;
  cancelBtnLabel?: string;
  schema: RequiredObjectSchema<any, any, any>;
  onChange?: (values: any) => void;
  values?: any;
  btnChildren?: ReactNode;
}

interface FormProps {
  setValue: UseFormSetValue<any>;
  inputs: InputProps[];
  fileInputRef?: MutableRefObject<HTMLInputElement | null>;
  control: Control<FieldValues, any>;
  errors: any;
  action: "ADD" | "VIEW" | "EDIT";
}

const Form: FC<FormProps> = ({ inputs, control, errors, setValue, action }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {inputs.map(
        (input, index) =>
          !input.hidden && (
            <Box mb={2} key={`input-${input.type}-${index}`}>
              <Controller
                control={control}
                name={input.name}
                render={({ field }) => (
                  <>
                    {input.type === "node" ? (
                      input.node
                    ) : input.type === "textarea" ? (
                      <TextareaAutosize
                        {...input}
                        {...field}
                        placeholder={input.placeholder}
                        minRows={4}
                        style={{ width: "100%" }}
                        disabled={action === "VIEW"}
                      />
                    ) : input.type === "checkbox" ? (
                      <FormControlLabel
                        disabled={action === "VIEW"}
                        label={input.label}
                        control={<Checkbox {...field} checked={input.value} />}
                      />
                    ) : input.type === "autocomplete" &&
                      input.options &&
                      input.onSearch ? (
                      <Autocomplete
                        onSearch={input.onSearch}
                        options={input.options}
                        field={field}
                        input={input}
                        setValue={setValue}
                        disabled={action === "VIEW"}
                      />
                    ) : input.type === "select" ? (
                      <TextField
                        {...input}
                        {...field}
                        fullWidth
                        select
                        variant="outlined"
                        value={input.value}
                        disabled={action === "VIEW"}
                      >
                        {(input.options || []).map((option) => (
                          <MenuItem
                            key={`select-${option.value}`}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : input.type === "switch" ? (
                      <FormGroup>
                        <FormControlLabel
                          disabled={action === "VIEW"}
                          control={
                            <Switch
                              onChange={(e) => {
                                setValue(input.name, e.target.checked);
                              }}
                              checked={field.value}
                            />
                          }
                          label={input.label}
                        />
                      </FormGroup>
                    ) : input.type === "date" ? (
                      <DatePicker
                        disabled={action === "VIEW"}
                        onChange={field.onChange}
                        label={input.label}
                        InputProps={{ name: input.name }}
                        value={field.value}
                        disablePast={input.disablePast}
                        renderInput={(props) => (
                          <TextField {...props} fullWidth />
                        )}
                      />
                    ) : (
                      <TextField
                        {...input}
                        {...field}
                        
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          endAdornment: input.textafter ? (
                            <InputAdornment position="end">
                              {input.textafter}
                            </InputAdornment>
                          ) : undefined,
                          inputProps: {
                            min: 0,
                            max: input.max,
                          },
                        }}
                        disabled={action === "VIEW"}
                      />
                    )}
                    {errors && errors[field.name] && (
                      <Typography variant="caption" className={classes.error}>
                        {errors[field.name]?.message}*
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>
          )
      )}
    </Box>
  );
};

const FormWrapper: FC<FormWrapperProps> = ({
  element,
  onSubmit,
  loading,
  initialCreateInput,
  inputs,
  schema,
  submitBtnLabel,
  cancelBtnLabel,
  onCancel,
  onChange,
  values,
  btnChildren,
  submitBtnDataCy,
  cancelBtnDatacy
}) => {

  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [action, setAction] = useState<"ADD" | "EDIT" | "VIEW">(
    element ? "VIEW" : "ADD"
  );
  const [showNoChangesModal, setShowNoChangesModal] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: initialCreateInput,
    resolver: yupResolver(schema),
  });

  const disableSubmitBtn = loading;

  useEffect(() => {
    if (element) {
      reset(element);
      setAction("VIEW");
    }
  }, [element]);

  const currentValues = watch();

  useEffect(() => {
    if (onChange) {
      onChange(currentValues);
    }
  }, [currentValues]);

  useEffect(() => {
    if (values) {
      Object.keys(values).forEach((key) => {
        setValue(key, values[key]);
      });
    }
  }, [values]);

  const handleEdit = (state: any, id?: string) => {
    let withChanges = false;
    for (const input of inputs) {
      if (input.type === "date") {
        state[input.name] = moment(state[input.name]).format("YYYY-MM-DD");
      }
      if (state[input.name] !== element[input.name]) {
        withChanges = true;
        break;
      }
    }
    if (!withChanges) {
      setShowNoChangesModal(true);
    } else {
      onSubmit(state, id);
    }
  };

  const handleAction = (state: any, id?: string) => {
    switch (action) {
      case "ADD":
        onSubmit(state, id);
        break;
      case "EDIT":
        handleEdit(state, id);
        break;

      default:
        break;
    }
  };

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (action === "VIEW") {
      setAction("EDIT");
      return;
    }
    handleSubmit((state) => handleAction(state, element?.id))(e);
  };

  const handleCloseNoChangesModal = () => {
    setShowNoChangesModal(false);
    onCancel && onCancel();
  };

  return (
    <>
      <Modal
        open={showNoChangesModal}
        onClose={handleCloseNoChangesModal}
        actions={
          <Button onClick={handleCloseNoChangesModal}>
            {t("commons.close")}
          </Button>
        }
      >
        <Typography>{t("commons.noChange")}</Typography>
      </Modal>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        component="form"
        onSubmit={handleFormSubmit}
        noValidate
      >
        <Form
          setValue={setValue}
          inputs={inputs}
          fileInputRef={fileInputRef}
          control={control}
          errors={errors}
          action={action}
        />
        {onCancel ? (
          <Box sx={{ width: "100%" }}>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: 2,
              }}
            >
              {btnChildren}
              <Button
                disabled={disableSubmitBtn}
                variant="contained"
                onClick={onCancel}
                sx={{ marginRight: submitBtnLabel ? 2 : 0 }}
                color="error"
                data-cy={cancelBtnDatacy}
              >
                {cancelBtnLabel || "Annuler"}
              </Button>
              {submitBtnLabel && (
                <Button
                  disabled={disableSubmitBtn}
                  color="primary"
                  variant="contained"
                  type="submit"
                  data-cy={submitBtnDataCy}
                >
                  {action === "EDIT" ? t("commons.save") : submitBtnLabel}
                </Button>
              )}
            </Box>
          </Box>
        ) : (
          submitBtnLabel && (
            <Button
              disabled={disableSubmitBtn}
              color="primary"
              variant="contained"
              type="submit"
              data-cy={submitBtnDataCy}
            >
              {action === "EDIT" ? t("commons.save") : submitBtnLabel}
            </Button>
          )
        )}
      </Box>
    </>
  );
};
export { Form, FormWrapper };