import { Backdrop, CircularProgress } from "@mui/material";
import React, { FC, MouseEventHandler } from "react";

interface BackdropLoaderProps {
  open: boolean;
  handleClose?: MouseEventHandler<HTMLElement>;
}

export const BackdropLoader: FC<BackdropLoaderProps> = ({
  open,
  handleClose,
}) => {
  return (
    <Backdrop sx={{ color: "#fff" }} open={open} onClick={handleClose}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
