import * as Types from '../../types';

import { gql } from '@apollo/client';
import { FileInfoFragmentDoc } from '../../File/__generated__/fragment';
export type CarteVitaleInfoFragment = { __typename?: 'CarteVitale', id: string, patient?: string | null, number?: string | null, status: string, validationError?: string | null, createdAt: any, updatedAt?: any | null, file: { __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null } };

export const CarteVitaleInfoFragmentDoc = gql`
    fragment CarteVitaleInfo on CarteVitale {
  id
  patient
  file {
    ...FileInfo
  }
  number
  status
  validationError
  createdAt
  updatedAt
}
    ${FileInfoFragmentDoc}`;