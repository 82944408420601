import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: "auto",
      "& .MuiListItemIcon-root": {
        minWidth: 40,
      },
    },
    borderRight: {
      boxShadow: `0px 2px 4px -1px ${theme.palette.primary.main}, 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px ${theme.palette.primary.main} !important`,
    },
    active: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`,
      "& .MuiListItemIcon-root": {
        color: theme.palette.common.white,
      },
      "& .MuiBadge-badge": {
        background: theme.palette.common.white,
        color: theme.palette.primary.main,
      },
    },
    bold: {
      // fontWeight: "bold !important",
      fontFamily: "'Montserrat', sans-serif !important",
    },
    collapseRoot: {
      paddingTop: "8px !important",
      paddingBottom: "32px !important",
    },
    listItemButtonRoot: {
      borderRadius: "8px !important",
    },
    availabilityLabel: {
      color: theme.palette.primary.main,
    },
  })
);
