import * as Types from '../../types';

import { gql } from '@apollo/client';
import { StripeAccountLinkInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_One_Stripe_Account_LinkMutationVariables = Types.Exact<{
  input: Types.CreateOneStripeAccountLinkInput;
}>;


export type Create_One_Stripe_Account_LinkMutation = { __typename?: 'Mutation', createOneStripeAccountLink: { __typename?: 'StripeAccountLink', id: string, idStripeAccount: string, url: string, type: string } };


export const Create_One_Stripe_Account_LinkDocument = gql`
    mutation CREATE_ONE_STRIPE_ACCOUNT_LINK($input: CreateOneStripeAccountLinkInput!) {
  createOneStripeAccountLink(input: $input) {
    ...StripeAccountLinkInfo
  }
}
    ${StripeAccountLinkInfoFragmentDoc}`;
export type Create_One_Stripe_Account_LinkMutationFn = Apollo.MutationFunction<Create_One_Stripe_Account_LinkMutation, Create_One_Stripe_Account_LinkMutationVariables>;

/**
 * __useCreate_One_Stripe_Account_LinkMutation__
 *
 * To run a mutation, you first call `useCreate_One_Stripe_Account_LinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_One_Stripe_Account_LinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneStripeAccountLinkMutation, { data, loading, error }] = useCreate_One_Stripe_Account_LinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_One_Stripe_Account_LinkMutation(baseOptions?: Apollo.MutationHookOptions<Create_One_Stripe_Account_LinkMutation, Create_One_Stripe_Account_LinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_One_Stripe_Account_LinkMutation, Create_One_Stripe_Account_LinkMutationVariables>(Create_One_Stripe_Account_LinkDocument, options);
      }
export type Create_One_Stripe_Account_LinkMutationHookResult = ReturnType<typeof useCreate_One_Stripe_Account_LinkMutation>;
export type Create_One_Stripe_Account_LinkMutationResult = Apollo.MutationResult<Create_One_Stripe_Account_LinkMutation>;
export type Create_One_Stripe_Account_LinkMutationOptions = Apollo.BaseMutationOptions<Create_One_Stripe_Account_LinkMutation, Create_One_Stripe_Account_LinkMutationVariables>;