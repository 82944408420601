import {
  useCreate_One_Bank_AccountMutation,
  useUpdate_One_Bank_AccountMutation,
} from "@graphql/";
import { useApplicationContext } from "@src/context";
import { formatErrorMessage } from "@utils/formatErrorMessage";
import { useTranslation, } from "react-i18next";

export const useBankAccountMutations = () => {
  const { t } = useTranslation();

  const { notify } = useApplicationContext();
  const [createOneBankAccount, creatingOneBankAccount] =
    useCreate_One_Bank_AccountMutation({
      refetchQueries: ["BANK_ACCOUNTS"],
      onCompleted: () => {
        notify({
          message: t("bankAccount.success.create"),
          type: "success",
        });
      },
      onError: (e) => {
        notify({
          message: formatErrorMessage(e),
          type: "error",
        });
      },
    });

  const [updateOneBankAccount, updatingOneBankAccount] =
    useUpdate_One_Bank_AccountMutation({
      refetchQueries: ["BANK_ACCOUNTS"],
      onCompleted: (data) => {
        notify({
          message: t("bankAccount.success.update"),
          type: "success",
        });
      },
      onError: (e) => {
        notify({
          message: formatErrorMessage(e),
          type: "error",
        });
      },
    });

  return {
    createOneBankAccount,
    updateOneBankAccount,
    creatingOneBankAccount,
    updatingOneBankAccount,
  };
};
