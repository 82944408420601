import { Modal } from "@components/Modal";
import { Box, Button, Typography } from "@mui/material";
import React, { FC, useState } from "react";

interface ValidationPopupBtnProps {
  onSubmit: () => void;
  disabled?: boolean;
  label: string;
  message: string;
  submitLabel: string;
  cancelLabel: string;
}

export const ValidationPopupBtn: FC<ValidationPopupBtnProps> = ({
  onSubmit,
  disabled,
  label,
  message,
  submitLabel,
  cancelLabel,
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const handleToggleOpenConfirmDialog = () => {
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleSubmit = () => {
    onSubmit();
    handleToggleOpenConfirmDialog();
  };

  return (
    <>
      <Button
        disabled={disabled}
        variant="contained"
        color="primary"
        onClick={handleToggleOpenConfirmDialog}
      >
        {label}
      </Button>
      <Modal
        maxWidth="sm"
        open={openConfirmDialog}
        onClose={handleToggleOpenConfirmDialog}
      >
        <Typography>{message}</Typography>
        <Box display="flex" justifyContent="flex-end" mt={1}>
          <Button
            variant="contained"
            color="error"
            onClick={handleToggleOpenConfirmDialog}
            sx={{ mr: 1 }}
          >
            {cancelLabel}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {submitLabel}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
