export const useEchartsOptions = () => {
  const userIncomeStatsOptions = (data: { count: number; label: string }[]) => {
    return {
      xAxis: {
        type: "category",
        data: data.map((el) => el.label),
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: data.map((el) => el.count),
          type: "line",
        },
      ],
    };
  };

  return { userIncomeStatsOptions };
};
