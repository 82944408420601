import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { FC, ReactNode } from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  children: ReactNode;
  actions?: ReactNode;
  onCancel?: () => void;
  maxWidth?: "xs" | "sm" | "md" | "xl" | "lg";
  text?: string;
  disableCloseBtn?: boolean;
  onContentScroll?: React.UIEventHandler;
  dataCy?: string;
}

export const Modal: FC<ModalProps> = ({
  dataCy,
  open,
  onClose,
  onContentScroll,
  title,
  children,
  actions,
  onCancel,
  maxWidth = "md",
  text,
  disableCloseBtn,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth || "md"}
      fullScreen={!children || !title ? false : onlyMobile}
      PaperProps={{
        "data-cy": dataCy,
      }}
    >
      {title && (
        <Box className={classes.header}>
          <Typography variant="h6">{title}</Typography>
          <IconButton
            onClick={onClose}
            color="inherit"
            disabled={disableCloseBtn}
          >
            <Close />
          </IconButton>
        </Box>
      )}
      <DialogContent
        onScroll={onContentScroll}
        classes={{ root: classes.content }}
      >
        {text && <DialogContentText> {text}</DialogContentText>}
        {children}
      </DialogContent>
      {actions && (
        <DialogActions sx={{ pb: 4 }}>
          {onCancel && (
            <Button
              variant="contained"
              onClick={onCancel}
              sx={{ marginRight: 2 }}
              color="error"
            >
              {t("commons.cancel")}
            </Button>
          )}
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};
