import { OrderItem } from "@graphql/*";
import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import React, { FC } from "react";
import { useStyles } from "./styles";

interface OrderItemMobileRowProps {
  orderItem?: OrderItem;
  actions?: (row: OrderItem) => JSX.Element;
  onRowClick?: (row: OrderItem) => void;
  selected?: any;
}

export const OrderItemMobileRow: FC<OrderItemMobileRowProps> = ({
  orderItem,
  actions,
  onRowClick,
  selected,
}) => {
  const classes = useStyles({ selected, orderItem });
  const theme = useTheme();
  return (
    <Card
      className={classes.root}
      sx={{
        mb: 3,
        border:
          orderItem?.id === selected?.id
            ? `2px solid ${theme.palette.primary.main}`
            : "unset",
      }}
      onClick={() => onRowClick && orderItem && onRowClick(orderItem)}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="body1">
            <Typography fontWeight="bold">Libelle:</Typography>
            {orderItem?.libelle}
          </Typography>
          {orderItem && actions && actions(orderItem)}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="body1">
            <Typography fontWeight="bold">PU:</Typography>
            {orderItem?.unitPrice}€
          </Typography>
          <Typography variant="body1">
            <Typography fontWeight="bold">Total:</Typography>
            {orderItem?.totalPrice}€
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="body1">
            <Typography fontWeight="bold">Description:</Typography>
            {orderItem?.description}
          </Typography>
          <Typography variant="body1">
            <Typography fontWeight="bold">Quantité:</Typography>
            {orderItem?.quantity}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="body1">
            <Typography fontWeight="bold">Remboursement:</Typography>
            {orderItem?.refund}€
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
