import { useOrder_Statuses_CountsQuery } from "@graphql/";
import { useApplicationContext } from "@src/context";
import moment from "moment";
import React from "react";
import { useParams } from "./useParams";
export const useOrderStatusesCounts = () => {
  const { connectedUser } = useApplicationContext();
  const { params } = useParams();
  const orderStatusesCountsQuery = useOrder_Statuses_CountsQuery({
    fetchPolicy: "network-only",
    variables: {
      input: {
        from: moment(params.from).startOf("d").toISOString(),
        to: moment(params.to).endOf("d").toISOString(),
        entityCode: connectedUser?.entity?.code as string,
        idAdmin:
          connectedUser?.role.code === "USER"
            ? connectedUser?.admin?.id
            : undefined,
        preparer: `${connectedUser?.firstName} ${connectedUser?.lastName}`,
      },
    },
    skip: !connectedUser?.entity?.code,
  });

  return orderStatusesCountsQuery.data?.orderStatusesCounts || [];
};
