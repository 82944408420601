import { Column, Table } from "@components/Table";
import { useUsersQuery } from "@graphql/";
import { Typography, Box } from "@mui/material";
import { useApplicationContext } from "@src/context";
import { usePagination } from "@src/hooks";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export const Customers: FC = () => {
  const { connectedUser } = useApplicationContext();
  const { t } = useTranslation();
  const pagination = usePagination(false);
  const customersQuery = useUsersQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        entity: { code: { in: ["INDIVIDUAL", "PROFESSIONNAL"] } },
      },
      paging: pagination.offsetPaging,
    },
    skip: !connectedUser,
  });

  const customers = customersQuery.data?.users.nodes || [];

  const columns: Column[] = [
    {
      label: t("user.list.label.name"),
      name: "firstName",
    },
    {
      label: t("user.list.label.firstName"),
      name: "lastName",
    },
    {
      label: t("commons.email"),
      name: "email",
    },
  ];

  return (
    <Box>
      <Table
        title={t("serviceCustomer.customers.title")}
        columns={columns}
        data={customers}
        pagination={pagination}
      />
    </Box>
  );
};
