import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: `8px !important`,
      width: `87px !important`
    },
    selected: {
      background: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`
    },
  })
);
