import { Box, Button, Typography } from "@mui/material";
import React, { FC } from "react";
import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import { useTranslation, } from "react-i18next";

export const AccountLimited: FC = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const navigate = useNavigate();
  const handleGoToDetails = () => {
    navigate("/dashboard/account-limited");
  };
  return (
    <Box className={classes.root}>
      <Typography>{t("accountLimited.useAccountLimited")}</Typography>
      <Button variant="contained" color="primary" onClick={handleGoToDetails}>
      {t("accountLimited.learnMore")}
      </Button>
    </Box>
  );
};
