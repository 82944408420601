import { Modal } from "@components/Modal";
import {
  Box,
  Button,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, FC, useState } from "react";

interface VerifyCustomerFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (email: string) => void;
}

export const VerifyCustomerForm: FC<VerifyCustomerFormProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [email, setEmail] = useState<string>("");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit(email);
  };
  return (
    <Modal
      maxWidth="md"
      title="Vérification client"
      open={open}
      onClose={onClose}
      onCancel={onClose}
      actions={
        <Button
          variant="contained"
          color="primary"
          disabled={email === ""}
          onClick={handleSubmit}
        >
          Envoyer code
        </Button>
      }
    >
      <Box sx={{ minWidth: { xs: "unset", sm: 600 } }}>
        <Typography sx={{ mb: 1 }}>Entrer l'email du client:</Typography>
        <TextField
          value={email}
          onChange={handleChange}
          label="Email"
          fullWidth
        />
      </Box>
    </Modal>
  );
};
