import {
  AppBar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";
import SwitchLanguage from "@src/components/SwitchLanguage/SwitchLanguage";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./styles";

import LogoGm from "@src/assets/img/logo-gm.png";
import Pharmacien from "@src/assets/img/pharmacien.jpg";
import Livreur from "@src/assets/img/livreur.jpg";
import Client from "@src/assets/img/client.jpg";
import DownloadFromStores from "@src/assets/img/download-from-stores.png";
import { useTranslation } from "react-i18next";
import { Modal } from "@components/Modal";
import moment from "moment";
import franceFlag from "@src/assets/img/fr.png";
export const Home: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [details, setDetails] = useState({ title: "", description: "" });

  const handleDetailsClick = (title: string, description: string) => {
    setDetails({ description, title });
    setOpenDetails(true);
  };

  const currentYear = moment().format("YYYY");

  return (
    <Grid container>
      <Modal
        open={openDetails}
        onClose={() => setOpenDetails(false)}
        actions={<Button onClick={() => setOpenDetails(false)}>Fermer</Button>}
        maxWidth="xs"
      >
        <Typography variant="h4" color="primary" textAlign="center" mb={1}>
          {details.title}
        </Typography>
        <Typography textAlign="justify">{details.description}</Typography>
      </Modal>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar color="primary">
          <Box sx={{ flexGrow: 1 }}>
            <img src={LogoGm} height={64} />
          </Box>
          <SwitchLanguage />
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate("/signin")}
          >
            {t("home.login")}
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container padding={2} my={5} mb={4} maxWidth="lg" mx="auto">
        <Grid item xs={12} md={8} lg={8} mx="auto" py={5}>
          <Typography variant="h2" mb={1} color="primary" fontWeight={400}>
            DelivDose
          </Typography>
          <Typography fontWeight={300} variant="h6" mb={3}>
            {t("home.title")}
          </Typography>
          <img src={DownloadFromStores} width={150} />
        </Grid>
      </Grid>

      <Grid container padding={2} my={5} mb={4} maxWidth="lg" mx="auto">
        <Card sx={{ border: "1px solid #31956B" }}>
          <Grid container>
            <Grid item sm={4} xs={12}>
              <Grid container>
                <img src={Client} style={{ maxHeight: 450, width: "100%" }} />
              </Grid>
            </Grid>
            <Grid
              item
              sm={8}
              mx="auto"
              sx={{
                px: { xs: 2, sm: 0 },

                py: { xs: 2, sm: 0 },
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item md={6} mx="auto">
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  mb={1}
                  color="primary"
                >
                  {t("home.customerCard.title")}
                </Typography>
                <Typography variant="body1" mb={3}>
                  {t("home.customerCard.description")}
                </Typography>
                <Typography variant="body1" mb={3}>
                  {t("home.customerCard.description2")}
                </Typography>
                <Typography variant="body1" mb={3}>
                  {t("home.customerCard.description3")}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/signup?entity=customer")}
                  sx={{ textTransform: "none" }}
                >
                  {t("home.register")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid container padding={2} my={5} mb={4} maxWidth="lg" mx="auto">
        <Card sx={{ border: "1px solid #31956B" }}>
          <Grid container>
            <Grid
              item
              sm={8}
              mx="auto"
              sx={{
                px: { xs: 2, sm: 0 },

                py: { xs: 2, sm: 0 },
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item md={6} mx="auto">
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  mb={1}
                  color="primary"
                >
                  {t("home.pharmacyCard.title")}
                </Typography>
                <Typography variant="body1" mb={3}>
                  {t("home.pharmacyCard.description")}
                </Typography>
                <Typography variant="body1" mb={3}>
                  {t("home.pharmacyCard.description2")}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/signup?entity=pharmacy")}
                  sx={{ textTransform: "none" }}
                >
                  {t("home.register")}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container>
                <img
                  src={Pharmacien}
                  style={{ maxHeight: 450, width: "100%" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid container padding={2} my={5} mb={4} maxWidth="lg" mx="auto">
        <Card sx={{ border: "1px solid #31956B" }}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Grid container>
                <img src={Livreur} style={{ maxHeight: 450, width: "100%" }} />
              </Grid>
            </Grid>
            <Grid
              item
              sm={8}
              mx="auto"
              sx={{
                px: { xs: 2, sm: 0 },
                py: { xs: 2, sm: 0 },
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item md={6} mx="auto">
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  mb={1}
                  color="primary"
                >
                  {t("home.livreurCard.title")}
                </Typography>
                <Typography variant="body1" mb={3}>
                  {t("home.livreurCard.description")}
                </Typography>
                <Typography variant="body1" mb={3}>
                  {t("home.livreurCard.description2")}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/signup?entity=livreur")}
                  sx={{ textTransform: "none" }}
                >
                  {t("home.register")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid
        container
        my={5}
        mb={4}
        maxWidth="lg"
        spacing={2}
        px={{ xs: 2, sm: 0 }}
        mx="auto"
        display="flex"
      >
        <Grid item xs={12} sm={4} mb={{ xs: 6, sm: 0 }}>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16%"
                height="16%"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z"></path>
                <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z"></path>
              </svg>
              <Typography variant="h4" color="primary" mb={3}>
                {t("home.punctuality.title")}
              </Typography>
              <Typography variant="body1" textAlign="center" mb={3}>
                {t("home.punctuality.description")}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleDetailsClick(
                    t("home.punctuality.title"),
                    t("home.punctuality.details")
                  )
                }
                sx={{ textTransform: "none" }}
              >
                {t("home.showMore")}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} mb={{ xs: 6, sm: 0 }}>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16%"
                height="16%"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"></path>
                <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"></path>
              </svg>
              <Typography variant="h4" color="primary" mb={3}>
                {t("home.professionalism.title")}
              </Typography>
              <Typography variant="body1" textAlign="center" mb={3}>
                {t("home.professionalism.description")}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleDetailsClick(
                    t("home.professionalism.title"),
                    t("home.professionalism.details")
                  )
                }
                sx={{ textTransform: "none" }}
              >
                {t("home.showMore")}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16%"
                height="16%"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"></path>
              </svg>
              <Typography variant="h4" color="primary" mb={3}>
                {t("home.fast.title")}
              </Typography>
              <Typography variant="body1" textAlign="center" mb={3}>
                {t("home.fast.description")}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleDetailsClick(
                    t("home.fast.title"),
                    t("home.fast.details")
                  )
                }
                sx={{ textTransform: "none" }}
              >
                {t("home.showMore")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ background: "#31956B", pt: 5, pb: 2, width: "100%" }}>
        <Grid container padding={2} maxWidth="lg" mx="auto">
          <Grid container>
            <Grid item xs={6} sm={3} display="flex" flexDirection="column">
              <Typography variant="h6" fontWeight="bold" mb={1}>
                {t("home.availableOn")}
              </Typography>
              <Link underline="none" href="#" color="#fff">
                {t("home.appleStore")}
              </Link>
              <Link underline="none" href="#" color="#fff">
                {t("home.googlePlay")}
              </Link>
            </Grid>
            <Grid item xs={6} sm={3} display="flex" flexDirection="column">
              <Typography variant="h6" fontWeight="bold" mb={1}>
                {t("home.register")}
              </Typography>
              <Link
                underline="none"
                href="/signup?entity=customer"
                color="#fff"
              >
                {t("home.individual")}
              </Link>
              <Link
                underline="none"
                href="/signup?entity=customer"
                color="#fff"
              >
                {t("home.professional")}
              </Link>
              <Link
                underline="none"
                href="/signup?entity=pharmacy"
                color="#fff"
              >
                {t("home.pharmacy")}
              </Link>
              <Link underline="none" href="/signup?entity=livreur" color="#fff">
                {t("home.livreur")}
              </Link>
            </Grid>
            <Grid item xs={6} sm={3} display="flex" flexDirection="column">
              <Typography variant="h6" fontWeight="bold" mb={1}>
                {t("home.siteInformation")}
              </Typography>

              <Link underline="none" href="#" color="#fff">
                {t("home.infoLegales")}
              </Link>
              <Link underline="none" href="#" color="#fff">
                {t("home.termsAndConditions")}
              </Link>
              <Link underline="none" href="#" color="#fff">
                {t("home.privacyPolicy")}
              </Link>
            </Grid>
            <Grid item xs={6} sm={3} display="flex" flexDirection="column">
              <Typography variant="h6" fontWeight="bold" mb={1}>
                {t("home.about")}
              </Typography>

              <Link underline="none" href="#" color="#fff">
                {t("home.team")}
              </Link>
              <Link underline="none" href="#" color="#fff">
                {t("home.location")}
              </Link>
              <Link underline="none" href="#" color="#fff">
                {t("home.terms")}
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: 2 }} />
        <Typography variant="body1" color="white" mb={3} ml={1}>
          © GTA {t("home.team")} {currentYear}{" "}
          <img
            src={franceFlag}
            alt="French Flag"
            style={{ width: "20px", height: "auto" }}
          />
        </Typography>
      </Box>
    </Grid>
  );
};
