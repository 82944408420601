import { Address } from "@graphql/*";
import { LocationCity, Place, ShoppingCart } from "@mui/icons-material";
import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import React, { FC } from "react";
import { useStyles } from "./styles";

interface AddressMobileRowProps {
  address?: Address;
  actions?: (row: Address) => JSX.Element;
  onRowClick?: (row: Address) => void;
  selected?: any;
}

export const AddressMobileRow: FC<AddressMobileRowProps> = ({
  address,
  actions,
  onRowClick,
  selected,
}) => {
  const classes = useStyles({ selected, address });
  const theme = useTheme();
  return (
    <Card
      className={classes.root}
      sx={{
        mb: 3,
        border:
          address?.id === selected?.id
            ? `2px solid ${theme.palette.primary.main}`
            : "unset",
      }}
      onClick={() => onRowClick && address && onRowClick(address)}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            <Place sx={{ mr: 1 }} />
            {address?.name}
          </Typography>
          {address && actions && actions(address)}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography>
            <LocationCity sx={{ mr: 1 }} />
            {address?.city}
          </Typography>
          <Typography variant="h5" fontWeight="bold">
            {address?.zipcode}
          </Typography>
        </Box>
        <Box>
        <Typography>
            <ShoppingCart sx={{ mr: 1 }} />
            {address?.orders?.totalCount}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
