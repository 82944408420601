import { Modal } from "@components/Modal";
import { Button } from "@mui/material";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


interface ConfirmDeleteModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  loading: boolean;
  deleted?: string | null;
  onDelete: () => void;
  children?: ReactNode
}

export const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = ({
  open,
  setOpen,
  loading,
  onDelete,
  deleted,
  children
}) => {
  const { t } = useTranslation();

  const handleToggleOpenConfirmDialog = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!loading && deleted) {
      setOpen(false);
    }
  }, [loading, deleted]);

  return (
    <Modal
      open={open}
      onClose={handleToggleOpenConfirmDialog}
      title={t("commons.confirmDelete.title")}
      text={t("commons.confirmDelete.subTitle")}
      maxWidth="sm"
      actions={
        <>
          <Button
            color="error"
            variant="contained"
            onClick={handleToggleOpenConfirmDialog}
          >
            {t("commons.no").toUpperCase()}
          </Button>
          <Button color="primary" variant="contained" onClick={onDelete}>
            {t("commons.yes").toUpperCase()}

          </Button>
        </>
      }
    >
      {children}
    </Modal>
  );
};
