import React, { FC, useCallback } from "react";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  useMediaQuery,
  useTheme,
  SortDirection,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { Column } from "../Table";

export interface Sort {
  name: string;
  direction: SortDirection;
}
export interface TableHeaderProps {
  onRowClick?: (row: any) => void;
  columns: Column[];
  onSort?: (sortedColumns: Sort[]) => void;
  sort?: Sort[];
}
export const TableHeader: FC<TableHeaderProps> = ({
  onRowClick,
  columns,
  onSort,
  sort,
}) => {
  const theme = useTheme();
  const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const mobileColumns = columns.filter((column) => !column.breaking);
  const shownColumns = onlyMobile ? mobileColumns : columns;

  const getSortDirection = (column: Column) => {
    if (!column.sortable) {
      return;
    }
    const sorted =
      sort && sort.find((sortedColumn) => sortedColumn.name === column.name);
    return sorted?.direction;
  };

  const sortBy = useCallback(
    (name: string) => {
      let pendingChange = [...(sort || [])];
      const index = pendingChange.findIndex((config) => config.name === name);
      if (index > -1) {
        var currentSortType = pendingChange[index].direction;
        pendingChange.splice(index, 1);
        if (currentSortType === "desc") {
          pendingChange = [...pendingChange, { name: name, direction: "asc" }];
        }
      } else {
        pendingChange = [...pendingChange, { name: name, direction: "desc" }];
      }
      onSort && onSort([...pendingChange]);
    },
    [sort]
  );
  return (
    <TableHead>
      <TableRow>
        {onRowClick && (
          <TableCell valign="middle" key={`th-checkbox`}></TableCell>
        )}
        {shownColumns.map((column, index) => {
          const sorted =
            sort &&
            sort.find((sortedColumn) => sortedColumn.name === column.name);
          if (column.sortable) {
            return (
              <TableCell
                align={column.centered ? "center" : "left"}
                key={`th-${column.label}-${index}`}
                sortDirection={getSortDirection(column)}
              >
                <TableSortLabel
                  active={sorted?.name === column.name}
                  onClick={() => sortBy(column.name)}
                  direction={
                    getSortDirection(column) as "desc" | "asc" | undefined
                  }
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                align={column.centered ? "center" : "left"}
                key={`th-${column.label}-${index}`}
              >
                {column.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};
