import { Modal } from "@components/Modal";
import { StripeAccount, useStripe_Account_HistoriesQuery } from "@graphql/";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Card, CardContent } from "@mui/material";
import moment from "moment";
import React, { FC } from "react";

interface StripeAccountDetailsProps {
  show: boolean;
  onClose: () => void;
  stripeAccount?: StripeAccount;
}

const StripeAccountDetails: FC<StripeAccountDetailsProps> = ({
  stripeAccount,
  show,
  onClose,
}) => {
  const stripeAccountHistoriesQuery = useStripe_Account_HistoriesQuery({
    fetchPolicy: "network-only",
    variables: { filter: { idStripeAccount: { eq: stripeAccount?.id } } },
  });
  const stripeAccountHistories =
    stripeAccountHistoriesQuery.data?.stripeAccountHistories.nodes || [];

  const actions = {
    CREATION: "Création",
    RESTRICTED: "Limité",
    COMPLETED: "Validé",
    DELETED: "Supprimé",
  };

  return (
    <Modal open={show} maxWidth="lg" title="Historiques" onClose={onClose}>
      <Card>
        <CardContent>
          <Timeline>
            {stripeAccountHistories.map((stripeAccountHistory, index) => (
              <TimelineItem key={`timeline-item-${index}`}>
                <TimelineOppositeContent color="textSecondary">
                  {moment(stripeAccountHistory.createdAt).format(
                    "DD/MM/YYYY à HH:mm"
                  )}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {actions[stripeAccountHistory.action as keyof typeof actions]} (
                  {`${stripeAccountHistory.user.firstName} ${stripeAccountHistory.user.lastName}`}
                  )
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </CardContent>
      </Card>
    </Modal>
  );
};
export { StripeAccountDetails };
