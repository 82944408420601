import * as Types from '../../types';

import { gql } from '@apollo/client';
export type StripeAccountLinkInfoFragment = { __typename?: 'StripeAccountLink', id: string, idStripeAccount: string, url: string, type: string };

export const StripeAccountLinkInfoFragmentDoc = gql`
    fragment StripeAccountLinkInfo on StripeAccountLink {
  id
  idStripeAccount
  url
  type
}
    `;