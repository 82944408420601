import { FormWrapper } from "@components/Form/Form";
import { Modal } from "@components/Modal";
import {
  Typography,
} from "@mui/material";
import { useUsersMutations } from "@src/hooks";
import { InputProps } from "@src/types";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

interface ForgotPasswordFormProps {
  open: boolean;
  onClose: () => void;
}

export const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  const { sendForgotPassword, loading } = useUsersMutations();
  const initialCreateInput = {
    email: "",
  };

  const schema = yup
    .object({
      email: yup
        .string()
        .email(t("forgotPassword.errors.invalidEmail"))
        .required(t("forgotPassword.errors.invalidEmail")),
    })
    .required();

  const inputs: InputProps[] = [
    {
      "data-cy": "email",
      name: "email",
      label: t("commons.email"),
      type: "text",
      placeholder: t("commons.email"),
    },
  ];

  const handleSubmit = (state: any) => {
    sendForgotPassword({
      variables: {
        email: state.email,
      },
    }).then(() => onClose());
  };

  return (
    <Modal open={open} onClose={onClose} title={t("forgotPassword.title")} maxWidth="xs" dataCy="forgotPasswordModal">
      <Typography sx={{ mb: 2 }}>
        {t("forgotPassword.subTitle")}
      </Typography>
      <FormWrapper
        initialCreateInput={initialCreateInput}
        onSubmit={handleSubmit}
        onCancel={onClose}
        schema={schema}
        loading={loading}
        submitBtnLabel={t("commons.send")}
        cancelBtnLabel={t("commons.cancel")}
        inputs={inputs}
        submitBtnDataCy="forgotPasswordBtn"
        cancelBtnDatacy="close"
      />
    </Modal>
  );
};
