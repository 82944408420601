import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 123,
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        height: 100,
        "& .MuiTypography-root": {
          fontSize: "1rem",
        },
        "& .MuiSvgIcon-root": {
          width: "1.5rem",
          height: "1.5rem",
        },
      },
    },
  })
);
