import { UrlParams } from "@src/types";
import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

export const useUsersTermFilter = (params: UrlParams) => {
  const term = params.term || "";
  
  const getUserTermFilter = () => {
    let filter = {};
    if (term) {
      filter = { ...filter, ..._getTermFilter() };
    }
    return filter;
  };

  const _getTermFilter = () => {
    if (!term || term.length < 3) {
      return {};
    }
    return {
      or: [
        {
          firstName: {
            iLike: `%${term}%`,
          },
        },
        {
          lastName: {
            iLike: `%${term}%`,
          },
        },
        {
          email: {
            iLike: `%${term}%`,
          },
        },
        {
          bankAccount: {
            iban: { iLike: `%${term}%` },
          },
        },
      ],
    };
  };
  return { getUserTermFilter };
};
