import {
  useUpdate_Many_FilesMutation,
  useUpdate_One_FileMutation,
  useUpload_FilesMutation,
  FileInfoFragment,
} from "@graphql/";
import { useApplicationContext } from "@src/context";
import { useState } from "react";

interface Params {
  hideNofitications?: boolean;
  skipRefetch?: boolean;
}

interface FileInput {
  file: File;
  name: string;
  filename: string;
  type: string;
  idType?: string | null;
  idUser: string;
  status?: string;
}
export const useFilesMutations = (params?: Params) => {
  const { notify } = useApplicationContext();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const uploadFiles = async (
    fileInputs: FileInput[]
  ): Promise<FileInfoFragment[]> => {
    setIsUploading(true);
    const formData = new FormData();

    fileInputs.forEach(({ file, idUser, name, filename, type, idType }) => {
      formData.append("files", file);
      formData.append("idUser", idUser);
      formData.append("name", name);
      formData.append("filename", filename);
      if (idType) formData.append("idType", idType);
      formData.append("type", type);
    });
    try {
      const token = localStorage.getItem("token");
      return fetch(`${process.env.API_URL}/upload/files`, {
        method: "POST",
        body: formData,
        headers: {
          authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setIsUploading(false);
        if (!response.ok) {
          throw "Upload Error";
        }
        notify({
          message: "Le fichier a été mis à jours avec succès",
          type: "success",
        });
        return response.json();
      });
    } catch {
      setIsUploading(false);
      notify({
        message: "Une erreur s'est produite lors de la mise à jour du fichier",
        type: "error",
      });
      return [];
    }
  };

  const [updateFile, updatingFile] = useUpdate_One_FileMutation({
    refetchQueries: params?.skipRefetch ? ["ORDERS"] : undefined,
    onCompleted: () => {
      notify({
        message: "Le fichier a été mis à jours avec succès",
        type: "success",
      });
    },
    onError: () => {
      notify({
        message: "Une erreur s'est produite lors de la mise à jour du fichier",
        type: "error",
      });
    },
  });

  const [updateFiles, updatingFiles] = useUpdate_Many_FilesMutation({
    refetchQueries: ["ORDERS"],
    onCompleted: () => {
      notify({
        message: "Le(s) fichier(s) ont été mis à jours avec succès",
        type: "success",
      });
    },
    onError: () => {
      notify({
        message:
          "Une erreur s'est produite lors de la mise à jour de(s) fichier(s)",
        type: "error",
      });
    },
  });

  const loading = isUploading;

  return {
    updateFile,
    updatingFile,
    updateFiles,
    updatingFiles,
    loading,
    uploadFiles,
    isUploading,
  };
};
