import { StatsCard } from "@components/StatsCardNew";
import { ToggleButtons } from "@components/ToggleButtons";
import {
  useSuperadmin_Dashboard_Global_StatsQuery,
  useSuperadmin_Dashboard_Periodic_StatsQuery,
} from "@graphql/";
import {
  Euro,
  ListAlt,
  PeopleOutline,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {} from "echarts";
import { PieChart } from "@components/Charts/Pie";
import { LineChart } from "@components/Charts/Line";
import { useStyles } from "./styles";
import Echarts from "echarts-for-react";
import { useEchartsOptions } from "./useEchartsOptions";
import { PdfDownloaderBtn } from "@components/PdfDownloaderBtn";
import moment from "moment";
import { StripeTransfertStats } from "@components/StripeTransfertStats";

export const Dashboard: FC = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { userUserDevicesOptions, useAgesRangesOptions, useEntitiesOptions } =
    useEchartsOptions();
  const query = new URLSearchParams(search);
  const dateRange =
    (query.get("dateRange") as "week" | "month" | "year") || "week";
  const periodicStatsQuery = useSuperadmin_Dashboard_Periodic_StatsQuery({
    fetchPolicy: "cache-and-network",
    variables: { period: dateRange },
  });
  const periodicStats =
    periodicStatsQuery.data?.superadminDashboardPeriodicStats || {};
  const globalStatsQuery = useSuperadmin_Dashboard_Global_StatsQuery({
    fetchPolicy: "cache-and-network",
  });
  const globalStats =
    globalStatsQuery.data?.superadminDashboardGlobalStats || {};
  const toggleButtons = [
    {
      label: t("dashboard.filter.week"),
      value: "week",
    },
    { label: t("dashboard.filter.month"), value: "month" },
    { label: t("dashboard.filter.year"), value: "year" },
  ];
  const handleDateRangeChange = (value: string) => {
    navigate(`${pathname}?status=SUPERADMIN_DASHBOARD&dateRange=${value}`);
  };
  const orderLabels: Record<string, string> = {
    DONE: t("dashboard.DONE"),
    IN_PROGRESS_PHARMACY: t("dashboard.IN_PROGRESS_PHARMACY"),
    IN_PROGRESS_DELIVERY_MAN: t("dashboard.IN_PROGRESS_DELIVERY_MAN"),
    NEW: t("dashboard.NEW"),
    REJECTED: t("dashboard.REJECTED"),
  };
  const ordersStats = Object.keys(periodicStats?.ordersStats || {}).map(
    (key: string) => {
      return {
        name: orderLabels[key],
        value: periodicStats?.ordersStats[key],
      };
    }
  );

  const usersDevices = globalStats?.usersDevices || {};
  const agesRanges = globalStats?.agesRanges || [];
  const entities = globalStats?.entitiesStats || {};

  const userDevicesOptions = userUserDevicesOptions(
    Object.keys(usersDevices).map((key) => ({
      label: key,
      value: usersDevices[key],
    }))
  );

  const agesRangesOptions = useAgesRangesOptions(agesRanges);
  const entitiesOptions = useEntitiesOptions(
    Object.keys(entities).map((key) => entities[key])
  );

  return (
    <Box className={classes.root} id="superadmin-dashboard">
      <Card sx={{ p: 3 }}>
        <Box className={classes.filterRoot}>
          <PdfDownloaderBtn
            rootElementId="superadmin-dashboard"
            fileName={`superadmin-dashboard-${moment().format("x")}.pdf`}
            ignoredElementIds={["download-pdf-btn"]}
          />
          <ToggleButtons
            onChange={handleDateRangeChange}
            value={dateRange}
            toggleButtons={toggleButtons}
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography fontWeight="bold" variant="subtitle1">
            {t("dashboard.statistics")}
          </Typography>
          <Divider/>
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <StatsCard
                  label={t("dashboard.orders")}
                  value={periodicStats?.globalStats?.ORDERS || 0}
                  icon={<ShoppingCartOutlined fontSize="large" />}
                  color="linear-gradient(45deg, #4099ff, #73b4ff)"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <StatsCard
                  label={t("dashboard.income")}
                  value={periodicStats?.globalStats?.INCOMES || 0}
                  icon={<Euro fontSize="large" />}
                  color="linear-gradient(45deg, #2ed8b6, #59e0c5)"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <StatsCard
                  label={t("dashboard.users")}
                  value={periodicStats?.globalStats?.USERS || 0}
                  icon={<PeopleOutline fontSize="large" />}
                  color="linear-gradient(45deg, #FFB64D, #ffcb80)"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <StatsCard
                  label={t("dashboard.ticketToSolve")}
                  value={periodicStats?.globalStats?.SUPPORT_TICKETS || 0}
                  icon={<ListAlt fontSize="large" />}
                  color="linear-gradient(45deg, #FF5370, #ff869a)"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <StripeTransfertStats periodicStats={periodicStats} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardHeader title={t("dashboard.orders")} />
              <CardContent>
                <PieChart data={ordersStats} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardHeader title={t("dashboard.income")} />
              <CardContent>
                <LineChart
                  labels={(periodicStats?.incomeStats || []).map(
                    (el: { label: string; count: number }) => el.label
                  )}
                  values={(periodicStats?.incomeStats || []).map(
                    (el: { label: string; count: number }) => el.count
                  )}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Card>
      <Card sx={{ p: 3, mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader title={t("dashboard.materialType")} />
              <CardContent>
                <Echarts option={userDevicesOptions} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader title={t("dashboard.usersGroup")} />
              <CardContent>
                <Echarts option={agesRangesOptions} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader title={t("dashboard.users")} />
              <CardContent>
                <Echarts option={entitiesOptions} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
