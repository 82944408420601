import * as Types from '../../types';

import { gql } from '@apollo/client';
export type FileInfoFragment = { __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null };

export const FileInfoFragmentDoc = gql`
    fragment FileInfo on File {
  id
  idType
  idUser
  filename
  mimeType
  name
  status
  type
  url
  createdAt
  updatedAt
}
    `;