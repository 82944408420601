import { Search } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Table as MuiTable,
  OutlinedInput,
  Paper,
  Tab,
  TableBody,
  TableContainer,
  TablePagination,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, {
  ChangeEvent,
  FC,
  JSXElementConstructor,
  MouseEvent,
  ReactElement,
  ReactNode,
  UIEvent,
  useEffect,
  useState,
} from "react";
import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { Sort } from "./TableHeader/TableHeader";

export interface Column {
  name: string;
  label: string;
  sortable?: boolean;
  renderer?: (row: any, index: number) => any;
  editable?: boolean;
  centered?: boolean;
  breaking?: boolean;
}

interface Pagination {
  offsetPaging: {
    limit: number;
    offset: number;
  };
  setOffsetPaging: (offsetPaging: { limit: number; offset: number }) => void;
  totalCount: number;
}

export interface Tab {
  label: string;
  value: string;
  onClick: (value: string) => void;
}

interface TableProps {
  title?: string;
  columns: Column[];
  data: any;
  onFetchMore?: () => void;
  toolbar?: ReactNode;
  tabs?: Tab[];
  onRowClick?: (row: any) => void;
  selectedRow?: any;
  pagination?: Pagination;
  activeTab?: string | null;
  tableHeader?: ReactNode;
  mobileTableRow?: ReactElement<any, string | JSXElementConstructor<any>>;
  infiniteScrollHeight?: string;
  model?: string;
  onSearch?: (term: string) => void;
  term?: string | null;
  loading?: boolean;
  onSort?: (sortedColumns: Sort[]) => void;
  sort?: Sort[];
}
export const Table: FC<TableProps> = ({
  columns,
  data,
  title,
  toolbar,
  tabs,
  onFetchMore,
  onRowClick,
  selectedRow,
  pagination,
  activeTab,
  tableHeader,
  mobileTableRow,
  infiniteScrollHeight,
  model,
  onSearch,
  term,
  loading,
  onSort,
  sort,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const [selected, setSelected] = useState<any>(selectedRow);
  const theme = useTheme();
  const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchTerm, setSearchTerm] = useState<string>(term || "");

  const filteredData = data;

  const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleScroll = (e: UIEvent<HTMLTableSectionElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight;
    if (bottom && onlyMobile) {
      onFetchMore && onFetchMore();
    }
  };

  const handleRowClick = (row: any) => {
    if (selected?.id === row?.id) {
      setSelected(undefined);
      onRowClick && onRowClick(undefined);
    } else {
      setSelected(row);
      onRowClick && onRowClick(row);
    }
  };

  useEffect(() => {
    if (selected && data.length) {
      const changed = data.find((el: any) => el.id === selected.id);
      if (changed) {
        setSelected(changed);
        onRowClick && onRowClick(changed);
      } else {
        setSelected(undefined);
        onRowClick && onRowClick(undefined);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!term || term === "") {
      setSearchTerm("");
    }
  }, [term]);

  useEffect(() => {
    setSelected(selectedRow);
  }, [selectedRow]);

  useEffect(() => {
    const timeOutId = setTimeout(() => onSearch && onSearch(searchTerm), 1000);
    return () => clearTimeout(timeOutId);
  }, [searchTerm]);

  const handleChangePage = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
    page: number
  ) => {
    pagination?.setOffsetPaging({
      limit: pagination.offsetPaging.limit,
      offset: page * pagination.offsetPaging.limit,
    });
  };

  const handleChangeRowsPerPage = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    pagination?.setOffsetPaging({
      limit: e.target.value as unknown as number,
      offset: 0,
    });
  };

  return (
    <Box>
      {title && (
        <Box>
          <Typography variant="h4" textAlign="center" color="primary" mb={2}>
            {title}
          </Typography>
        </Box>
      )}
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        spacing={2}
      >
        <Grid item xs={12} lg={3}>
          {onSearch && (
            <OutlinedInput
              onChange={handleSearchValueChange}
              endAdornment={<Search />}
              fullWidth
              sx={{ borderRadius: 8 }}
              value={searchTerm}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={9}>
          {toolbar}
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      {onlyMobile && mobileTableRow && model ? (
        <div
          className={classes.mobileRoot}
          style={{ height: infiniteScrollHeight }}
          onScroll={handleScroll}
        >
          {tableHeader}
          {filteredData?.length === 0 && !loading ? (
            <Typography variant="body2" textAlign="center" fontWeight="bold">
              {t("table.noItemToDisplay")}
            </Typography>
          ) : (
            filteredData.map((row: any, rowIndex: number) =>
              React.cloneElement(mobileTableRow, {
                [model]: row,
                key: `mobile-row-${rowIndex}`,
              })
            )
          )}
          {loading && (
            <Box my={3} display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
        </div>
      ) : (
        <div>
          {tableHeader}
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 500 }}
            onScroll={handleScroll}
          >
            {activeTab && tabs && (
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={activeTab}>
                  {tabs.map((tab) => (
                    <Tab
                      value={tab.value}
                      label={tab.label}
                      onClick={() => tab.onClick(tab.value)}
                    />
                  ))}
                </Tabs>
              </Box>
            )}
            <MuiTable
              stickyHeader
              sx={{ minWidth: 650 }}
              size="small"
              className={classes.root}
            >
              <TableHeader
                columns={columns}
                onRowClick={onRowClick}
                onSort={onSort}
                sort={sort}
              />
              <TableBody onScroll={handleScroll}>
                {!loading &&
                  filteredData.map((row: any, rowIndex: number) => (
                    <TableRow
                      columns={columns}
                      handleRowClick={handleRowClick}
                      row={row}
                      rowIndex={rowIndex}
                      selected={selected}
                      onRowClick={onRowClick}
                    />
                  ))}
              </TableBody>
            </MuiTable>
            {loading ? (
              <Box my={3} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              !filteredData.length && (
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  my={3}
                  textAlign="center"
                >
                  {t("commons.noItemToDisplay")}
                </Typography>
              )
            )}
          </TableContainer>
          {pagination && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={pagination.totalCount}
              rowsPerPage={pagination.offsetPaging.limit}
              page={
                pagination.offsetPaging.offset / pagination.offsetPaging.limit
              }
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("table.linesPerPage")}
              labelDisplayedRows={({ count, from, to, page }) =>
                `${from}-${to} ${t("table.of")} ${count}`
              }
            />
          )}
        </div>
      )}
    </Box>
  );
};
