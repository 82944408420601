import * as Types from '../../types';

import { gql } from '@apollo/client';
import { UserHistoryInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_One_User_HistoryMutationVariables = Types.Exact<{
  input: Types.CreateOneUserHistoryInput;
}>;


export type Create_One_User_HistoryMutation = { __typename?: 'Mutation', createOneUserHistory: { __typename?: 'UserHistory', id: string, idUser: string, action: string, description: string } };


export const Create_One_User_HistoryDocument = gql`
    mutation CREATE_ONE_USER_HISTORY($input: CreateOneUserHistoryInput!) {
  createOneUserHistory(input: $input) {
    ...UserHistoryInfo
  }
}
    ${UserHistoryInfoFragmentDoc}`;
export type Create_One_User_HistoryMutationFn = Apollo.MutationFunction<Create_One_User_HistoryMutation, Create_One_User_HistoryMutationVariables>;

/**
 * __useCreate_One_User_HistoryMutation__
 *
 * To run a mutation, you first call `useCreate_One_User_HistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_One_User_HistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneUserHistoryMutation, { data, loading, error }] = useCreate_One_User_HistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_One_User_HistoryMutation(baseOptions?: Apollo.MutationHookOptions<Create_One_User_HistoryMutation, Create_One_User_HistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_One_User_HistoryMutation, Create_One_User_HistoryMutationVariables>(Create_One_User_HistoryDocument, options);
      }
export type Create_One_User_HistoryMutationHookResult = ReturnType<typeof useCreate_One_User_HistoryMutation>;
export type Create_One_User_HistoryMutationResult = Apollo.MutationResult<Create_One_User_HistoryMutation>;
export type Create_One_User_HistoryMutationOptions = Apollo.BaseMutationOptions<Create_One_User_HistoryMutation, Create_One_User_HistoryMutationVariables>;