import {
  useCreate_One_AddressMutation,
  useDelete_One_AddressMutation,
  useUpdate_One_AddressMutation,
} from "@graphql/";
import { useApplicationContext } from "@src/context";
export const useAddressMutations = (handleToggleForm: () => void) => {
  const { notify } = useApplicationContext();
  const [createOneAddress] = useCreate_One_AddressMutation({
    refetchQueries: ["ADDRESSES"],
    onCompleted: () => {
      handleToggleForm()
      notify({
        message: "Votre adresse a été ajouté avec succès",
        type: "success",
      });
    },
    onError: () => {
      notify({
        message: "Une erreur s'est produite lors de l'ajout de votre adresse",
        type: "error",
      });
    },
  });

  const [updateOneAddress] = useUpdate_One_AddressMutation({
    refetchQueries: ["ADDRESSES"],
    onCompleted: () => {
      handleToggleForm();
      notify({
        message: "Votre adresse a été modifié avec succès",
        type: "success",
      });
    },
    onError: () => {
      notify({
        message:
          "Une erreur s'est produite lors de la moditication de votre adresse",
        type: "error",
      });
    },
  });

  const [deleteOneAddress, deletingOneAddress] = useDelete_One_AddressMutation({
    refetchQueries: ["ADDRESSES"],
    onCompleted: (data) => {
      handleToggleForm();
      notify({
        message: "Votre adresse a été supprimé avec succès",
        type: "success",
      });
    },
    onError: () => {
      notify({
        message:
          "Une erreur s'est produite lors de la suppression de votre adresse",
        type: "error",
      });
    },
  });

  return {
    createOneAddress,
    deleteOneAddress,
    updateOneAddress,
    deletingOneAddress,
  };
};
