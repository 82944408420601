import { Map } from "@components/Map";
import { Typography, Box } from "@mui/material";
import React, { FC, useState } from "react";
import { useStyles } from "./styles";
interface DistanceProps {
  id: string;
  depart: google.maps.LatLngLiteral;
  arrive: google.maps.LatLngLiteral;
}

const Distance: FC<DistanceProps> = ({ id, depart, arrive }) => {
  const classes = useStyles();
  const [distanceMatrixResponse, setDistanceMatrixResponse] =
    useState<google.maps.DistanceMatrixResponse | null>(null);
  const handleDistanceMatrixResponse = (
    response: google.maps.DistanceMatrixResponse | null,
    status: google.maps.DistanceMatrixStatus
  ) => {
    setDistanceMatrixResponse(response);
  };
  return (
    <Box className={classes.root}>
      {distanceMatrixResponse && (
        <Box>
          <Typography>
            <span>Distance du trajet:</span>{" "}
            {distanceMatrixResponse.rows[0].elements[0]?.distance?.text}
          </Typography>
          <Typography>
            <span>Durée estimée du trajet:</span>{" "}
            {distanceMatrixResponse.rows[0].elements[0]?.duration?.text}
          </Typography>
        </Box>
      )}
      <Map
        id={id}
        withAutoComplete={false}
        depart={depart}
        arrive={arrive}
        onDistanceMatrixResponse={handleDistanceMatrixResponse}
      />
    </Box>
  );
};

export { Distance };
