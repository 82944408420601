import { User } from "@graphql/*";
import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import React, { FC } from "react";
import { useStyles } from "./styles";
import { useUsersMutations } from "@src/hooks";
import { ChipSelect } from "@components/ChipSelect";
import { Role, roles, userStatuses } from "@src/constants";

interface UserMobileRowProps {
  user?: User;
  onRowClick?: (row: User) => void;
  selected?: any;
  actions?: (row: User) => JSX.Element;
}

export const UserMobileRow: FC<UserMobileRowProps> = ({
  user,
  onRowClick,
  selected,
  actions,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { updateUser } = useUsersMutations();

  const handleUpdateUserStatus = (value: string, id: string) => {
    updateUser({ variables: { input: { id, update: { status: value } } } });
  };

  const handleChange = (value: string) => {
    if (user?.id) {
      handleUpdateUserStatus(value, user.id);
    }
  };
  return (
    <Card
      className={classes.root}
      sx={{
        mb: 3,
        border:
          user?.id === selected?.id
            ? `2px solid ${theme.palette.primary.main}`
            : "unset",
      }}
      onClick={() => onRowClick && user && onRowClick(user)}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            {`${user?.firstName} ${user?.lastName}`}
          </Typography>
          {user && actions && actions(user)}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="body2">{user?.email}</Typography>
          <Typography variant="body2" fontWeight="bold">
            {roles[user?.role?.code as Role]}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          {user && (
            <ChipSelect
              menuItems={userStatuses}
              onChange={handleChange}
              value={user.status}
              width={230}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
