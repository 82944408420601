import { Addchart } from "@mui/icons-material";
import { Icon } from "@mui/material";
import { useParams } from "@src/hooks";
import { orderStatuses } from "@utils/constants";
import { encodeParam } from "@utils/encodeParams";
import { useTranslation } from "react-i18next";
import React from "react";
import { useApplicationContext } from "@src/context";

export const useLivreur = () => {
  const { t } = useTranslation();
  const livreurOrderStatuses = ["READY", "ASSIGNED", "DELIVERED"];
  const { params } = useParams();
  const { connectedUserType } = useApplicationContext();
  const livreurItems =
    connectedUserType === "LIVREUR"
      ? [
          {
            name: "PHARMACY_DASHBOARD",
            pathname: `/dashboard/pharmacy-dashboard?status=PHARMACY_DASHBOARD&dateRange=week`,
            label: "Dashboard",
            count: 0,
            icon: <Addchart />,
          },
          ...livreurOrderStatuses.map((status) => {
            const orderStatus = orderStatuses.find(
              (orderStatus) => orderStatus.status === status
            );
            return {
              name: status,
              pathname: `/dashboard/my-orders?${encodeParam({
                status: status,
                offset: 0,
                limit: params.limit,
                from: params.from,
                to: params.to,
                collapsed: "ALL_ORDERS",
              })}`,
              label: t(`sideNav.orders.${status}`),
              count: 0,
              icon: <Icon component={orderStatus!.icon} />,
            };
          }),
        ]
      : [];
  return { livreurItems };
};
