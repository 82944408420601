import { MoreHorizMenu } from "@components/MoreHorizMenu";
import { StatusChip } from "@components/StatusChip";
import { StripeAccountDetails } from "@components/StripeAccountDetails";
import { Column, Table } from "@components/Table";
import {
  User,
  useCreate_One_Stripe_AccountMutation,
  useCreate_One_Stripe_Account_LinkMutation,
  useDelete_One_Stripe_AccountMutation,
  useUsersQuery,
} from "@graphql/";
import { ChildCare, FileDownload } from "@mui/icons-material";
import { Box, Chip, IconButton } from "@mui/material";
import {
  Entity,
  StripeAccountStatus,
  entities,
  stripeAccountStatuses,
} from "@src/constants";
import { usePagination, useParams, useUsersTermFilter } from "@src/hooks";
import { decryptFilePublicUrl } from "@utils/decryptFilePublicUrl";
import { encodeParam } from "@utils/encodeParams";
import { getUserFile } from "@utils/getUserFile";
import moment from "moment";
import React, { FC, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
export const StripeAccounts: FC = () => {
  const pagination = usePagination();
  const [createOneStripeAccount] = useCreate_One_Stripe_AccountMutation();
  const [createOneStripeAccountLink] =
    useCreate_One_Stripe_Account_LinkMutation();
  const [deleteOneStripeAccount] = useDelete_One_Stripe_AccountMutation();
  const { params } = useParams();
  const [user, setUser] = useState<User>();
  const { getUserTermFilter } = useUsersTermFilter(params);
  const status = params.status || "ALL_STRIPE_ACCOUNT";
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const usersQuery = useUsersQuery({
    fetchPolicy: "network-only",
    variables: {
      filter: {
        entity: {
          code: {
            in: ["PHARMACY", "LIVREUR"],
          },
        },
        idAdmin: {
          eq: null,
        },
        stripeAccount:
          status === "NOT_CREATED"
            ? undefined
            : status === "PENDING"
            ? { status: { in: ["PENDING", "DELETED"] } }
            : { status: { eq: status } },
        ...getUserTermFilter(),
      },
      paging: pagination.offsetPaging,
    },
    onCompleted: (data) => {
      pagination.setTotalCount(data.users.totalCount);
    },
  });
  const [showStripeAccounDetails, setShowStripeAccounDetails] =
    useState<boolean>(false);

  const users =
    usersQuery.data?.users.nodes.filter((user) =>
      status === "NOT_CREATED" ? !user.stripeAccount && !user.idAdmin : user
    ) || [];

  const disableCreate = (user: User) => {
    if (user.entity?.code === "LIVREUR") {
      return user.stripeAccount || !user.addresses?.length || !user.bankAccount;
    }
    return (
      user.stripeAccount ||
      !user.infoLegale ||
      !user.addresses?.length ||
      !user.bankAccount
    );
  };

  const renderFile = (user: User, name: string) => {
    const file = getUserFile(user, name);
    return file ? (
      <Box display="flex">
        <StatusChip status={file.status} />
        <Link to={decryptFilePublicUrl(file.url)} target="_blank" download>
          <IconButton>
            <FileDownload />
          </IconButton>
        </Link>
      </Box>
    ) : (
      "-"
    );
  };

  const actions = (row: User) => {
    const handleSelect = (selected: string) => {
      setUser(row);
      switch (selected) {
        case "CREATE":
          createOneStripeAccount({
            variables: { input: { stripeAccount: { idUser: row.id } } },
          });
          break;
        case "VALIDATE":
          createOneStripeAccountLink({
            variables: {
              input: {
                stripeAccountLink: {
                  idStripeAccount: row.stripeAccount!.id,
                  type: "account_onboarding",
                },
              },
            },
          }).then(({ data }) => {
            window.open(
              data?.createOneStripeAccountLink.url,
              "_blank",
              "noreferrer"
            );
          });
          break;
        case "DELETE":
          if (row.stripeAccount)
            deleteOneStripeAccount({
              variables: { input: { id: row.stripeAccount?.id } },
            });
          break;
        case "PENDING":
          createOneStripeAccountLink({
            variables: {
              input: {
                stripeAccountLink: {
                  idStripeAccount: row.stripeAccount!.id,
                  type: "account_update",
                },
              },
            },
          }).then(({ data }) => {
            window.open(
              data?.createOneStripeAccountLink.url,
              "_blank",
              "noreferrer"
            );
          });
          break;
        case "HISTORY":
          setShowStripeAccounDetails(true);
          break;
      }
    };

    const moreHorizMenus = [
      {
        label: "Créer le compte",
        value: "CREATE",
        disabled: disableCreate(row),
      },
      {
        label: "Valider le compte",
        value: "VALIDATE",
        disabled: row.stripeAccount?.status !== "RESTRICTED",
      },
      // {
      //   label: "Suspendre le compte",
      //   value: "PENDING",
      //   disabled: row.stripeAccount?.status !== "COMPLETED",
      // },
      {
        label: "Supprimer le compte",
        value: "DELETE",
        disabled:
          row.stripeAccount?.status === "DELETED" ||
          !disableCreate(row) ||
          status === "NOT_CREATED",
      },
      {
        label: "Voir l'historique",
        value: "HISTORY",
        disabled: !disableCreate(row) || status === "NOT_CREATED",
      },
    ];
    return (
      <MoreHorizMenu
        disabled={!moreHorizMenus.length}
        items={moreHorizMenus}
        onSelect={handleSelect}
      />
    );
  };

  const columns: Column[] = [
    {
      label: "Partenaire",
      name: "",
      renderer: (row: User) => {
        return entities[row.entity?.code as Entity];
      },
    },
    {
      label: "Intitulé",
      name: "",
      renderer: (row: User) => {
        return row.entity?.code === "PHARMACY"
          ? row.infoLegale?.name
          : `${row.firstName} ${row.lastName}`;
      },
    },
    {
      label: "Adresse",
      name: "",
      renderer: (row: User) => {
        return row.addresses?.[0]?.name;
      },
    },
    {
      label: "IBAN",
      name: "",
      renderer: (row: User) => {
        return row.bankAccount?.iban;
      },
    },
    {
      label: "RIB",
      name: "",
      renderer: (row: User) => {
        return renderFile(row, "RIB");
      },
      centered: true
    },
    {
      label: "K-BIS",
      name: "",
      renderer: (row: User) => {
        return renderFile(row, "KBIS");
      },
      centered: true
    },
    {
      label: "Contrat",
      name: "",
      renderer: (row: User) => {
        return renderFile(row, "CONTRAT");
      },
      centered: true
    },
    {
      label: "Statut",
      name: "",
      renderer: (row: User) => {
        return stripeAccountStatuses[
          (row.stripeAccount?.status || "NOT_CREATED") as StripeAccountStatus
        ];
      },
    },
    {
      label: "Date de création",
      name: "",
      renderer: (row: User) => {
        const status = row.stripeAccount?.status;
        if (status) {
          return moment(row.stripeAccount?.createdAt).format("DD/MM/YYYY");
        }
        return moment(row.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      label: "Date de validation",
      name: "",
      renderer: (row: User) => {
        const validatedAt = row.stripeAccount?.validatedAt;
        if (validatedAt) {
          return moment(validatedAt).format("DD/MM/YYYY");
        }
        return "-";
      },
    },
    {
      label: "Date de suppression",
      name: "",
      renderer: (row: User) => {
        const deletedAt = row.stripeAccount?.deletedAt;
        if (deletedAt) {
          return moment(deletedAt).format("DD/MM/YYYY");
        }
        return "-";
      },
    },
    {
      label: "Fait par",
      name: "",
      renderer: (row: User) => {
        const updatedBy = row.stripeAccount?.updatedBy;
        if (updatedBy) {
          return `${updatedBy.firstName} ${updatedBy.lastName}`;
        }
        return "-";
      },
    },
    {
      label: "Action",
      name: "",
      renderer: actions,
    },
  ];

  const handleSearch = (term: string) => {
    const query = encodeParam({
      ...params,
      offset: 0,
      term,
    });
    navigate(`${pathname}?${query}`);
  };

  return (
    <Box>
      <Table
        columns={columns}
        data={users}
        title=""
        pagination={pagination}
        infiniteScrollHeight="calc(100vh - 270px)"
        onSearch={handleSearch}
        term={params.term}
      />
      <StripeAccountDetails
        show={showStripeAccounDetails}
        onClose={() => {
          setUser(undefined);
          setShowStripeAccounDetails(false);
        }}
        stripeAccount={user?.stripeAccount}
      />
    </Box>
  );
};
