import { useTranslation } from "react-i18next";
import { Modal } from "@components/Modal";
import { SupportTicket, useSupport_Ticket_HistoriesQuery } from "@graphql/";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import moment from "moment";
import React, { FC } from "react";
import { getFileType } from "@utils/getFileType";
import { PdfViewer } from "@components/PdfViewer/PdfViewer";
import { decryptFilePublicUrl } from "@utils/decryptFilePublicUrl";

interface SupportTicketDetailsProps {
  open: boolean;
  onClose: () => void;
  supportTicket?: SupportTicket;
}

export const SupportTicketDetails: FC<SupportTicketDetailsProps> = ({
  supportTicket,
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  const supportTicketHistoriesQuery = useSupport_Ticket_HistoriesQuery({
    variables: { filter: { idSupportTicket: { eq: supportTicket?.id } } },
    skip: !supportTicket,
  });
  const supportTicketHistories =
    supportTicketHistoriesQuery.data?.supportTicketHistories.nodes || [];

  return (
    <Modal
      open={open}
      maxWidth="xl"
      onClose={onClose}
      title={`${t("supportTicket.list.details.title")} ${supportTicket?.ref}`}
    >
      {supportTicket?.files?.length && (
        <Card>
          <CardHeader title="Document" />
          <CardContent>
            {supportTicket?.files.map((file) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: 500
                  }}
                >
                  {getFileType(file.mimeType) === "pdf" && (
                    <PdfViewer
                      url={decryptFilePublicUrl(file.url)}
                      width={600}
                    />
                  )}
                  {getFileType(file.mimeType) === "image" && (
                    <img
                      src={decryptFilePublicUrl(file.url)}
                      alt={`file-${file.id}`}
                    />
                  )}
                </Box>
              );
            })}
          </CardContent>
        </Card>
      )}
      <Card>
        <CardHeader title={t("supportTicket.list.details.description")} />
        <CardContent>
          <Typography>
            {supportTicket?.description ||
              t("supportTicket.list.details.noDescription")}
          </Typography>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title={t("supportTicket.list.details.historical")} />
        <CardContent>
          <Timeline>
            {supportTicketHistories.map((supportTicketHistory, index) => (
              <TimelineItem key={`timeline-item-${index}`}>
                <TimelineOppositeContent color="textSecondary">
                  {moment(supportTicketHistory.createdAt).format(
                    "DD/MM/YYYY à HH:mm"
                  )}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>{supportTicketHistory.action}</TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </CardContent>
      </Card>
    </Modal>
  );
};
