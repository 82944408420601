import * as Types from '../../types';

import { gql } from '@apollo/client';
export type RoleInfoFragment = { __typename?: 'Role', id: string, name: string, code: string, createdAt: any, updatedAt?: any | null };

export const RoleInfoFragmentDoc = gql`
    fragment RoleInfo on Role {
  id
  name
  code
  createdAt
  updatedAt
}
    `;