import * as Types from '../../types';

import { gql } from '@apollo/client';
import { SupportTicketInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_One_Support_TicketMutationVariables = Types.Exact<{
  input: Types.CreateOneSupportTicketInput;
}>;


export type Create_One_Support_TicketMutation = { __typename?: 'Mutation', createOneSupportTicket: { __typename?: 'SupportTicket', id: string, idOrder?: string | null, idCustomer: string, idServiceCustomer?: string | null, unreadSupportTicketMessageCount: number, category: string, priority: string, ref: string, subject: string, description?: string | null, status: string, createdAt: any, updatedAt?: any | null, order?: { __typename?: 'Order', id: string, ref: string } | null, customer?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null, serviceCustomer?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null, files?: Array<{ __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null }> | null } };

export type Update_One_Support_TicketMutationVariables = Types.Exact<{
  input: Types.UpdateOneSupportTicketInput;
}>;


export type Update_One_Support_TicketMutation = { __typename?: 'Mutation', updateOneSupportTicket: { __typename?: 'SupportTicket', id: string, idOrder?: string | null, idCustomer: string, idServiceCustomer?: string | null, unreadSupportTicketMessageCount: number, category: string, priority: string, ref: string, subject: string, description?: string | null, status: string, createdAt: any, updatedAt?: any | null, order?: { __typename?: 'Order', id: string, ref: string } | null, customer?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null, serviceCustomer?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null, files?: Array<{ __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null }> | null } };


export const Create_One_Support_TicketDocument = gql`
    mutation CREATE_ONE_SUPPORT_TICKET($input: CreateOneSupportTicketInput!) {
  createOneSupportTicket(input: $input) {
    ...SupportTicketInfo
  }
}
    ${SupportTicketInfoFragmentDoc}`;
export type Create_One_Support_TicketMutationFn = Apollo.MutationFunction<Create_One_Support_TicketMutation, Create_One_Support_TicketMutationVariables>;

/**
 * __useCreate_One_Support_TicketMutation__
 *
 * To run a mutation, you first call `useCreate_One_Support_TicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_One_Support_TicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneSupportTicketMutation, { data, loading, error }] = useCreate_One_Support_TicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_One_Support_TicketMutation(baseOptions?: Apollo.MutationHookOptions<Create_One_Support_TicketMutation, Create_One_Support_TicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_One_Support_TicketMutation, Create_One_Support_TicketMutationVariables>(Create_One_Support_TicketDocument, options);
      }
export type Create_One_Support_TicketMutationHookResult = ReturnType<typeof useCreate_One_Support_TicketMutation>;
export type Create_One_Support_TicketMutationResult = Apollo.MutationResult<Create_One_Support_TicketMutation>;
export type Create_One_Support_TicketMutationOptions = Apollo.BaseMutationOptions<Create_One_Support_TicketMutation, Create_One_Support_TicketMutationVariables>;
export const Update_One_Support_TicketDocument = gql`
    mutation UPDATE_ONE_SUPPORT_TICKET($input: UpdateOneSupportTicketInput!) {
  updateOneSupportTicket(input: $input) {
    ...SupportTicketInfo
  }
}
    ${SupportTicketInfoFragmentDoc}`;
export type Update_One_Support_TicketMutationFn = Apollo.MutationFunction<Update_One_Support_TicketMutation, Update_One_Support_TicketMutationVariables>;

/**
 * __useUpdate_One_Support_TicketMutation__
 *
 * To run a mutation, you first call `useUpdate_One_Support_TicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_One_Support_TicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneSupportTicketMutation, { data, loading, error }] = useUpdate_One_Support_TicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_One_Support_TicketMutation(baseOptions?: Apollo.MutationHookOptions<Update_One_Support_TicketMutation, Update_One_Support_TicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_One_Support_TicketMutation, Update_One_Support_TicketMutationVariables>(Update_One_Support_TicketDocument, options);
      }
export type Update_One_Support_TicketMutationHookResult = ReturnType<typeof useUpdate_One_Support_TicketMutation>;
export type Update_One_Support_TicketMutationResult = Apollo.MutationResult<Update_One_Support_TicketMutation>;
export type Update_One_Support_TicketMutationOptions = Apollo.BaseMutationOptions<Update_One_Support_TicketMutation, Update_One_Support_TicketMutationVariables>;