import * as Types from '../../types';

import { gql } from '@apollo/client';
export type TarificationInfoFragment = { __typename?: 'Tarification', id: string, dateDebut: any, dateFin: any, tva: number, prixKmJour: number, prixKmSoir: number, tarifMinimum: number, deliveryManPercentage: number };

export const TarificationInfoFragmentDoc = gql`
    fragment TarificationInfo on Tarification {
  id
  dateDebut
  dateFin
  tva
  prixKmJour
  prixKmSoir
  tarifMinimum
  deliveryManPercentage
}
    `;