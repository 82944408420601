import * as Types from '../../types';

import { gql } from '@apollo/client';
import { SupportTicketMessageInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Support_Ticket_MessagesQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.SupportTicketMessageFilter>;
  sorting?: Types.InputMaybe<Array<Types.SupportTicketMessageSort> | Types.SupportTicketMessageSort>;
}>;


export type Support_Ticket_MessagesQuery = { __typename?: 'Query', supportTicketMessages: { __typename?: 'SupportTicketMessageConnection', totalCount: number, nodes: Array<{ __typename?: 'SupportTicketMessage', id: string, idSupportTicket: string, idSender: string, message: string, status: string, createdAt: any, sender: { __typename?: 'User', id: string, role: { __typename?: 'Role', id: string, code: string } } }> } };


export const Support_Ticket_MessagesDocument = gql`
    query SUPPORT_TICKET_MESSAGES($paging: OffsetPaging, $filter: SupportTicketMessageFilter, $sorting: [SupportTicketMessageSort!]) {
  supportTicketMessages(paging: $paging, filter: $filter, sorting: $sorting) {
    nodes {
      ...SupportTicketMessageInfo
    }
    totalCount
  }
}
    ${SupportTicketMessageInfoFragmentDoc}`;

/**
 * __useSupport_Ticket_MessagesQuery__
 *
 * To run a query within a React component, call `useSupport_Ticket_MessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupport_Ticket_MessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupport_Ticket_MessagesQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSupport_Ticket_MessagesQuery(baseOptions?: Apollo.QueryHookOptions<Support_Ticket_MessagesQuery, Support_Ticket_MessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Support_Ticket_MessagesQuery, Support_Ticket_MessagesQueryVariables>(Support_Ticket_MessagesDocument, options);
      }
export function useSupport_Ticket_MessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Support_Ticket_MessagesQuery, Support_Ticket_MessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Support_Ticket_MessagesQuery, Support_Ticket_MessagesQueryVariables>(Support_Ticket_MessagesDocument, options);
        }
export type Support_Ticket_MessagesQueryHookResult = ReturnType<typeof useSupport_Ticket_MessagesQuery>;
export type Support_Ticket_MessagesLazyQueryHookResult = ReturnType<typeof useSupport_Ticket_MessagesLazyQuery>;
export type Support_Ticket_MessagesQueryResult = Apollo.QueryResult<Support_Ticket_MessagesQuery, Support_Ticket_MessagesQueryVariables>;