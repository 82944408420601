import { useOrderMutations } from "@components/CreateOrderStepper/useOrderMutations";
import { MoreHorizMenu } from "@components/MoreHorizMenu";
import { Column } from "@components/Table";
import { Order, User } from "@graphql/";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

export const useLivreurProps = ({
  setOrder,
  setShowDeliveryDetails,
  entity,
  connectedUser,
  setShowDeliveryCodeForm,
}: {
  setShowDeliveryCodeForm: Dispatch<SetStateAction<boolean>>;
  setShowDeliveryDetails: Dispatch<SetStateAction<boolean>>;
  entity?: { code: string } | null;
  setOrder: Dispatch<SetStateAction<Order | undefined>>;
  connectedUser?: User;
}) => {
  const { t } = useTranslation();

  const { updateOneOrder } = useOrderMutations();
  const isLimitedAccount =
    connectedUser?.bankAccountFiles?.some(
      (file) => file.status === "INVALID"
    ) || connectedUser?.vehicleFiles?.some((file) => file.status === "INVALID");
  const isVerifyingAccount =
    connectedUser?.bankAccountFiles?.some(
      (file) => file.status === "NOT_VERIFIED"
    ) ||
    connectedUser?.vehicleFiles?.some((file) => file.status === "NOT_VERIFIED");

  const livreurActions = (row: Order) => {
    const handleSelect = (selected: string) => {
      setOrder(row);
      switch (selected) {
        case "ASSIGN":
          updateOneOrder({
            variables: {
              input: {
                id: row.id,
                update: {
                  idDeliveryMan: connectedUser?.id,
                  status: "ASSIGNED",
                },
              },
            },
          });
          break;
        case "UNASSIGN":
          updateOneOrder({
            variables: {
              input: {
                id: row.id,
                update: { idDeliveryMan: null, status: "READY" },
              },
            },
          });
          break;
        case "DETAILS":
          setShowDeliveryDetails(true);
          break;
        case "MARK_AS_DELIVERED":
          setShowDeliveryCodeForm(true);
      }
    };
    const menu = [
      {
        label: t("commons.assignItToMe"),
        value: "ASSIGN",
        disabled:
          row.idDeliveryMan === connectedUser?.id ||
          isLimitedAccount ||
          isVerifyingAccount,
      },
      {
        label: t("orderAction.list.action.unassignIt"),
        value: "UNASSIGN",
        disabled:
          row.idDeliveryMan !== connectedUser?.id || row.status === "DELIVERED",
      },
      {
        label: t("commons.seeDetails"),
        value: "DETAILS",
      },
      {
        label: t("order.list.action.completeTheDelivery"),
        value: "MARK_AS_DELIVERED",
        disabled: row.status !== "ASSIGNED",
      },
    ].filter((menu) => !menu.disabled);
    return <MoreHorizMenu items={menu} onSelect={handleSelect} />;
  };

  const livreurColumns: Column[] =
    entity?.code === "LIVREUR"
      ? [
          {
            label: t("order.list.label.deliveryPrice"),
            name: "deliveryPrice",
            sortable: true,
            renderer: (row: Order) => {
              return `${Number(row.deliveryPrice).toFixed(2)}€`;
            },
          },
        ]
      : [];
  return { livreurColumns, livreurActions };
};
