import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
        overflow: "auto"
      },
      "& > .MuiBox-root": {
        height: "100%",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3)
        },
        [theme.breakpoints.up("sm")]: {
          width: "50%",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      },
      "& label.Mui-focused": {
        color: theme.palette.secondary.main,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: theme.palette.secondary.main,
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: theme.palette.secondary.main,
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.secondary.main,
        },
      },
    },
    formCard: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: theme.palette.primary.light,
      border: "3px solid",
      borderColor: theme.palette.secondary.main,
      padding: theme.spacing(3),
      overflow: "auto !important",
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    logo: {
      width: 100,
      marginTop: theme.spacing(3),
    },
    form: {
      "& .MuiTextField-root": { marginBottom: theme.spacing(3), width: "100%" },
      marginBottom: theme.spacing(3),
    },
    bgPrimary: {
      background: theme.palette.primary.main,
      height: "100vh",
    },
    title: {
      color: theme.palette.primary.main,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.typography.h6.fontSize} !important`,
        fontWeight: "bold !important",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: `${theme.typography.h4.fontSize} !important`,
        fontWeight: "bold !important",
      },
    },
    pdf: {
      width: 600,
      height: 780
    }
  })
);
