import * as Types from '../../types';

import { gql } from '@apollo/client';
export type StripeTransfertInfoFragment = { __typename?: 'StripeTransfert', id: string, idOrder: string, idUser: string, idStripeAccount: string, idTransfert: string, data: any, createdAt: any, updatedAt: any };

export const StripeTransfertInfoFragmentDoc = gql`
    fragment StripeTransfertInfo on StripeTransfert {
  id
  idOrder
  idUser
  idStripeAccount
  idTransfert
  data
  createdAt
  updatedAt
}
    `;