import { Box, Card, Typography } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { useStyles } from "./styles";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

interface StripeStatCardProps {
  label: string;
  value: string;
  icon: ReactNode;
  color: string;
  percentage: number;
}

export const StripeStatCard: FC<StripeStatCardProps> = ({
  label,
  value,
  color,
  icon,
  percentage,
}) => {
  const classes = useStyles();
  return (
    <Card
      elevation={0}
      className={classes.root}
      sx={{
        border: `1px solid ${color}`,
        background: color,
        color: "#fff",
        position: "relative",
      }}
    >
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        {label}
      </Typography>
      {icon}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h2" fontSize="2rem" fontWeight={300}>
          {value}
        </Typography>
        <Box display="flex">
          <Typography variant="h4" fontSize="1.2rem" fontWeight={500}>
            {Math.abs(percentage)}%
          </Typography>
          {percentage > 0 ? (
            <ArrowUpward className={classes.arrowIcon} />
          ) : (
            <ArrowDownward className={classes.arrowIcon} />
          )}
        </Box>
      </Box>
    </Card>
  );
};
