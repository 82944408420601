import * as Types from '../../types';

import { gql } from '@apollo/client';
export type StripeCheckoutSessionInfoFragment = { __typename?: 'StripeCheckoutSession', id: string, idOrder: string, idSession: string, status: string, url: string, createdAt: any, updatedAt: any };

export const StripeCheckoutSessionInfoFragmentDoc = gql`
    fragment StripeCheckoutSessionInfo on StripeCheckoutSession {
  id
  idOrder
  idSession
  status
  url
  createdAt
  updatedAt
}
    `;