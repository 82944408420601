import { Home } from "@mui/icons-material";
import { Typography, Box, Button } from "@mui/material";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

const NotFound: FC = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        display: "table",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "table-cell",
          verticalAlign: "middle",
          background: "#048c2c",
        }}
      >
        <Box
          sx={{
            width: 300,
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <Box sx={{ height: 300, borderRadius: "50%", background: "#fff" }}>
            <Home
              sx={{
                fontSize: "5em",
                lineHeight: "1em",
                float: "right",
                width: "0.9em",
                height: "0.9em",
                marginTop: "-.7em",
                marginRight: "-.5em",
                padding: "20px",
                textAlign: "center",
                color: "#f5f5f5 !important",
                borderRadius: "50%",
                backgroundColor: "#048c2c",
                boxShadow: "0 0 0 15px #f5f5f5",
              }}
            />
            <Box
              sx={{
                fontSize: "10em",
                fontWeight: 650,
                lineHeight: "1.2em",
                display: "block",
                textAlign: "center",
                color: "#048c2c",
              }}
            >
              404
            </Box>
          </Box>
          <Typography
            sx={{
              fontSize: "20px",
              display: "block",
              marginTop: "20px",
              marginBottom: "5px",
              textAlign: "center",
              color: "#f5f5f5",
            }}
          >
            Nous ne trouvons pas la page que vous recherchez.
          </Typography>
          <Button
            sx={{
              background: "#337ab7",
            }}
            variant="contained"
            startIcon={<Home />}
            onClick={handleClick}
          >
            Revenir à la page d'accueil
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export { NotFound };
