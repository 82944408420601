import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_One_OrderMutationVariables = Types.Exact<{
  input: Types.CreateOneOrderInput;
}>;


export type Create_One_OrderMutation = { __typename?: 'Mutation', createOneOrder: { __typename?: 'Order', id: string } };

export type Delete_One_OrderMutationVariables = Types.Exact<{
  input: Types.DeleteOneOrderInput;
}>;


export type Delete_One_OrderMutation = { __typename?: 'Mutation', deleteOneOrder: { __typename?: 'OrderDeleteResponse', id?: string | null } };

export type Update_One_OrderMutationVariables = Types.Exact<{
  input: Types.UpdateOneOrderInput;
}>;


export type Update_One_OrderMutation = { __typename?: 'Mutation', updateOneOrder: { __typename?: 'Order', id: string } };

export type Verify_Delivery_CodeMutationVariables = Types.Exact<{
  idOrder: Types.Scalars['String'];
  deliveryCode: Types.Scalars['String'];
}>;


export type Verify_Delivery_CodeMutation = { __typename?: 'Mutation', verifyDeliveryCode: { __typename?: 'Order', id: string } };


export const Create_One_OrderDocument = gql`
    mutation CREATE_ONE_ORDER($input: CreateOneOrderInput!) {
  createOneOrder(input: $input) {
    id
  }
}
    `;
export type Create_One_OrderMutationFn = Apollo.MutationFunction<Create_One_OrderMutation, Create_One_OrderMutationVariables>;

/**
 * __useCreate_One_OrderMutation__
 *
 * To run a mutation, you first call `useCreate_One_OrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_One_OrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneOrderMutation, { data, loading, error }] = useCreate_One_OrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_One_OrderMutation(baseOptions?: Apollo.MutationHookOptions<Create_One_OrderMutation, Create_One_OrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_One_OrderMutation, Create_One_OrderMutationVariables>(Create_One_OrderDocument, options);
      }
export type Create_One_OrderMutationHookResult = ReturnType<typeof useCreate_One_OrderMutation>;
export type Create_One_OrderMutationResult = Apollo.MutationResult<Create_One_OrderMutation>;
export type Create_One_OrderMutationOptions = Apollo.BaseMutationOptions<Create_One_OrderMutation, Create_One_OrderMutationVariables>;
export const Delete_One_OrderDocument = gql`
    mutation DELETE_ONE_ORDER($input: DeleteOneOrderInput!) {
  deleteOneOrder(input: $input) {
    id
  }
}
    `;
export type Delete_One_OrderMutationFn = Apollo.MutationFunction<Delete_One_OrderMutation, Delete_One_OrderMutationVariables>;

/**
 * __useDelete_One_OrderMutation__
 *
 * To run a mutation, you first call `useDelete_One_OrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_One_OrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneOrderMutation, { data, loading, error }] = useDelete_One_OrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDelete_One_OrderMutation(baseOptions?: Apollo.MutationHookOptions<Delete_One_OrderMutation, Delete_One_OrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_One_OrderMutation, Delete_One_OrderMutationVariables>(Delete_One_OrderDocument, options);
      }
export type Delete_One_OrderMutationHookResult = ReturnType<typeof useDelete_One_OrderMutation>;
export type Delete_One_OrderMutationResult = Apollo.MutationResult<Delete_One_OrderMutation>;
export type Delete_One_OrderMutationOptions = Apollo.BaseMutationOptions<Delete_One_OrderMutation, Delete_One_OrderMutationVariables>;
export const Update_One_OrderDocument = gql`
    mutation UPDATE_ONE_ORDER($input: UpdateOneOrderInput!) {
  updateOneOrder(input: $input) {
    id
  }
}
    `;
export type Update_One_OrderMutationFn = Apollo.MutationFunction<Update_One_OrderMutation, Update_One_OrderMutationVariables>;

/**
 * __useUpdate_One_OrderMutation__
 *
 * To run a mutation, you first call `useUpdate_One_OrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_One_OrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneOrderMutation, { data, loading, error }] = useUpdate_One_OrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_One_OrderMutation(baseOptions?: Apollo.MutationHookOptions<Update_One_OrderMutation, Update_One_OrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_One_OrderMutation, Update_One_OrderMutationVariables>(Update_One_OrderDocument, options);
      }
export type Update_One_OrderMutationHookResult = ReturnType<typeof useUpdate_One_OrderMutation>;
export type Update_One_OrderMutationResult = Apollo.MutationResult<Update_One_OrderMutation>;
export type Update_One_OrderMutationOptions = Apollo.BaseMutationOptions<Update_One_OrderMutation, Update_One_OrderMutationVariables>;
export const Verify_Delivery_CodeDocument = gql`
    mutation VERIFY_DELIVERY_CODE($idOrder: String!, $deliveryCode: String!) {
  verifyDeliveryCode(idOrder: $idOrder, deliveryCode: $deliveryCode) {
    id
  }
}
    `;
export type Verify_Delivery_CodeMutationFn = Apollo.MutationFunction<Verify_Delivery_CodeMutation, Verify_Delivery_CodeMutationVariables>;

/**
 * __useVerify_Delivery_CodeMutation__
 *
 * To run a mutation, you first call `useVerify_Delivery_CodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerify_Delivery_CodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyDeliveryCodeMutation, { data, loading, error }] = useVerify_Delivery_CodeMutation({
 *   variables: {
 *      idOrder: // value for 'idOrder'
 *      deliveryCode: // value for 'deliveryCode'
 *   },
 * });
 */
export function useVerify_Delivery_CodeMutation(baseOptions?: Apollo.MutationHookOptions<Verify_Delivery_CodeMutation, Verify_Delivery_CodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Verify_Delivery_CodeMutation, Verify_Delivery_CodeMutationVariables>(Verify_Delivery_CodeDocument, options);
      }
export type Verify_Delivery_CodeMutationHookResult = ReturnType<typeof useVerify_Delivery_CodeMutation>;
export type Verify_Delivery_CodeMutationResult = Apollo.MutationResult<Verify_Delivery_CodeMutation>;
export type Verify_Delivery_CodeMutationOptions = Apollo.BaseMutationOptions<Verify_Delivery_CodeMutation, Verify_Delivery_CodeMutationVariables>;