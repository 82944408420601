import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_Payzen_PaymentQueryVariables = Types.Exact<{
  idOrder: Types.Scalars['String'];
}>;


export type Create_Payzen_PaymentQuery = { __typename?: 'Query', createPayzenPayment: any };


export const Create_Payzen_PaymentDocument = gql`
    query CREATE_PAYZEN_PAYMENT($idOrder: String!) {
  createPayzenPayment(idOrder: $idOrder)
}
    `;

/**
 * __useCreate_Payzen_PaymentQuery__
 *
 * To run a query within a React component, call `useCreate_Payzen_PaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreate_Payzen_PaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreate_Payzen_PaymentQuery({
 *   variables: {
 *      idOrder: // value for 'idOrder'
 *   },
 * });
 */
export function useCreate_Payzen_PaymentQuery(baseOptions: Apollo.QueryHookOptions<Create_Payzen_PaymentQuery, Create_Payzen_PaymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Create_Payzen_PaymentQuery, Create_Payzen_PaymentQueryVariables>(Create_Payzen_PaymentDocument, options);
      }
export function useCreate_Payzen_PaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Create_Payzen_PaymentQuery, Create_Payzen_PaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Create_Payzen_PaymentQuery, Create_Payzen_PaymentQueryVariables>(Create_Payzen_PaymentDocument, options);
        }
export type Create_Payzen_PaymentQueryHookResult = ReturnType<typeof useCreate_Payzen_PaymentQuery>;
export type Create_Payzen_PaymentLazyQueryHookResult = ReturnType<typeof useCreate_Payzen_PaymentLazyQuery>;
export type Create_Payzen_PaymentQueryResult = Apollo.QueryResult<Create_Payzen_PaymentQuery, Create_Payzen_PaymentQueryVariables>;