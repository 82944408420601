import * as Types from '../../types';

import { gql } from '@apollo/client';
import { StripeCheckoutSessionInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_One_Stripe_Checkout_SessionMutationVariables = Types.Exact<{
  input: Types.CreateOneStripeCheckoutSessionInput;
}>;


export type Create_One_Stripe_Checkout_SessionMutation = { __typename?: 'Mutation', createOneStripeCheckoutSession: { __typename?: 'StripeCheckoutSession', id: string, idOrder: string, idSession: string, status: string, url: string, createdAt: any, updatedAt: any } };

export type Retrieve_Checkout_SessionMutationVariables = Types.Exact<{
  idSession: Types.Scalars['String'];
}>;


export type Retrieve_Checkout_SessionMutation = { __typename?: 'Mutation', retrieveCheckoutSession: { __typename?: 'StripeCheckoutSession', id: string, status: string, order: { __typename?: 'Order', id: string, ref: string } } };

export type RefundMutationVariables = Types.Exact<{
  idOrder: Types.Scalars['String'];
}>;


export type RefundMutation = { __typename?: 'Mutation', refund: { __typename?: 'Order', id: string, status: string, ref: string } };


export const Create_One_Stripe_Checkout_SessionDocument = gql`
    mutation CREATE_ONE_STRIPE_CHECKOUT_SESSION($input: CreateOneStripeCheckoutSessionInput!) {
  createOneStripeCheckoutSession(input: $input) {
    ...StripeCheckoutSessionInfo
  }
}
    ${StripeCheckoutSessionInfoFragmentDoc}`;
export type Create_One_Stripe_Checkout_SessionMutationFn = Apollo.MutationFunction<Create_One_Stripe_Checkout_SessionMutation, Create_One_Stripe_Checkout_SessionMutationVariables>;

/**
 * __useCreate_One_Stripe_Checkout_SessionMutation__
 *
 * To run a mutation, you first call `useCreate_One_Stripe_Checkout_SessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_One_Stripe_Checkout_SessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneStripeCheckoutSessionMutation, { data, loading, error }] = useCreate_One_Stripe_Checkout_SessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_One_Stripe_Checkout_SessionMutation(baseOptions?: Apollo.MutationHookOptions<Create_One_Stripe_Checkout_SessionMutation, Create_One_Stripe_Checkout_SessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_One_Stripe_Checkout_SessionMutation, Create_One_Stripe_Checkout_SessionMutationVariables>(Create_One_Stripe_Checkout_SessionDocument, options);
      }
export type Create_One_Stripe_Checkout_SessionMutationHookResult = ReturnType<typeof useCreate_One_Stripe_Checkout_SessionMutation>;
export type Create_One_Stripe_Checkout_SessionMutationResult = Apollo.MutationResult<Create_One_Stripe_Checkout_SessionMutation>;
export type Create_One_Stripe_Checkout_SessionMutationOptions = Apollo.BaseMutationOptions<Create_One_Stripe_Checkout_SessionMutation, Create_One_Stripe_Checkout_SessionMutationVariables>;
export const Retrieve_Checkout_SessionDocument = gql`
    mutation RETRIEVE_CHECKOUT_SESSION($idSession: String!) {
  retrieveCheckoutSession(idSession: $idSession) {
    id
    status
    order {
      id
      ref
    }
  }
}
    `;
export type Retrieve_Checkout_SessionMutationFn = Apollo.MutationFunction<Retrieve_Checkout_SessionMutation, Retrieve_Checkout_SessionMutationVariables>;

/**
 * __useRetrieve_Checkout_SessionMutation__
 *
 * To run a mutation, you first call `useRetrieve_Checkout_SessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetrieve_Checkout_SessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retrieveCheckoutSessionMutation, { data, loading, error }] = useRetrieve_Checkout_SessionMutation({
 *   variables: {
 *      idSession: // value for 'idSession'
 *   },
 * });
 */
export function useRetrieve_Checkout_SessionMutation(baseOptions?: Apollo.MutationHookOptions<Retrieve_Checkout_SessionMutation, Retrieve_Checkout_SessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Retrieve_Checkout_SessionMutation, Retrieve_Checkout_SessionMutationVariables>(Retrieve_Checkout_SessionDocument, options);
      }
export type Retrieve_Checkout_SessionMutationHookResult = ReturnType<typeof useRetrieve_Checkout_SessionMutation>;
export type Retrieve_Checkout_SessionMutationResult = Apollo.MutationResult<Retrieve_Checkout_SessionMutation>;
export type Retrieve_Checkout_SessionMutationOptions = Apollo.BaseMutationOptions<Retrieve_Checkout_SessionMutation, Retrieve_Checkout_SessionMutationVariables>;
export const RefundDocument = gql`
    mutation REFUND($idOrder: String!) {
  refund(idOrder: $idOrder) {
    id
    status
    ref
  }
}
    `;
export type RefundMutationFn = Apollo.MutationFunction<RefundMutation, RefundMutationVariables>;

/**
 * __useRefundMutation__
 *
 * To run a mutation, you first call `useRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundMutation, { data, loading, error }] = useRefundMutation({
 *   variables: {
 *      idOrder: // value for 'idOrder'
 *   },
 * });
 */
export function useRefundMutation(baseOptions?: Apollo.MutationHookOptions<RefundMutation, RefundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefundMutation, RefundMutationVariables>(RefundDocument, options);
      }
export type RefundMutationHookResult = ReturnType<typeof useRefundMutation>;
export type RefundMutationResult = Apollo.MutationResult<RefundMutation>;
export type RefundMutationOptions = Apollo.BaseMutationOptions<RefundMutation, RefundMutationVariables>;