import * as Types from '../../types';

import { gql } from '@apollo/client';
import { StripeAccountLinkInfoFragmentDoc } from '../../StripeAccountLink/__generated__/fragment';
export type StripeAccountInfoFragment = { __typename?: 'StripeAccount', id: string, account: string, status: string, validatedAt?: any | null, createdAt: any, deletedAt?: any | null, updatedBy?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null, stripeAccountLinks?: Array<{ __typename?: 'StripeAccountLink', id: string, idStripeAccount: string, url: string, type: string }> | null };

export const StripeAccountInfoFragmentDoc = gql`
    fragment StripeAccountInfo on StripeAccount {
  id
  account
  status
  validatedAt
  createdAt
  deletedAt
  updatedBy {
    id
    firstName
    lastName
  }
  stripeAccountLinks {
    ...StripeAccountLinkInfo
  }
}
    ${StripeAccountLinkInfoFragmentDoc}`;