import * as Types from '../../types';

import { gql } from '@apollo/client';
export type SupportTicketHistoryInfoFragment = { __typename?: 'SupportTicketHistory', id: string, idSupportTicket: string, action: string, createdAt: any, updatedAt?: any | null };

export const SupportTicketHistoryInfoFragmentDoc = gql`
    fragment SupportTicketHistoryInfo on SupportTicketHistory {
  id
  idSupportTicket
  action
  createdAt
  updatedAt
}
    `;