import React, { FC } from "react";
import Echarts from "echarts-for-react";

interface PieChartProps {
  data: { value: number; name: string }[];
}

export const PieChart: FC<PieChartProps> = ({ data }) => {
  const option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "5%",
      left: "center",
    },
    series: [
      {
        name: "Status",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        itemStyle: {
          
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: true,
          formatter: (param: any) => {
            // correct the percentage
            return `${param.name} ${param.value}`
          }
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 30,
            fontWeight: "bold",
          },
        },
        // labelLine: {
        //   show: false,
        // },
        data,
      },
    ],
  };
  return <Echarts option={option} />;
};
