import React, { FC, useState } from "react";
import { FormWrapper } from "@components/Form/Form";
import * as yup from "yup";
import { User, useRolesQuery } from "@graphql/";
import { InputProps } from "@src/types";
import { useTranslation } from "react-i18next";
import { phoneRegExp } from "@src/constants";
import { useUsersMutations } from "@src/hooks";
import { useApplicationContext } from "@src/context";
import { Modal } from "@components/Modal";
import moment from "moment";
import { Box, Button } from "@mui/material";
import { useStyles } from "./styles";
import { Delete } from "@mui/icons-material";
import { ConfirmDeleteMyAccountModal } from "@components/ConfirmDeleteMyAccountModal";

interface UserFormProps {
  open: boolean;
  onClose: () => void;
  user?: User;
  withRole?: boolean;
}

export const UserForm: FC<UserFormProps> = ({
  open,
  onClose,
  user,
  withRole,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [showDeleteMyAccountModal, setShowDeleteMyAccountModal] =
    useState<boolean>(false);

  const { connectedUser, connectedUserType } = useApplicationContext();
  const rolesQuery = useRolesQuery();
  const roles = rolesQuery.data?.roles.nodes || [];
  const rolesOptions = roles
    .filter((role) => role.code !== "SUPERADMIN")
    .map((role) => ({
      label: role.name,
      value: role.id,
    }));

  const { createUser, updateUser, loading } = useUsersMutations();
  const initialCreateInput = {
    email: "",
    firstName: "",
    lastName: "",
    phoneMobile: "",
    profession: "",
    birthDate: null,
    idRole: "",
  };

  const idUserRole = roles.find((role) => role.code === "USER")?.id;

  const schema = yup
    .object({
      email: yup
        .string()
        .email(t("user.form.errors.invalidEmail"))
        .required(t("user.formerrors.invalidEmail")),
      firstName: yup
        .string()
        .required(t("user.form.errors.firstNameIsRequired")),
      lastName: yup
        .string()
        .required(t("user.form.errors.nameIsRequired")),
      birthDate: yup
        .date()
        .typeError(t("user.form.errors.invalidBirthDate"))
        .max(
          moment().subtract(18, "y").toDate(),
          t("user.form.errors.notMajorBirthDate")
        )
        .required(t("user.form.errors.requiredBirthDate")),
      phoneMobile: yup
        .string()
        .matches(phoneRegExp, t("user.form.errors.invalidContact"))
        .required(t("user.form.errors.requiredContact")),
      optionalPhone: yup
        .string()
        .matches(phoneRegExp, t("user.form.errors.invalidOtherContact"))
        .nullable(),
      idRole: withRole
        ? yup.string().required(t("user.form.errors.requiredRole"))
        : yup.string().optional(),
    })
    .required();

  const inputs: InputProps[] = [
    {
      name: "email",
      label: t("commons.email"),
      type: "text",
      placeholder: t("commons.email"),
    },
    {
      name: "firstName",
      label: t("user.form.firstName"),
      type: "text",
      placeholder: t("user.form.firstName"),
    },
    {
      name: "lastName",
      label: t("user.form.name"),
      type: "text",
      placeholder: t("user.form.name"),
    },
    {
      name: "birthDate",
      label: t("user.form.dateOfBirth"),
      type: "date",
      placeholder: t("user.form.dateOfBirth"),
    },
    {
      name: "phoneMobile",
      label: t("user.form.contact"),
      type: "text",
      placeholder: t("user.form.contact"),
    },
    {
      name: "optionalPhone",
      label: t("user.form.otherContact"),
      type: "text",
      placeholder: t("user.form.otherContact"),
    },
    {
      name: "profession",
      label: t("user.form.occupation"),
      type: "text",
      placeholder: t("user.form.occupation"),
      disabled: user?.idAdmin !== undefined,
    },
  ];

  const withRoleInputs: InputProps[] = withRole
    ? [
        {
          name: "idRole",
          label: t("user.form.role"),
          type: "select",
          options: rolesOptions,
          placeholder: t("user.form.role"),
          disabled: user !== undefined,
        },
      ]
    : [];

  const handleSubmit = (state: any, id?: string) => {
    if (id) {
      updateUser({
        variables: {
          input: {
            id,
            update: {
              email: state.email,
              firstName: state.firstName,
              lastName: state.lastName,
              phoneMobile: state.phoneMobile,
              profession: state.profession,
              birthDate: state.birthDate,
              idRole: withRole ? state.idRole : undefined,
            },
          },
        },
      }).then(() => onClose());
    } else {
      createUser({
        variables: {
          input: {
            user: {
              ...state,
              idAdmin:
                connectedUserType === "PHARMACY"
                  ? connectedUser?.id
                  : undefined,
              idEntity: connectedUser?.idEntity,
              idRole:
                connectedUserType === "PHARMACY" ? idUserRole : state.idRole,
            },
          },
        },
      }).then(() => onClose());
    }
  };

  const handleDeleteMyAccount = () => {
    setShowDeleteMyAccountModal(true);
  };
  return (
    <Modal
      title={
        user
          ? t("user.form.title.create")
          : t("user.form.title.update")
      }
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <Box className={classes.root}>
        <FormWrapper
          initialCreateInput={initialCreateInput}
          onSubmit={handleSubmit}
          onCancel={onClose}
          cancelBtnLabel={t("commons.cancel")}
          schema={schema}
          loading={loading}
          submitBtnLabel={
            user ? t("commons.edit") : t("commons.add")
          }
          btnChildren={
            !user?.idAdmin ? (
              <Button
                variant="contained"
                color="warning"
                startIcon={<Delete />}
                sx={{ mr: 2 }}
                onClick={handleDeleteMyAccount}
              >
                {t("user.form.deleteMyAccount")}
              </Button>
            ) : undefined
          }
          inputs={[...inputs, ...withRoleInputs]}
          element={user}
        />
      </Box>
      {user && (
        <ConfirmDeleteMyAccountModal
          open={showDeleteMyAccountModal}
          setOpen={setShowDeleteMyAccountModal}
          user={user}
        />
      )}
    </Modal>
  );
};
