import * as Types from '../../types';

import { gql } from '@apollo/client';
import { MutuelleInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MutuellesQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.MutuelleFilter>;
  sorting?: Types.InputMaybe<Array<Types.MutuelleSort> | Types.MutuelleSort>;
}>;


export type MutuellesQuery = { __typename?: 'Query', mutuelles: { __typename?: 'MutuelleConnection', totalCount: number, nodes: Array<{ __typename?: 'Mutuelle', id: string, patient?: string | null, info?: string | null, status: string, validationError?: string | null, file: { __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null } }> } };


export const MutuellesDocument = gql`
    query MUTUELLES($paging: OffsetPaging, $filter: MutuelleFilter, $sorting: [MutuelleSort!]) {
  mutuelles(paging: $paging, filter: $filter, sorting: $sorting) {
    nodes {
      ...MutuelleInfo
    }
    totalCount
  }
}
    ${MutuelleInfoFragmentDoc}`;

/**
 * __useMutuellesQuery__
 *
 * To run a query within a React component, call `useMutuellesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMutuellesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMutuellesQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useMutuellesQuery(baseOptions?: Apollo.QueryHookOptions<MutuellesQuery, MutuellesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MutuellesQuery, MutuellesQueryVariables>(MutuellesDocument, options);
      }
export function useMutuellesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MutuellesQuery, MutuellesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MutuellesQuery, MutuellesQueryVariables>(MutuellesDocument, options);
        }
export type MutuellesQueryHookResult = ReturnType<typeof useMutuellesQuery>;
export type MutuellesLazyQueryHookResult = ReturnType<typeof useMutuellesLazyQuery>;
export type MutuellesQueryResult = Apollo.QueryResult<MutuellesQuery, MutuellesQueryVariables>;