import * as Types from '../../types';

import { gql } from '@apollo/client';
import { TarificationInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_One_TarificationMutationVariables = Types.Exact<{
  input: Types.CreateOneTarificationInput;
}>;


export type Create_One_TarificationMutation = { __typename?: 'Mutation', createOneTarification: { __typename?: 'Tarification', id: string, dateDebut: any, dateFin: any, tva: number, prixKmJour: number, prixKmSoir: number, tarifMinimum: number, deliveryManPercentage: number } };

export type Update_One_TarificationMutationVariables = Types.Exact<{
  input: Types.UpdateOneTarificationInput;
}>;


export type Update_One_TarificationMutation = { __typename?: 'Mutation', updateOneTarification: { __typename?: 'Tarification', id: string, dateDebut: any, dateFin: any, tva: number, prixKmJour: number, prixKmSoir: number, tarifMinimum: number, deliveryManPercentage: number } };

export type Delete_One_TarificationMutationVariables = Types.Exact<{
  input: Types.DeleteOneTarificationInput;
}>;


export type Delete_One_TarificationMutation = { __typename?: 'Mutation', deleteOneTarification: { __typename?: 'TarificationDeleteResponse', id?: string | null } };


export const Create_One_TarificationDocument = gql`
    mutation CREATE_ONE_TARIFICATION($input: CreateOneTarificationInput!) {
  createOneTarification(input: $input) {
    ...TarificationInfo
  }
}
    ${TarificationInfoFragmentDoc}`;
export type Create_One_TarificationMutationFn = Apollo.MutationFunction<Create_One_TarificationMutation, Create_One_TarificationMutationVariables>;

/**
 * __useCreate_One_TarificationMutation__
 *
 * To run a mutation, you first call `useCreate_One_TarificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_One_TarificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneTarificationMutation, { data, loading, error }] = useCreate_One_TarificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_One_TarificationMutation(baseOptions?: Apollo.MutationHookOptions<Create_One_TarificationMutation, Create_One_TarificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_One_TarificationMutation, Create_One_TarificationMutationVariables>(Create_One_TarificationDocument, options);
      }
export type Create_One_TarificationMutationHookResult = ReturnType<typeof useCreate_One_TarificationMutation>;
export type Create_One_TarificationMutationResult = Apollo.MutationResult<Create_One_TarificationMutation>;
export type Create_One_TarificationMutationOptions = Apollo.BaseMutationOptions<Create_One_TarificationMutation, Create_One_TarificationMutationVariables>;
export const Update_One_TarificationDocument = gql`
    mutation UPDATE_ONE_TARIFICATION($input: UpdateOneTarificationInput!) {
  updateOneTarification(input: $input) {
    ...TarificationInfo
  }
}
    ${TarificationInfoFragmentDoc}`;
export type Update_One_TarificationMutationFn = Apollo.MutationFunction<Update_One_TarificationMutation, Update_One_TarificationMutationVariables>;

/**
 * __useUpdate_One_TarificationMutation__
 *
 * To run a mutation, you first call `useUpdate_One_TarificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_One_TarificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneTarificationMutation, { data, loading, error }] = useUpdate_One_TarificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_One_TarificationMutation(baseOptions?: Apollo.MutationHookOptions<Update_One_TarificationMutation, Update_One_TarificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_One_TarificationMutation, Update_One_TarificationMutationVariables>(Update_One_TarificationDocument, options);
      }
export type Update_One_TarificationMutationHookResult = ReturnType<typeof useUpdate_One_TarificationMutation>;
export type Update_One_TarificationMutationResult = Apollo.MutationResult<Update_One_TarificationMutation>;
export type Update_One_TarificationMutationOptions = Apollo.BaseMutationOptions<Update_One_TarificationMutation, Update_One_TarificationMutationVariables>;
export const Delete_One_TarificationDocument = gql`
    mutation DELETE_ONE_TARIFICATION($input: DeleteOneTarificationInput!) {
  deleteOneTarification(input: $input) {
    id
  }
}
    `;
export type Delete_One_TarificationMutationFn = Apollo.MutationFunction<Delete_One_TarificationMutation, Delete_One_TarificationMutationVariables>;

/**
 * __useDelete_One_TarificationMutation__
 *
 * To run a mutation, you first call `useDelete_One_TarificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_One_TarificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneTarificationMutation, { data, loading, error }] = useDelete_One_TarificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDelete_One_TarificationMutation(baseOptions?: Apollo.MutationHookOptions<Delete_One_TarificationMutation, Delete_One_TarificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_One_TarificationMutation, Delete_One_TarificationMutationVariables>(Delete_One_TarificationDocument, options);
      }
export type Delete_One_TarificationMutationHookResult = ReturnType<typeof useDelete_One_TarificationMutation>;
export type Delete_One_TarificationMutationResult = Apollo.MutationResult<Delete_One_TarificationMutation>;
export type Delete_One_TarificationMutationOptions = Apollo.BaseMutationOptions<Delete_One_TarificationMutation, Delete_One_TarificationMutationVariables>;