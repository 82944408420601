import * as Types from '../../types';

import { gql } from '@apollo/client';
export type SupportTicketMessageInfoFragment = { __typename?: 'SupportTicketMessage', id: string, idSupportTicket: string, idSender: string, message: string, status: string, createdAt: any, sender: { __typename?: 'User', id: string, role: { __typename?: 'Role', id: string, code: string } } };

export const SupportTicketMessageInfoFragmentDoc = gql`
    fragment SupportTicketMessageInfo on SupportTicketMessage {
  id
  idSupportTicket
  idSender
  message
  status
  createdAt
  sender {
    id
    role {
      id
      code
    }
  }
}
    `;