import {
  File as FileType,
  useVehiclesQuery,
  useVerify_Bearer_TokenQuery,
} from "@graphql/";
import {
  Box,
  Button,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Divider,
  Grid,
} from "@mui/material";
import React, { ChangeEvent, FC, useState } from "react";
import { useStyles } from "./styles";
import { useApplicationContext } from "@src/context";
import { FileInput } from "@components/FileInput";
import { useFilesMutations, useUsersMutations } from "@src/hooks";
import { useTranslation } from "react-i18next";

interface VehicleProps {}

interface FileState {
  carteGrise: File | FileType | undefined;
  assurance: File | FileType | undefined;
  visiteTechnique: File | FileType | undefined;
}

export const Vehicle: FC<VehicleProps> = ({}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const vehiclesQuery = useVehiclesQuery();
  const vehicles = vehiclesQuery.data?.vehicles.nodes || [];
  const [vehicle, setVehicle] = useState<"SCOOTER" | "CAR" | "VELO">("VELO");
  const { notify } = useApplicationContext();
  const { updateUser, updatingUser } = useUsersMutations({ skipRefetch: true });
  const { uploadFiles, isUploading } = useFilesMutations({
    skipRefetch: true,
  });
  const loading = updatingUser.loading || isUploading;
  const initialFiles = {
    carteGrise: undefined,
    assurance: undefined,
    visiteTechnique: undefined,
  };
  const [files, setFiles] = useState<FileState>(initialFiles);
  const connectedUserQuery = useVerify_Bearer_TokenQuery({
    onCompleted: (data) => {
      const vehicle = data?.verifyBearerToken.vehicle;
      const files = vehicle?.files;
      if (vehicle) {
        setVehicle(vehicle.code as "SCOOTER" | "CAR" | "VELO");
      }
      if (files?.length) {
        const carteGrise = files.find((file) => file.name === "CARTE_GRISE");
        const assurance = files.find((file) => file.name === "ASSURANCE");
        const visiteTechnique = files.find(
          (file) => file.name === "VISITE_TECHNIQUE"
        );

        if (carteGrise && assurance && visiteTechnique)
          setFiles({
            carteGrise: { ...carteGrise, name: carteGrise.filename },
            assurance: { ...assurance, name: assurance.filename },
            visiteTechnique: {
              ...visiteTechnique,
              name: visiteTechnique.filename,
            },
          });
      }
    },
  });
  const connectedUser = connectedUserQuery.data?.verifyBearerToken;

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files, name } = e.target;
    setFiles((prev) => ({ ...prev, [name]: files && files[0] }));
  };
  const withFilesVehicles = ["SCOOTER", "CAR"];
  const idType = vehicles.find((v) => v.code === vehicle)?.id || "";

  const handleSubmit = async () => {
    if (
      (!files.assurance || !files.carteGrise || !files.visiteTechnique) &&
      withFilesVehicles.includes(vehicle)
    ) {
      notify({ type: "info", message: t("vehicle.addAllFilesRequired") });
      return;
    }
    if (withFilesVehicles.includes(vehicle)) {
      const toUploadFiles = [
        ...isInstanceOfFile("carteGrise", files, "CARTE_GRISE"),
        ...isInstanceOfFile("assurance", files, "ASSURANCE"),
        ...isInstanceOfFile("visiteTechnique", files, "VISITE_TECHNIQUE"),
      ];

      if (toUploadFiles.length) {
        await uploadFiles(toUploadFiles);
      }
    }
    const idVehicle = vehicles.find((v) => v.code === vehicle)?.id;
    if (idVehicle && connectedUser) {
      await updateUser({
        variables: { input: { id: connectedUser.id, update: { idVehicle } } },
      });
    }
  };

  const isInstanceOfFile = (
    key: keyof FileState,
    files: FileState,
    name: string
  ) => {
    return files[key] instanceof File && connectedUser?.id
      ? [
          {
            file: files[key],
            name,
            filename: files[key]?.name || "",
            type: "VEHICLE",
            idType,
            idUser: connectedUser?.id,
            status: "NOT_VERIFIED",
          },
        ]
      : [];
  };

  return (
    <Grid container display="flex" justifyContent="center">
      <Grid item xs={12} sm={6}>
        <Typography variant="h4" textAlign="center" color="primary" mb={2}>
          {t("vehicle.title")}
        </Typography>
        <Typography>{t("vehicle.description")}:</Typography>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel>{t("vehicle.title")}</InputLabel>
            <Select
              value={vehicle}
              label={t("vehicle.title")}
              onChange={(e) =>
                setVehicle(e.target.value as "SCOOTER" | "CAR" | "VELO")
              }
            >
              {vehicles.map((vehicle) => (
                <MenuItem key={`vehicle-${vehicle.id}`} value={vehicle.code}>
                  {vehicle.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {withFilesVehicles.includes(vehicle) && (
            <Box sx={{ mt: 3 }}>
              <Typography>{t("vehicle.addVehicleFiles")}:</Typography>
              <FileInput
                file={files.carteGrise}
                label={t("vehicle.documents.grayCard")}
                name="carteGrise"
                onFileChange={handleFileChange}
              />
              <FileInput
                file={files.assurance}
                label={t("vehicle.documents.insurance")}
                name="assurance"
                onFileChange={handleFileChange}
              />
              <FileInput
                file={files.visiteTechnique}
                label={t("vehicle.documents.technicalControl")}
                name="visiteTechnique"
                onFileChange={handleFileChange}
              />
            </Box>
          )}
        </Box>
        <Divider />

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            className={classes.createBtn}
            disabled={loading}
          >
            {t("commons.save")}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
