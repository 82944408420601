import * as Types from '../../types';

import { gql } from '@apollo/client';
import { StripeTransfertInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_One_Stripe_TransfertMutationVariables = Types.Exact<{
  input: Types.CreateOneStripeTransfertInput;
}>;


export type Create_One_Stripe_TransfertMutation = { __typename?: 'Mutation', createOneStripeTransfert: { __typename?: 'StripeTransfert', id: string, idOrder: string, idUser: string, idStripeAccount: string, idTransfert: string, data: any, createdAt: any, updatedAt: any } };


export const Create_One_Stripe_TransfertDocument = gql`
    mutation CREATE_ONE_STRIPE_TRANSFERT($input: CreateOneStripeTransfertInput!) {
  createOneStripeTransfert(input: $input) {
    ...StripeTransfertInfo
  }
}
    ${StripeTransfertInfoFragmentDoc}`;
export type Create_One_Stripe_TransfertMutationFn = Apollo.MutationFunction<Create_One_Stripe_TransfertMutation, Create_One_Stripe_TransfertMutationVariables>;

/**
 * __useCreate_One_Stripe_TransfertMutation__
 *
 * To run a mutation, you first call `useCreate_One_Stripe_TransfertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_One_Stripe_TransfertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneStripeTransfertMutation, { data, loading, error }] = useCreate_One_Stripe_TransfertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_One_Stripe_TransfertMutation(baseOptions?: Apollo.MutationHookOptions<Create_One_Stripe_TransfertMutation, Create_One_Stripe_TransfertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_One_Stripe_TransfertMutation, Create_One_Stripe_TransfertMutationVariables>(Create_One_Stripe_TransfertDocument, options);
      }
export type Create_One_Stripe_TransfertMutationHookResult = ReturnType<typeof useCreate_One_Stripe_TransfertMutation>;
export type Create_One_Stripe_TransfertMutationResult = Apollo.MutationResult<Create_One_Stripe_TransfertMutation>;
export type Create_One_Stripe_TransfertMutationOptions = Apollo.BaseMutationOptions<Create_One_Stripe_TransfertMutation, Create_One_Stripe_TransfertMutationVariables>;