import {
  useCreate_One_Support_TicketMutation,
  useUpdate_One_Support_TicketMutation,
} from "@graphql/";
import { useApplicationContext } from "@src/context";
export const useSupportTicketsMutations = (skipRefetch?: boolean) => {
  const { notify } = useApplicationContext();

  const [createOneSupportTicket, creatingOneSupportTicket] =
    useCreate_One_Support_TicketMutation({
      refetchQueries: skipRefetch
        ? undefined
        : ["SUPPORT_TICKETS", "SUPPORT_TICKETS_COUNTS"],
      onCompleted: () => {
        notify({
          message: "La demande de support a été ajouté avec succès",
          type: "success",
        });
      },
      onError: () => {
        notify({
          message:
            "Une erreur s'est produite lors de l'ajout de la demande de support",
          type: "error",
        });
      },
    });
  const [updateOneSupportTicket, updatingOneSupportTicket] =
    useUpdate_One_Support_TicketMutation({
      refetchQueries: skipRefetch
        ? undefined
        : ["SUPPORT_TICKETS", "SUPPORT_TICKETS_COUNTS"],
      onCompleted: () => {
        notify({
          message: "La demande de support a été modifié avec succès",
          type: "success",
        });
      },
      onError: () => {
        notify({
          message:
            "Une erreur s'est produite lors de la mise à jour de la demande de support",
          type: "error",
        });
      },
    });

  const loading =
    creatingOneSupportTicket.loading || updatingOneSupportTicket.loading;

  return {
    createOneSupportTicket,
    updateOneSupportTicket,
    loading,
  };
};
