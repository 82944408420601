import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiCardHeader-root": {
        background: "#00000008",
        "& .MuiCardHeader-title": {
          fontSize: 18,
        },
      },
    },
    filterRoot: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-end",
      },
    },
  })
);
