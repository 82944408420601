import { ThemeOptions } from "@mui/material";

const availableThemeNames = [
  "INDIVIDUAL",
  "PROFESSIONNAL",
  "PHARMACY",
  "LIVREUR",
  "SERVICE_CUSTOMER",
  "PREPARER",
  "SUPERADMIN",
] as const;

export type ThemeName = (typeof availableThemeNames)[number];
type Theme = {
  [key in ThemeName]: ThemeOptions;
};

const common = {
  black: "#1D1D1D",
  white: "#ffffff",
};

const defaultsPalette = {
  secondary: {
    main: "#636363",
  },
  text: {
    primary: "#27272F",
  },
  common,
};

const availableThemes: Theme = {
  INDIVIDUAL: {
    palette: {
      primary: {
        main: "#1246B8",
      },
      ...defaultsPalette,
    },
  },
  PROFESSIONNAL: {
    palette: {
      primary: {
        main: "#1246B8",
      },
      ...defaultsPalette,
    },
  },
  LIVREUR: {
    palette: {
      primary: {
        main: "#ffb713",
      },
      ...defaultsPalette,
    },
  },
  SERVICE_CUSTOMER: {
    palette: {
      primary: {
        main: "#92004d",
      },
      ...defaultsPalette,
    },
  },
  PHARMACY: {
    palette: {
      primary: {
        main: "#31956B",
      },
      ...defaultsPalette,
    },
  },
  PREPARER: {
    palette: {
      primary: {
        main: "#31956B",
      },
      ...defaultsPalette,
    },
  },
  SUPERADMIN: {
    palette: {
      primary: {
        main: "#31956B",
      },
      ...defaultsPalette,
    },
  },
};

export { availableThemes };
