import {
  useCreate_One_Support_Ticket_MessageMutation,
  useSupport_Ticket_MessagesQuery,
  useUpdate_Many_Support_Ticket_MessagesMutation,
} from "@graphql/";
import { Close, Send } from "@mui/icons-material";
import {
  Box,
  Drawer,
  IconButton,
  TextareaAutosize,
  Toolbar,
  Typography,
} from "@mui/material";
import { useApplicationContext } from "@src/context";
import moment from "moment";
import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useStyles } from "./styles";

export const MessagerieDrawer: FC = () => {
  const messageBoxRef = useRef<any>(null);
  const classes = useStyles();
  const { messagerie, connectedUser } = useApplicationContext();
  const [message, setMessage] = useState<string>("");
  const toggleDrawer = () => {
    messagerie.setSupportTicket(undefined);
  };
  const [sendMessage, sendingMessage] =
    useCreate_One_Support_Ticket_MessageMutation();
  const [updateMessages] = useUpdate_Many_Support_Ticket_MessagesMutation();
  const messagesQuery = useSupport_Ticket_MessagesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        idSupportTicket: { eq: messagerie.supportTicket?.id },
      },
    },
    skip: !messagerie.supportTicket,
    onCompleted: (data) => {
      const unreadMessages = data.supportTicketMessages.nodes.filter(
        (message) =>
          message.status === "UNREAD" && message.idSender !== connectedUser?.id
      );
      if (unreadMessages.length) {
        updateMessages({
          refetchQueries: ["SUPPORT_TICKETS"],
          variables: {
            input: {
              filter: {
                id: {
                  in: unreadMessages.map((message) => message.id),
                },
              },
              update: {
                status: "READ",
              },
            },
          },
        });
      }
    },
  });
  const messages = messagesQuery.data?.supportTicketMessages.nodes || [];

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = () => {
    sendMessage({
      variables: {
        input: {
          supportTicketMessage: {
            idSender: connectedUser?.id,
            idSupportTicket: messagerie.supportTicket!.id,
            message,
          },
        },
      },
      onCompleted: () => {
        setMessage("");
        messagesQuery.refetch();
      },
    });
  };

  useEffect(() => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  });

  return (
    <Drawer
      anchor="right"
      open={messagerie.open}
      onClose={toggleDrawer}
      sx={{
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 2,
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1" fontWeight="bold">
          {messagerie.supportTicket?.ref}
        </Typography>
        <IconButton onClick={toggleDrawer}>
          <Close />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          overflowX: "hidden",
          px: 2,
          flexBasis: "100%",
        }}
      >
        {messages.map((message) => (
          <Box
            className={
              message.idSender === connectedUser?.id ||
              (message.sender.role.code === "SUPERADMIN" &&
                connectedUser?.role.code === "SERVICE_CUSTOMER")
                ? classes.bubbleRight
                : classes.bubbleLeft
            }
            sx={{
              alignSelf:
                message.idSender === connectedUser?.id ||
                (message.sender.role.code === "SUPERADMIN" &&
                  connectedUser?.role.code === "SERVICE_CUSTOMER")
                  ? "end"
                  : "start",
            }}
          >
            <Typography variant="subtitle1">{message.message}</Typography>
            <Typography variant="caption">
              {moment(message.createdAt).format("DD/MM/YYYY à hh:mm")}
            </Typography>
          </Box>
        ))}
        <div ref={messageBoxRef}></div>
      </Box>
      <Box sx={{ display: "flex", p: 2, borderTop: "1px solid" }}>
        <TextareaAutosize
          onChange={handleChange}
          placeholder="Votre message..."
          minRows={4}
          style={{ width: "100%" }}
          value={message}
        />
        <IconButton
          disabled={!message || sendingMessage.loading}
          onClick={handleSendMessage}
        >
          <Send />
        </IconButton>
      </Box>
    </Drawer>
  );
};
