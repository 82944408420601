import * as Types from '../../types';

import { gql } from '@apollo/client';
import { OpeningTimeInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Opening_TimesQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.OpeningTimeFilter>;
  sorting?: Types.InputMaybe<Array<Types.OpeningTimeSort> | Types.OpeningTimeSort>;
}>;


export type Opening_TimesQuery = { __typename?: 'Query', openingTimes: { __typename?: 'OpeningTimeConnection', totalCount: number, nodes: Array<{ __typename?: 'OpeningTime', id: string, days: any }> } };


export const Opening_TimesDocument = gql`
    query OPENING_TIMES($paging: OffsetPaging, $filter: OpeningTimeFilter, $sorting: [OpeningTimeSort!]) {
  openingTimes(paging: $paging, filter: $filter, sorting: $sorting) {
    nodes {
      ...OpeningTimeInfo
    }
    totalCount
  }
}
    ${OpeningTimeInfoFragmentDoc}`;

/**
 * __useOpening_TimesQuery__
 *
 * To run a query within a React component, call `useOpening_TimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpening_TimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpening_TimesQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useOpening_TimesQuery(baseOptions?: Apollo.QueryHookOptions<Opening_TimesQuery, Opening_TimesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Opening_TimesQuery, Opening_TimesQueryVariables>(Opening_TimesDocument, options);
      }
export function useOpening_TimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Opening_TimesQuery, Opening_TimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Opening_TimesQuery, Opening_TimesQueryVariables>(Opening_TimesDocument, options);
        }
export type Opening_TimesQueryHookResult = ReturnType<typeof useOpening_TimesQuery>;
export type Opening_TimesLazyQueryHookResult = ReturnType<typeof useOpening_TimesLazyQuery>;
export type Opening_TimesQueryResult = Apollo.QueryResult<Opening_TimesQuery, Opening_TimesQueryVariables>;