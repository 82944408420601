import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& label.Mui-focused": {
        color: theme.palette.primary.main,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: theme.palette.primary.main,
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: theme.palette.primary.main,
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.primary.main,
        },
      },
    },
    error: {
      color: theme.palette.error.main
    }
  })
);
