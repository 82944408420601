import * as Types from '../../types';

import { gql } from '@apollo/client';
import { StripeAccountInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_One_Stripe_AccountMutationVariables = Types.Exact<{
  input: Types.CreateOneStripeAccountInput;
}>;


export type Create_One_Stripe_AccountMutation = { __typename?: 'Mutation', createOneStripeAccount: { __typename?: 'StripeAccount', id: string, account: string, status: string, validatedAt?: any | null, createdAt: any, deletedAt?: any | null, updatedBy?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null, stripeAccountLinks?: Array<{ __typename?: 'StripeAccountLink', id: string, idStripeAccount: string, url: string, type: string }> | null } };

export type Delete_One_Stripe_AccountMutationVariables = Types.Exact<{
  input: Types.DeleteOneStripeAccountInput;
}>;


export type Delete_One_Stripe_AccountMutation = { __typename?: 'Mutation', deleteOneStripeAccount: { __typename?: 'StripeAccountDeleteResponse', id?: string | null } };


export const Create_One_Stripe_AccountDocument = gql`
    mutation CREATE_ONE_STRIPE_ACCOUNT($input: CreateOneStripeAccountInput!) {
  createOneStripeAccount(input: $input) {
    ...StripeAccountInfo
  }
}
    ${StripeAccountInfoFragmentDoc}`;
export type Create_One_Stripe_AccountMutationFn = Apollo.MutationFunction<Create_One_Stripe_AccountMutation, Create_One_Stripe_AccountMutationVariables>;

/**
 * __useCreate_One_Stripe_AccountMutation__
 *
 * To run a mutation, you first call `useCreate_One_Stripe_AccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_One_Stripe_AccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneStripeAccountMutation, { data, loading, error }] = useCreate_One_Stripe_AccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_One_Stripe_AccountMutation(baseOptions?: Apollo.MutationHookOptions<Create_One_Stripe_AccountMutation, Create_One_Stripe_AccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_One_Stripe_AccountMutation, Create_One_Stripe_AccountMutationVariables>(Create_One_Stripe_AccountDocument, options);
      }
export type Create_One_Stripe_AccountMutationHookResult = ReturnType<typeof useCreate_One_Stripe_AccountMutation>;
export type Create_One_Stripe_AccountMutationResult = Apollo.MutationResult<Create_One_Stripe_AccountMutation>;
export type Create_One_Stripe_AccountMutationOptions = Apollo.BaseMutationOptions<Create_One_Stripe_AccountMutation, Create_One_Stripe_AccountMutationVariables>;
export const Delete_One_Stripe_AccountDocument = gql`
    mutation DELETE_ONE_STRIPE_ACCOUNT($input: DeleteOneStripeAccountInput!) {
  deleteOneStripeAccount(input: $input) {
    id
  }
}
    `;
export type Delete_One_Stripe_AccountMutationFn = Apollo.MutationFunction<Delete_One_Stripe_AccountMutation, Delete_One_Stripe_AccountMutationVariables>;

/**
 * __useDelete_One_Stripe_AccountMutation__
 *
 * To run a mutation, you first call `useDelete_One_Stripe_AccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_One_Stripe_AccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneStripeAccountMutation, { data, loading, error }] = useDelete_One_Stripe_AccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDelete_One_Stripe_AccountMutation(baseOptions?: Apollo.MutationHookOptions<Delete_One_Stripe_AccountMutation, Delete_One_Stripe_AccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_One_Stripe_AccountMutation, Delete_One_Stripe_AccountMutationVariables>(Delete_One_Stripe_AccountDocument, options);
      }
export type Delete_One_Stripe_AccountMutationHookResult = ReturnType<typeof useDelete_One_Stripe_AccountMutation>;
export type Delete_One_Stripe_AccountMutationResult = Apollo.MutationResult<Delete_One_Stripe_AccountMutation>;
export type Delete_One_Stripe_AccountMutationOptions = Apollo.BaseMutationOptions<Delete_One_Stripe_AccountMutation, Delete_One_Stripe_AccountMutationVariables>;