import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Verify_TokenQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
  action: Types.Scalars['String'];
}>;


export type Verify_TokenQuery = { __typename?: 'Query', verifyToken: { __typename?: 'User', id: string } };


export const Verify_TokenDocument = gql`
    query VERIFY_TOKEN($token: String!, $action: String!) {
  verifyToken(token: $token, action: $action) {
    id
  }
}
    `;

/**
 * __useVerify_TokenQuery__
 *
 * To run a query within a React component, call `useVerify_TokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerify_TokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerify_TokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useVerify_TokenQuery(baseOptions: Apollo.QueryHookOptions<Verify_TokenQuery, Verify_TokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Verify_TokenQuery, Verify_TokenQueryVariables>(Verify_TokenDocument, options);
      }
export function useVerify_TokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Verify_TokenQuery, Verify_TokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Verify_TokenQuery, Verify_TokenQueryVariables>(Verify_TokenDocument, options);
        }
export type Verify_TokenQueryHookResult = ReturnType<typeof useVerify_TokenQuery>;
export type Verify_TokenLazyQueryHookResult = ReturnType<typeof useVerify_TokenLazyQuery>;
export type Verify_TokenQueryResult = Apollo.QueryResult<Verify_TokenQuery, Verify_TokenQueryVariables>;