import { useCreate_One_Opening_TimeMutation } from "@graphql/";
import { useApplicationContext } from "@src/context";
import { useTranslation } from "react-i18next";

export const useOpeningTimeMutations = () => {
  const { t } = useTranslation();

  const { notify } = useApplicationContext();
  const [createOneOpeningTime] = useCreate_One_Opening_TimeMutation({
    refetchQueries: ["OPENING_TIMES"],
    onCompleted: () => {
      notify({
        message: t("openingTimes.success"),
        type: "success",
      });
    },
    onError: () => {
      notify({
        message:
        t("openingTimes.error"),
        type: "error",
      });
    },
  });

  return {
    createOneOpeningTime,
  };
};
