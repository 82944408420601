import { useTranslation } from "react-i18next";
import { FormWrapper } from "@components/Form/Form";
import { SupportTicket } from "@graphql/";
import { InputProps } from "@src/types";
import * as yup from "yup";
import React, { FC } from "react";
import { Modal } from "@components/Modal";

interface SupportTicketOthersFormProps {
  supportTicket?: SupportTicket;
  onSubmit: (state: any, id?: string) => void;
  onCancel: () => void;
  loading: boolean;
}

export const SupportTicketOthersForm: FC<SupportTicketOthersFormProps> = ({
  supportTicket,
  onSubmit,
  onCancel,
  loading
}) => {
  const { t } = useTranslation();

  const initialCreateInput = {
    subject: "",
    description: "",
  };

  const schema = yup
    .object({
      subject: yup.string().required(t("supportTicket.form.errors.subjectRequired"))
    })
    .required();

  const inputs: InputProps[] = [
    {
      name: "subject",
      label: t("supportTicket.form.subject"),
      type: "text",
      placeholder: t("supportTicket.form.subject"),
    },
    {
      name: "description",
      label: t("supportTicket.form.description"),
      type: "textarea",
      placeholder: t("supportTicket.form.description"),
    },
  ];

  const handleSubmit = (state: any, id?: string) => {
    onSubmit(state, id);
  };
  return (
    <FormWrapper
      initialCreateInput={initialCreateInput}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      schema={schema}
      submitBtnLabel={
        supportTicket ? t("commons.edit") : t("commons.add")
      }
      inputs={inputs}
      element={supportTicket}
      loading={loading}
    />
  );
};
