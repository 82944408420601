import { useTranslation } from "react-i18next";
import { DeleteIconBtn } from "@components/DeleteIconBtn";
import { FormWrapper } from "@components/Form/Form";
import { Table, Column } from "@components/Table";
import { Order, OrderItem, useOrder_ItemsQuery } from "@graphql/";
import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { InputProps } from "@src/types";
import { getFileType } from "@utils/getFileType";
import React, { FC, useState } from "react";
import * as yup from "yup";
import { useOrderItemMutations } from "./useOrderItemMutations";
import { decryptFilePublicUrl } from "@utils/decryptFilePublicUrl";
import { usePagination } from "@src/hooks";
import { useOrderMutations } from "@components/CreateOrderStepper/useOrderMutations";
import lodash from "lodash";
import Facturation from "@components/Facturation/Facturation";
import { Modal } from "@components/Modal";
import { OrderItemMobileRow } from "@components/MobileTableRows/OrderItemMobileRow";
import { MoreHorizMenu } from "@components/MoreHorizMenu";
import { ConfirmDeleteModal } from "@components/ConfirmDeleteModal";
import { ValidationPopupBtn } from "@components/ValidationPopupBtn";
import { PdfViewer } from "@components/PdfViewer/PdfViewer";

interface OrderItemsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  order?: Order;
}

export const OrderItemsModal: FC<OrderItemsModalProps> = ({
  open,
  setOpen,
  order,
}) => {
  const { t } = useTranslation();
  const [totalPrice, setTotalPrice] = useState<number>(order?.totalPrice || 0);

  const initialCreateInput = {
    libelle: "",
    description: "",
    quantity: 0,
    unitPrice: 0,
    totalPrice: 0,
    refund: 0,
  };
  const schema = yup
    .object({
      libelle: yup
        .string()
        .required(t("orderItem.form.errors.labelRequired")),
      description: yup
        .string()
        .required(t("orderItem.form.errors.descriptionRequired")),
      quantity: yup
        .number()
        .typeError("orderItem.form.errors.notANumber")
        .positive(t("orderItem.form.errors.negativeQuantity"))
        .required(t("orderItem.form.errors.requiredQuantity")),
      unitPrice: yup
        .number()
        .typeError("orderItem.form.errors.notANumber")
        .required(t("orderItem.form.errors.unitPriceRequired"))
        .positive(t("orderItem.form.errors.negativeUnitPrice")),
      refund: yup
        .number()
        .typeError("orderItem.form.errors.notANumber")
        .positive(t("orderItem.form.errors.negativeRefund"))
        .max(
          totalPrice,
          t("orderItem.form.errors.invalidRefund")
        ),
    })
    .required();
  const [openOrderItemForm, setOpenOrderItemForm] = useState<boolean>(false);
  const [orderItem, setOrderItem] = useState<OrderItem>();
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
    useState<boolean>(false);
  const {
    createOneOrderItem,
    updateOneOrderItem,
    deleteOneOrderItem,
    deletingOneOrderItem,
  } = useOrderItemMutations();
  const { updateOneOrder } = useOrderMutations();
  const pagination = usePagination();
  const orderItemsQuery = useOrder_ItemsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        idOrder: { eq: order?.id },
      },
      paging: pagination.offsetPaging,
    },
    skip: !order?.id,
    onCompleted: (data) => {
      pagination.setTotalCount(data.orderItems.totalCount);
    },
  });
  const orderItems = orderItemsQuery.data?.orderItems.nodes || [];
  const theme = useTheme();
  const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const disableChanges = order?.status !== "NOT_VERIFIED";

  const actionColumn = (row: OrderItem) => {
    const handleSelect = (selected: string) => {
      setOrderItem(row);
      switch (selected) {
        case "UPDATE":
          handleToggleForm();
          break;
        case "DELETE":
          if (onlyMobile) {
            setOpenConfirmDeleteDialog(true);
          } else {
            deleteOneOrderItem({ variables: { input: { id: row.id } } });
          }
          break;
      }
    };
    const handleOrderItem = () => {
      setOrderItem(row);
      handleToggleForm();
    };
    const handleDelete = () => {
      if (row?.id) {
        deleteOneOrderItem({ variables: { input: { id: row.id } } });
      }
    };

    const menus = [
      {
        label: t("commons.edit"),
        value: "UPDATE",
      },
      {
        label: t("commons.delete"),
        value: "DELETE",
      },
    ];

    if (onlyMobile) {
      return (
        <>
          <MoreHorizMenu items={menus} onSelect={handleSelect} />
          <ConfirmDeleteModal
            open={openConfirmDeleteDialog}
            setOpen={setOpenConfirmDeleteDialog}
            loading={deletingOneOrderItem.loading}
            onDelete={handleDelete}
            deleted={deletingOneOrderItem.data?.deleteOneOrderItem?.id}
          />
        </>
      );
    }
    return (
      <>
        <IconButton onClick={handleOrderItem} disabled={disableChanges}>
          <Edit />
        </IconButton>
        &nbsp;
        <DeleteIconBtn
          loading={deletingOneOrderItem.loading}
          onDelete={handleDelete}
          deleted={deletingOneOrderItem.data?.deleteOneOrderItem?.id}
          disabled={disableChanges}
        />
      </>
    );
  };
  const columns: Column[] = [
    {
      label: t("orderItem.list.label.label"),
      name: "libelle",
    },
    {
      label: t("orderItem.list.label.description"),
      name: "description",
    },
    {
      label: t("orderItem.list.label.quantity"),
      name: "quantity",
    },
    {
      label: t("orderItem.list.label.unitPrice"),
      name: "unitPrice",
      renderer: (row: OrderItem) => {
        return `${Number(row.unitPrice).toFixed(2)}€`;
      },
    },
    {
      label: t("orderItem.list.label.totalPrice"),
      name: "totalPrice",
      renderer: (row: OrderItem) => {
        return `${Number(row.totalPrice).toFixed(2)}€`;
      },
    },
    {
      label: t("orderItem.list.label.refund"),
      name: "refund",
      renderer: (row: OrderItem) => {
        return `${Number(row.refund).toFixed(2)}€`;
      },
    },
    {
      label: t("commons.actions"),
      name: "",
      renderer: actionColumn,
    },
  ];

  const handleToggleForm = () => {
    if (openOrderItemForm) {
      setOrderItem(undefined);
    }
    setOpenOrderItemForm(!openOrderItemForm);
  };

  const handleSubmitOrderItem = (state: any, id?: string) => {
    if (orderItem) {
      updateOneOrderItem({
        variables: {
          input: {
            id: orderItem.id,
            update: {
              libelle: state.libelle,
              description: state.description,
              quantity: state.quantity,
              unitPrice: state.unitPrice,
              refund: Number(state.refund),
            },
          },
        },
      });
    } else {
      createOneOrderItem({
        variables: {
          input: {
            orderItem: {
              libelle: state.libelle,
              description: state.description,
              quantity: state.quantity,
              unitPrice: state.unitPrice,
              refund: Number(state.refund),
              idOrder: order?.id as string,
            },
          },
        },
      });
    }
    setOpenOrderItemForm(false);
  };

  const inputs: InputProps[] = [
    {
      name: "libelle",
      label: t("orderItem.list.label.label"),
      type: "text",
      placeholder: t("orderItem.list.label.label"),
    },
    {
      name: "description",
      label: t("orderItem.list.label.description"),
      type: "text",
      placeholder: t("orderItem.list.label.description"),
    },
    {
      name: "quantity",
      label: t("orderItem.list.label.quantity"),
      type: "number",
      min: 0,
    },
    {
      name: "unitPrice",
      label: t("orderItem.list.label.unitPrice"),
      type: "number",
      min: 0,
      textafter: "€",
    },
    {
      name: "totalPrice",
      label: t("orderItem.list.label.totalPrice"),
      type: "number",
      textafter: "€",
      disabled: true,
      value: Number(totalPrice).toFixed(2),
    },
    {
      name: "refund",
      label: t("orderItem.list.label.refund"),
      type: "number",
      textafter: "€",
      max: totalPrice,
    },
  ];

  const handleChange = (values: any) => {
    setTotalPrice(Number((values.unitPrice * values.quantity).toFixed(2)));
  };

  const handleUnavailable = () => {
    if (order) {
      updateOneOrder({
        variables: {
          input: { id: order.id, update: { status: "UNAVAILABLE" } },
        },
      }).then(() => setOpen(false));
    }
  };

  const file = order?.ordonnance?.file;

  const customerPrice = lodash.sum(
    orderItems
      .filter((orderItem) => orderItem.totalPrice - orderItem.refund > 0)
      .map((orderItem) => orderItem.totalPrice - orderItem.refund)
  );

  const handleClose = () => {
    setOpen(false);
  };

  const updateOrderStatusToUnpaid = () => {
    if (order) {
      updateOneOrder({
        variables: { input: { id: order?.id, update: { status: "UNPAID" } } },
      });
      handleClose();
    }
  };
  return (
    <>
      <Modal
        open={open}
        maxWidth="lg"
        title={`${t("orderItem.list.title")} ${order?.ref} ${Number(
          customerPrice
        ).toFixed(2)}€`}
        onClose={handleClose}
        actions={
          <ValidationPopupBtn
            disabled={totalPrice <= 0 || disableChanges}
            label={t("orderItem.list.finish")}
            cancelLabel={t("commons.no")}
            submitLabel={t("commons.yes")}
            message={t("orderItem.list.toUnpaid")}
            onSubmit={updateOrderStatusToUnpaid}
          />
        }
      >
        <Table
          columns={columns}
          data={orderItems}
          title={t("orderItem.list.title")}
          pagination={pagination}
          toolbar={
            <Grid container spacing={2} justifyContent="end">
              <Grid item xs={12} lg={5}>
                <Button
                  variant="contained"
                  onClick={handleToggleForm}
                  fullWidth
                  disabled={disableChanges}
                >
                  {t("commons.add")}
                </Button>
              </Grid>
              <Grid item xs={12} lg={5}>
                <Button
                  variant="outlined"
                  onClick={handleUnavailable}
                  fullWidth
                  disabled={
                    !orderItems.length ||
                    order?.status === "UNAVAILABLE" ||
                    disableChanges
                  }
                >
                  {t("orderItem.list.submit")}
                </Button>
              </Grid>
            </Grid>
          }
          tableHeader={
            <>
              <Facturation orderItems={orderItems} />
              <Divider sx={{ marginBottom: 3 }} />
            </>
          }
          model="orderItem"
          mobileTableRow={<OrderItemMobileRow actions={actionColumn} />}
        />
      </Modal>
      <Modal
        open={openOrderItemForm}
        title={
          orderItem
            ? t("orderItem.form.title.update")
            : t("orderItem.form.title.create")
        }
        onClose={handleToggleForm}
        text={`${
          orderItem
            ? t("commons.edit")
            : t("commons.add")
        } un article pour la commande N°
            ${order?.ref}`}
      >
        <Box
          sx={{
            display: "flex",
            mt: 4,
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          {file && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mr: 4,
                mb: 4,
              }}
            >
              {getFileType(file?.mimeType) === "pdf" && (
                <PdfViewer url={decryptFilePublicUrl(file.url)} width={300} />
              )}
              {getFileType(file.mimeType) === "image" && (
                <img
                  src={decryptFilePublicUrl(file.url)}
                  alt={`file-${file.id}`}
                  width="300px"
                />
              )}
            </Box>
          )}
          <FormWrapper
            element={orderItem}
            initialCreateInput={initialCreateInput}
            onSubmit={handleSubmitOrderItem}
            onCancel={handleToggleForm}
            schema={schema}
            loading={false}
            submitBtnLabel={
              orderItem
                ? t("orderItem.form.title.update")
                : t("orderItem.form.title.create")
            }
            inputs={inputs}
            onChange={handleChange}
            values={{ totalPrice }}
          />
        </Box>
      </Modal>
    </>
  );
};
