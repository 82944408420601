import { Column, Table } from "@components/Table/Table";
import { InfoLegale, Order, useFind_Opened_EntityLazyQuery } from "@graphql/";
import { Box, Button, Divider, Grid, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, {
  FC,
  Dispatch,
  useEffect,
  SetStateAction,
  useState,
} from "react";
import { CreateOrderState } from "@components/CreateOrderStepper";
import { useStyles } from "./styles";
import { VendorMobileRow } from "@components/MobileTableRows/VendorMobileRow/VendorMobileRow";
import { useTranslation } from "react-i18next";

interface VendorSelectionProps {
  onNextStep: () => void;
  disableNextStepBtn?: boolean;
  onPreviousStep: () => void;
  setState: Dispatch<SetStateAction<CreateOrderState | undefined>>;
  datetime?: string | null;
  selectedRow?: InfoLegale;
  state?: CreateOrderState;
  action?: "RELAUNCH";
}

export const VendorSelection: FC<VendorSelectionProps> = ({
  onNextStep,
  setState,
  disableNextStepBtn,
  onPreviousStep,
  datetime,
  selectedRow,
  state,
  action,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const [findOpenedEntities, openedEntitiesQuery] =
    useFind_Opened_EntityLazyQuery();
  const openedEntities = openedEntitiesQuery.data?.findOpenedEntity || [];
  const [term, setTerm] = useState<string>("");

  const columns: Column[] = [
    {
      label: t("order.list.pharmacySelection.label.pharmacy"),
      name: "name",
      renderer: (row) => {
        return row.name.split("(")[0];
      },
    },
    {
      label: t("order.list.pharmacySelection.label.address"),
      name: "name",
      renderer: (row) => {
        return row.addresses[0] && row.addresses[0].name;
      },
    },
    {
      label: t("order.list.pharmacySelection.label.zipCode"),
      name: "zipcode",
      renderer: (row) => {
        return row.addresses[0] && row.addresses[0].zipcode;
      },
    },
    {
      label: t("order.list.pharmacySelection.label.city"),
      name: "city",
      renderer: (row) => {
        return row.addresses[0] && row.addresses[0].city;
      },
    },
    {
      label: t("order.list.pharmacySelection.label.distance"),
      name: "",
      renderer: (row) => {
        return row?.name && row.name.split("(")[1]
          ? row.name.split("(")[1].replace(")", "")
          : "";
      },
    },
  ];

  const handleDateTimeChange = (value: number | null) => {
    setState((prev: any) => ({ ...prev, datetime: moment(value).format() }));
  };

  const handleRowClick = (row: InfoLegale) => {
    setState((prev: any) => ({ ...prev, vendor: row }));
  };

  useEffect(() => {
    if (datetime) {
      findOpenedEntities({
        variables: {
          day: moment(datetime).day() - 2,
          time: moment(datetime).format(),
          idOriginAddress: state?.address?.id as string,
          term,
        },
      });
    }
  }, [datetime, term]);

  const handleSearch = (term: string) => {
    setTerm(term);
  };

  return (
    <Box>
      <Table
        columns={columns}
        data={openedEntities}
        onRowClick={handleRowClick}
        title={t("order.list.pharmacySelection.title")}
        toolbar={
          <Grid container spacing={2} justifyContent="end">
            <Grid item xs={12} lg={8}>
              <DateTimePicker
                disablePast={true}
                ampm={false}
                label={t("order.list.pharmacySelection.deliveryDate")}
                value={datetime}
                onChange={handleDateTimeChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
          </Grid>
        }
        selectedRow={selectedRow}
        mobileTableRow={
          <VendorMobileRow onRowClick={handleRowClick} selected={selectedRow} />
        }
        model="infoLegale"
        infiniteScrollHeight="calc(100vh - 550px)"
        onSearch={handleSearch}
        term={term}
      />
      <Divider sx={{ my: 4 }} />
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3 }}>
        <Button
          variant="outlined"
          onClick={onPreviousStep}
          className={classes.createBtn}
          sx={{ marginRight: 2 }}
        >
          {action === "RELAUNCH" ? t("commons.cancel") : t("commons.previous")}
        </Button>
        <Button
          variant="contained"
          onClick={onNextStep}
          className={classes.createBtn}
          disabled={disableNextStepBtn || !openedEntities.length}
        >
          {action === "RELAUNCH" ? t("commons.save") : t("commons.next")}
        </Button>
      </Box>
    </Box>
  );
};
