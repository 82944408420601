import { StatsCard } from "@components/StatsCardNew";
import { ToggleButtons } from "@components/ToggleButtons";
import { usePharmacy_Dashboard_Periodic_StatsQuery } from "@graphql/";
import {
  Euro,
  ShoppingCartOutlined,
  LocalShipping,
  Warning,
} from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from "@mui/material";
import { useApplicationContext } from "@src/context";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./styles";
import Echarts from "echarts-for-react";
import { useEchartsOptions } from "./useEchartsOptions";
import { PdfDownloaderBtn } from "@components/PdfDownloaderBtn";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { StripeTransfertStats } from "@components/StripeTransfertStats";

export const Dashboard: FC = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const { search, pathname } = useLocation();
  const { connectedUser } = useApplicationContext();
  const { userIncomeStatsOptions } = useEchartsOptions();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const dateRange =
    (query.get("dateRange") as "week" | "month" | "year") || "week";
  const periodicStatsQuery = usePharmacy_Dashboard_Periodic_StatsQuery({
    fetchPolicy: "cache-and-network",
    variables: { idVendor: connectedUser?.id, period: dateRange },
    skip: !connectedUser?.id,
  });
  const periodicStats =
    periodicStatsQuery.data?.pharmacyDashboardPeriodicStats || {};

  const toggleButtons = [
    {
      label: t("dashboard.filter.week"),
      value: "week",
    },
    { label: t("dashboard.filter.month"), value: "month" },
    { label: t("dashboard.filter.year"), value: "year" },
  ];

  const handleDateRangeChange = (value: string) => {
    navigate(`${pathname}?status=PHARMACY_DASHBOARD&dateRange=${value}`);
  };

  return (
    <Box className={classes.root} id="pharmacy-dashboard">
      <Card sx={{ p: 3 }}>
        <Box className={classes.filterRoot}>
          <PdfDownloaderBtn
            rootElementId="pharmacy-dashboard"
            fileName={`pharmacy-dashboard-${moment().format("x")}.pdf`}
            ignoredElementIds={["download-pdf-btn"]}
          />
          <ToggleButtons
            onChange={handleDateRangeChange}
            value={dateRange}
            toggleButtons={toggleButtons}
          />
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography fontWeight="bold" variant="subtitle1">
            {t("dashboard.statistics")}
          </Typography>
          <Divider/>
          <Box sx={{mt: 1}}>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} md={4} lg={3}>
                <StatsCard
                  label={t("dashboard.orders")}
                  value={periodicStats?.globalStats?.ORDERS || 0}
                  icon={<ShoppingCartOutlined fontSize="large" />}
                  color="linear-gradient(45deg, #4099ff, #73b4ff)"
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <StatsCard
                  label={t("dashboard.income")}
                  value={periodicStats?.globalStats?.INCOMES || 0}
                  icon={<Euro fontSize="large" />}
                  color="linear-gradient(45deg, #2ed8b6, #59e0c5)"
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <StatsCard
                  label={t("dashboard.deliveries")}
                  value={periodicStats?.globalStats?.DELIVERED || 0}
                  icon={<LocalShipping fontSize="large" />}
                  color="linear-gradient(45deg, #FFB64D, #ffcb80)"
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <StatsCard
                  label={t("dashboard.pendingDeliveries")}
                  value={periodicStats?.globalStats?.READY || 0}
                  icon={<Warning fontSize="large" />}
                  color="linear-gradient(45deg, #FF5370, #ff869a)"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <StripeTransfertStats periodicStats={periodicStats} />
        <Grid item xs={12}>
          <Card>
            <CardHeader title={t("dashboard.income")} />
            <CardContent>
              <Echarts
                option={userIncomeStatsOptions(
                  periodicStats?.incomeStats || []
                )}
              />
            </CardContent>
          </Card>
        </Grid>
      </Card>
    </Box>
  );
};
