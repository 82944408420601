import { Map } from "@components/Map";
import { Address as AddressType, useAddressesQuery } from "@graphql/";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  debounce,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { validateAddress } from "@src/services";
import { AddressValidationResponse } from "@src/types";
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useStyles } from "./styles";
import { Column, Table } from "@components/Table/Table";
import { DeleteIconBtn } from "@components/DeleteIconBtn";
import { Edit } from "@mui/icons-material";
import { useAddressMutations } from "./useAddressMutations";
import { CreateOrderState } from "@components/CreateOrderStepper";
import { usePagination } from "@src/hooks";
import { useApplicationContext } from "@src/context";
import { Modal } from "@components/Modal";
import { AddressMobileRow } from "@components/MobileTableRows/AddressMobileRow";
import { MoreHorizMenu } from "@components/MoreHorizMenu";
import { ConfirmDeleteModal } from "@components/ConfirmDeleteModal";
import { addressComponentTypes } from "@src/constants";
import { useTranslation } from "react-i18next";

interface AddressProps {
  onNextStep?: () => void;
  setState?: Dispatch<SetStateAction<CreateOrderState | undefined>>;
  disableNextStepBtn?: boolean;
  selectedRow?: any;
}

export const Address: FC<AddressProps> = ({
  onNextStep,
  setState,
  disableNextStepBtn,
  selectedRow,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const { connectedUser } = useApplicationContext();
  const [openAddressForm, setOpenAddressForm] = useState<boolean>(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
    useState<boolean>(false);
  const [addressValidationResponse, setAddressValidationResponse] =
    useState<AddressValidationResponse | null>(null);
  const [address, setAddress] = useState<AddressType | null>(null);
  const [input, setInput] = useState<string>("");
  const [isValidatingAddress, setIsValidatingAddress] =
    useState<boolean>(false);
  const [term, setTerm] = useState<string>("");

  const {
    createOneAddress,
    deleteOneAddress,
    updateOneAddress,
    deletingOneAddress,
  } = useAddressMutations(handleToggleForm);
  const pagination = usePagination();
  const addressesQuery = useAddressesQuery({
    variables: {
      filter:
        term !== ""
          ? {
              or: [
                {
                  name: {
                    iLike: `%${term}%`,
                  },
                },
                {
                  city: {
                    iLike: `%${term}%`,
                  },
                },
                {
                  zipcode: {
                    iLike: `%${term}%`,
                  },
                },
              ],
            }
          : undefined,
      paging: pagination.offsetPaging,
    },
    onCompleted: (data) => {
      pagination.setTotalCount(data.addresses.totalCount);
      if (data.addresses.totalCount === 1) {
        handleRowClick(data.addresses.nodes[0] as AddressType);
      }
    },
  });

  const theme = useTheme();
  const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const addresses = addressesQuery.data?.addresses.nodes || [];

  const handleSubmitAddress = () => {
    if (!addressValidationResponse) {
      throw "addressValidationResponse is not defined";
    }
    const state = {
      name:
        addressValidationResponse.result.address.postalAddress.addressLines.join(
          ","
        ) || "",
      city:
        addressValidationResponse.result.address.postalAddress.locality || "",
      country: "France",
      zipcode:
        addressValidationResponse.result.address.postalAddress.postalCode,
      latitude: addressValidationResponse.result.geocode.location.latitude,
      longitude: addressValidationResponse.result.geocode.location.longitude,
    };
    if (address) {
      updateOneAddress({
        variables: {
          input: {
            id: address.id,
            update: state,
          },
        },
      });
    } else {
      createOneAddress({
        variables: {
          input: {
            address: state,
          },
        },
      });
    }
  };

  function handleToggleForm() {
    setOpenAddressForm(false);
    setAddress(null);
    setAddressValidationResponse(null);
    setInput("");
  }

  const actionColumn = (row: AddressType) => {
    const handleEditAddress = () => {
      setAddress(row);
      setInput(`${row.name}, ${row.zipcode} ${row.city}, ${row.country}`);
      setOpenAddressForm(true);
    };
    const handleDelete = () => {
      if (row?.id) {
        deleteOneAddress({ variables: { input: { id: row.id } } });
      }
    };
    const handleSelect = (selected: string) => {
      setAddress(row);
      switch (selected) {
        case "UPDATE":
          setInput(`${row.name}, ${row.zipcode} ${row.city}, ${row.country}`);
          setOpenAddressForm(true);
          break;
        case "DELETE":
          if (onlyMobile) {
            setOpenConfirmDeleteDialog(true);
          } else {
            deleteOneAddress({ variables: { input: { id: row.id } } });
          }
          break;
      }
    };
    const menus = [
      {
        label: t("commons.edit"),
        value: "UPDATE",
        disabled:
          (row?.orders?.totalCount || 0) > 0,
      },
      {
        label: t("commons.delete"),
        value: "DELETE",
        disabled:
          (row?.orders?.totalCount || 0) > 0,
      },
    ];
    if (onlyMobile) {
      return (
        <>
          <MoreHorizMenu items={menus} onSelect={handleSelect} />
          <ConfirmDeleteModal
            open={openConfirmDeleteDialog}
            setOpen={setOpenConfirmDeleteDialog}
            loading={deletingOneAddress.loading}
            onDelete={handleDelete}
            deleted={deletingOneAddress.data?.deleteOneAddress?.id}
          />
        </>
      );
    }

    return (
      <>
        <IconButton
          onClick={handleEditAddress}
          disabled={
            row?.orders?.totalCount && row?.orders?.totalCount > 0
              ? true
              : false
          }
        >
          <Edit />
        </IconButton>
        &nbsp;
        <DeleteIconBtn
          loading={deletingOneAddress.loading}
          onDelete={handleDelete}
          deleted={deletingOneAddress.data?.deleteOneAddress?.id}
          disabled={
            row?.orders?.totalCount && row?.orders?.totalCount > 0
              ? true
              : false
          }
        />
      </>
    );
  };

  const columns: Column[] = [
    {
      label: t("address.list.label.address"),
      name: "name",
    },
    {
      label: t("address.list.label.zipCode"),
      name: "zipcode",
    },
    {
      label: t("address.list.label.city"),
      name: "city",
    },
    {
      label: t("address.list.label.orderNumber"),
      name: "",
      renderer: (row: AddressType) => {
        return row.orders?.totalCount;
      },
    },
    {
      label: t("commons.actions"),
      name: "",
      renderer: actionColumn,
    },
  ];

  const handleRowClick = (row: AddressType) => {
    setState && setState((prev) => ({ ...prev, address: row }));
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setInput(value);
  };

  const handleSearch = useCallback(
    debounce(async (input) => {
      setIsValidatingAddress(true);
      const data = await validateAddress(input);
      setAddressValidationResponse(data);
      setIsValidatingAddress(false);
    }, 1500),
    []
  );

  const handleSearchOnTable = (term: string) => {
    setTerm(term);
  };

  const missingComponentTypes = (
    addressValidationResponse?.result.address.missingComponentTypes || []
  ).map((type) => addressComponentTypes[type]);
  const unconfirmedComponentTypes = (
    addressValidationResponse?.result.address.unconfirmedComponentTypes || []
  ).map((type) => addressComponentTypes[type]);
  const isValidAddress =
    missingComponentTypes.length === 0 &&
    unconfirmedComponentTypes.length === 0 &&
    input !== "";

  const geocode = addressValidationResponse?.result.geocode;

  useEffect(() => {
    if (input.length > 2) {
      handleSearch(input);
    }
  }, [input, openAddressForm]);

  return (
    <Box>
      <Table
        columns={columns}
        data={addresses}
        onRowClick={handleRowClick}
        title={t("address.list.title")}
        toolbar={
          <Grid container spacing={2} justifyContent="end">
            <Grid item xs={12} lg={5}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenAddressForm(true)}
                className={classes.createBtn}
                disabled={
                  ["PHARMACY", "LIVREUR"].includes(
                    connectedUser?.entity?.code as string
                  ) && addresses.length > 0
                }
                fullWidth
              >
                {t("address.list.add")}
              </Button>
            </Grid>
          </Grid>
        }
        selectedRow={selectedRow}
        pagination={pagination}
        mobileTableRow={
          <AddressMobileRow
            actions={actionColumn}
            onRowClick={handleRowClick}
            selected={selectedRow}
          />
        }
        model="address"
        infiniteScrollHeight="calc(100vh - 530px)"
        onSearch={handleSearchOnTable}
        term={term}
        loading={addressesQuery.loading}
      />
      <Divider sx={{ my: 4 }} />
      {onNextStep && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3 }}>
          <Button
            variant="contained"
            onClick={onNextStep}
            className={classes.createBtn}
            disabled={disableNextStepBtn || !addresses.length}
          >
            {t("commons.next")}
          </Button>
        </Box>
      )}
      {openAddressForm && (
        <Modal
          open={openAddressForm}
          onClose={handleToggleForm}
          title={
            address
              ? t("address.form.title.create")
              : t("address.form.title.update")
          }
          text={
            address
            ? t("address.form.subtitle.create")
            : t("address.form.subtitle.update")
          }
          maxWidth="xs"
        >
          <TextField
            label={t("address.form.address")}
            value={input}
            onChange={handleChange}
            fullWidth={true}
            disabled={isValidatingAddress}
            sx={{ my: 2 }}
          />
          {isValidatingAddress ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              {addressValidationResponse?.result && (
                <Typography
                  mb={2}
                >{`${t("address.form.addressFound")}: ${addressValidationResponse.result.address.formattedAddress}`}</Typography>
              )}
              {missingComponentTypes?.length > 0 && (
                <Typography mb={2}>{`${t(
                  "address.form.missingComponent"
                )}: ${missingComponentTypes.join(",")}`}</Typography>
              )}
              {unconfirmedComponentTypes?.length > 0 && (
                <Typography mb={2}>{`${t(
                  "address.form.notVerifiedComponent"
                )}: ${unconfirmedComponentTypes.join(",")}`}</Typography>
              )}
              {isValidAddress && (
                <Typography>{t("address.form.validAddress")}</Typography>
              )}
            </Box>
          )}
          {geocode && (
            <Map
              id="addres-form"
              latLng={{
                lat: geocode.location.latitude,
                lng: geocode.location.longitude,
              }}
            />
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              onClick={handleToggleForm}
              variant="contained"
              sx={{ marginRight: 2 }}
              color="error"
            >
              {t("commons.cancel")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={missingComponentTypes.length > 0 || !input}
              onClick={handleSubmitAddress}
            >
              {unconfirmedComponentTypes.includes(t("address.form.componentErrors.street_number")) ||
              unconfirmedComponentTypes.includes(t("address.form.componentErrors.route"))
                ? t("address.form.saveAnyway")
                : t("commons.save")}
            </Button>
          </Box>
        </Modal>
      )}
    </Box>
  );
};
