import {
  CreateOrderState,
  CreateOrderStepper,
} from "@components/CreateOrderStepper";
import { useOrderMutations } from "@components/CreateOrderStepper/useOrderMutations";
import { DeliveryDetails } from "@components/DeliveryDetails";
import { FileViewer } from "@components/FileViewer";
import { OrderDeliveryCodeModal } from "@components/OrderDeliveryCodeModal";
import { OrderDetails } from "@components/OrderDetails";
import { OrderItemsModal } from "@components/OrderItemsModal";
import { Column, Tab, Table } from "@components/Table";
import {
  InfoLegale,
  Order,
  OrderSortFields,
  SortDirection,
  useOrdersQuery,
  User,
} from "@graphql/";
import {
  Box,
  Button,
  Chip,
  Grid,
  SelectChangeEvent,
  TextField,
  useMediaQuery,
  useTheme,
  SortDirection as MUISortDirection,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useApplicationContext } from "@src/context";
import {
  useFetchMore,
  useFilesMutations,
  useOrdersFilter,
  usePagination,
  useParams,
} from "@src/hooks";
import { encodeParam } from "@utils/encodeParams";
import moment from "moment";
import React, { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCustomerProps } from "./useCustomerProps";
import { useLivreurProps } from "./useLIvreurProps";
import { usePharmacyProps } from "./usePharmacyProps";
import { useSuperadminProps } from "./useSuperadminProps";
import { Modal } from "@components/Modal";
import { OrderMobileRow } from "@components/MobileTableRows/OrderMobileRow";
import { PayzenForm } from "@components/PayzenForm";
import { OrderTransfertHistory } from "@components/OrderTransfertHistory";
import { useTranslation } from "react-i18next";
import { Sort } from "@components/Table/TableHeader";
import { VendorSelection } from "@components/CreateOrderStepper/VendorSelection";

const MyOrders: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showVendorForm, setShowVendorForm] = useState<boolean>(false);
  const [showDeliveryDetails, setShowDeliveryDetails] =
    useState<boolean>(false);
  const [showOrderDetails, setShowOrderDetails] = useState<boolean>(false);
  const [showFileViewer, setShowFileViewer] = useState<boolean>(false);
  const [showDeliveryCodeForm, setShowDeliveryCodeForm] =
    useState<boolean>(false);
  const [showOrderItemsModal, setShowOrderItemsModal] =
    useState<boolean>(false);
  const [showPayzenForm, setShowPayzenForm] = useState<boolean>(false);
  const [showTransfertHistory, setShowTransfertHistory] =
    useState<boolean>(false);
  const [order, setOrder] = useState<Order>();
  const theme = useTheme();
  const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { connectedUser, isCustomer, connectedUserType } =
    useApplicationContext();
  const entity = connectedUser?.entity;
  const { params, requiredParams } = useParams();

  const { superadminActions, superadminColumns } = useSuperadminProps({
    setOrder,
    setShowTransfertHistory,
    entity,
  });
  const { customerActions, customerColumns } = useCustomerProps({
    setShowForm,
    setOrder,
    isCustomer,
    setShowOrderDetails,
    setShowPayzenForm,
    setShowVendorForm,
  });
  const { pharmacyActions, pharmacyColumns } = usePharmacyProps({
    setOrder,
    setShowFileViewer,
    setShowOrderItemsModal,
    entity,
    connectedUser: connectedUser as User,
    setShowOrderDetails,
  });
  const { livreurActions, livreurColumns } = useLivreurProps({
    setOrder,
    setShowDeliveryDetails,
    entity,
    connectedUser,
    setShowDeliveryCodeForm,
  });

  const { updateOneOrder } = useOrderMutations();

  const orderStatuses = {
    NEW: { label: t("order.statuses.NEW"), color: "#1AA7EC" },
    NOT_VERIFIED: {
      label: t("order.statuses.NOT_VERIFIED"),
      color: "#1AA7EC",
    },
    UNPAID: { label: t("order.statuses.UNPAID"), color: "#FC7869" },
    PAID: { label: t("order.statuses.PAID"), color: "#2ecc71 " },
    UNAVAILABLE: {
      label: t("order.statuses.UNAVAILABLE"),
      color: "#2C3E50",
    },
    REFUNDED: {
      label: t("order.statuses.REFUNDED"),
      color: "#2ECC71 ",
    },
    READY: { label: t("order.statuses.READY"), color: "#4ADEDE" },
    DELIVERED: {
      label: t("order.statuses.DELIVERED"),
      color: "#2ECC71 ",
    },
    ASSIGNED: {
      label:
        connectedUserType === "LIVREUR"
          ? t("order.statuses.ASSIGNED")
          : t("order.statuses.ASSIGNED_TO_A_DELIVERY"),
      color: "#FFC107",
    },
    CANCELED: {
      label: t("order.statuses.CANCELED"),
      color: "#2C3E50",
    },
  };

  const { updateFile, updatingFile } = useFilesMutations();
  const { verifyDeliveryCode, verifyingDeliveryCode } = useOrderMutations();

  const pagination = usePagination(true);
  const fetchMore = useFetchMore();
  const { getOrdersFilter } = useOrdersFilter(params);

  const {
    fetchMore: fetchMoreOrders,
    data,
    loading,
  } = useOrdersQuery({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: getOrdersFilter(),
      paging: onlyMobile ? fetchMore.offsetPaging : pagination.offsetPaging,
      sorting: params.sortFields
        ? params.sortFields.split(",").map((sortField) => {
            const splits = sortField.split("__");
            return {
              field: splits[0] as OrderSortFields,
              direction: splits[1].toUpperCase() as SortDirection,
            };
          })
        : undefined,
    },
    skip: !connectedUser,
    onCompleted: (data) => {
      if (order) {
        const newOrder = data.orders.nodes.find((o) => o.id === order.id);
        setOrder(newOrder as Order);
      }
      pagination.setTotalCount(data.orders.totalCount);
    },
  });

  const orders = data?.orders.nodes || [];

  const cancelReasonColumn =
    params.status === "CANCELED"
      ? [
          {
            label: t("order.list.label.cancelReason"),
            name: "cancelReson",
            renderer: (row: Order) => {
              return t(
                `order.list.statuses.CANCEL_REASON.${row.cancelReason}`
              );
            },
          },
        ]
      : [];

  const columns: Column[] = [
    {
      label: t("order.list.label.ref"),
      name: "ref",
      sortable: true,
    },
    {
      label: t("order.list.label.dateTime"),
      name: "datetime",
      renderer: (row: Order) => {
        return moment(row.datetime).format("DD/MM/YYYY à hh:mm");
      },
      breaking: true,
      sortable: true,
    },
    {
      label: t("order.list.label.status"),
      name: "status",
      renderer: (row: Order) => {
        const status = orderStatuses[row.status as keyof typeof orderStatuses];
        return (
          <Chip
            label={status.label}
            sx={{ color: "#FFFFFF", background: status.color }}
          />
        );
      },
      sortable: true,
    },
    ...cancelReasonColumn,
    {
      label: isCustomer
        ? t("order.list.label.pharmacy")
        : t("order.list.label.customer"),
      name: isCustomer ? "vendor_infoLegale_name" : "customer_firstName",
      renderer: (row: Order) => {
        return isCustomer
          ? row.vendor?.infoLegale?.name.split("(")[0]
          : row.customer
          ? `${row.customer?.firstName} ${row.customer?.lastName}`
          : t("commons.deletedUser");
      },
      breaking: true,
      sortable: true,
    },
    {
      label: t("order.list.label.deliveryAddress"),
      name: "address_name",
      renderer: (row: Order) => {
        return row.address
          ? `${row.address.name} ${row.address.city} ${row.address.zipcode}`
          : t("commons.deletedAddress");
      },
      breaking: true,
      sortable: true,
    },
    ...customerColumns,
    ...pharmacyColumns,
    ...livreurColumns,
    ...superadminColumns,
    {
      label: t("commons.actions"),
      name: "",
      renderer: isCustomer
        ? customerActions
        : entity?.code === "PHARMACY"
        ? pharmacyActions
        : entity?.code === "LIVREUR"
        ? livreurActions
        : superadminActions,
    },
  ];

  const handleUpdateFileStatus = (e: SelectChangeEvent<string>) => {
    const { name: id, value } = e.target;
    setOrder((prev) => {
      if (!prev) {
        return undefined;
      }
      return {
        ...prev,
        files: prev?.files?.map((file) =>
          file.id === id ? { ...file, status: value } : file
        ),
      };
    });
  };

  const handleVerifyDeliveryCode = (deliveryCode: string) => {
    if (order) {
      verifyDeliveryCode({
        variables: { idOrder: order.id, deliveryCode },
      }).then(() => setShowDeliveryCodeForm(false));
    }
  };

  const handleDateTimeChange = (name: string, value: string | null) => {
    if (value) {
      const query = encodeParam({
        ...params,
        [name]: moment(value).format("YYYY/MM/DD"),
      });
      navigate(`${pathname}?${query}`);
    }
  };
  const handleCloseForm = () => {
    setOrder(undefined);
    setShowForm(false);
  };

  const handleCloseVendorForm = () => {
    setOrder(undefined);
    setShowVendorForm(false);
  };

  const handleClickTab = (value: string) => {
    const query = encodeParam({
      ...params,
      tab: value,
      orderRef: "",
    });
    navigate(`${pathname}?${query}`);
  };

  const pharmacyTabs: Tab[] = [
    {
      label: t("order.list.toValidate").toUpperCase(),
      onClick: handleClickTab,
      value: "NEW",
    },
    {
      label: t("order.list.validated").toUpperCase(),
      onClick: handleClickTab,
      value: "OTHERS",
    },
  ];

  const handleSubmitFiles = async () => {
    await Promise.all(
      (order?.files || []).map(async (file) =>
        updateFile({
          variables: {
            input: { id: file.id, update: { status: file.status } },
          },
        })
      )
    );
    setShowFileViewer(false);
  };

  const handleFetchMore = async () => {
    if (orders.length < (data?.orders?.totalCount || 0)) {
      fetchMoreOrders({
        variables: {
          paging: { offset: orders.length, limit: 10 },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            orders: {
              ...prev.orders,
              nodes: [...prev.orders.nodes, ...fetchMoreResult.orders.nodes],
            },
          });
        },
      });
    }
  };

  const handleSearch = (term: string) => {
    const query = encodeParam({
      ...requiredParams,
      offset: 0,
      term,
    });
    navigate(`${pathname}?${query}`);
  };

  const handleSort = (sortedColumns: Sort[]) => {
    const query = encodeParam({
      ...requiredParams,
      sortFields: sortedColumns
        .map(
          (sortedColumn) => `${sortedColumn.name}__${sortedColumn.direction}`
        )
        .join(","),
    });
    navigate(`${pathname}?${query}`);
  };

  const handleUpdateVendor = async () => {
    await updateOneOrder({
      variables: {
        input: {
          id: order!.id,
          update: {
            status: "NEW",
            idVendor: (order!.vendor as unknown as InfoLegale).user.id,
            datetime: order!.datetime,
            preparer: null,
            idDeliveryMan: null,
          },
        },
      },
    });
    handleCloseVendorForm();
  };
  return (
    <Box>
      <Modal
        open={showForm}
        maxWidth="lg"
        onClose={handleCloseForm}
        title={
          order
            ? `${t("order.form.title.update")} ${order.ref}`
            : t("order.form.title.create")
        }
      >
        <CreateOrderStepper onClose={handleCloseForm} order={order} />
      </Modal>
      <Modal
        open={showVendorForm}
        maxWidth="lg"
        onClose={handleCloseVendorForm}
        title={`${t("order.list.pharmacySelection.rerunTitle")} ${order?.ref}`}
      >
        <VendorSelection
          action="RELAUNCH"
          setState={
            setOrder as React.Dispatch<
              React.SetStateAction<CreateOrderState | undefined>
            >
          }
          datetime={order?.datetime}
          onPreviousStep={handleCloseVendorForm}
          onNextStep={handleUpdateVendor}
          state={{ address: order?.address || undefined }}
          disableNextStepBtn={
            !order?.vendor || moment(order?.datetime).isBefore(Date.now())
          }
        />
      </Modal>
      {showDeliveryDetails && (
        <DeliveryDetails
          show={showDeliveryDetails}
          onClose={() => {
            setOrder(undefined);
            setShowDeliveryDetails(false);
          }}
          order={order}
        />
      )}
      {showOrderDetails && (
        <OrderDetails
          show={showOrderDetails}
          onClose={() => {
            setOrder(undefined);
            setShowOrderDetails(false);
          }}
          order={order}
        />
      )}
      <Table
        title={t("order.list.title")}
        columns={columns}
        data={orders}
        loading={loading}
        toolbar={
          <Grid container spacing={2} justifyContent="end">
            <Grid item xs={6} lg={3}>
              <DatePicker
                label={t("order.list.startDate")}
                value={params.from}
                onChange={(value) => handleDateTimeChange("from", value)}
                renderInput={(params) => (
                  <TextField size="small" {...params} fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <DatePicker
                label={t("order.list.endDate")}
                value={params.to}
                onChange={(value) => handleDateTimeChange("to", value)}
                renderInput={(params) => (
                  <TextField size="small" {...params} fullWidth />
                )}
              />
            </Grid>
            {isCustomer && (
              <Grid item xs={12} lg={3}>
                <Button
                  variant="contained"
                  onClick={() => setShowForm(true)}
                  fullWidth
                  color="primary"
                >
                  {t("order.list.create")}
                </Button>
              </Grid>
            )}
          </Grid>
        }
        tabs={
          entity?.code === "PHARMACY" && params.status === "ALL_ORDERS"
            ? pharmacyTabs
            : undefined
        }
        pagination={pagination}
        activeTab={params.tab}
        mobileTableRow={
          <OrderMobileRow
            actions={
              isCustomer
                ? customerActions
                : entity?.code === "PHARMACY"
                ? pharmacyActions
                : entity?.code === "LIVREUR"
                ? livreurActions
                : superadminActions
            }
          />
        }
        onFetchMore={handleFetchMore}
        infiniteScrollHeight="calc(100vh - 300px)"
        model="order"
        onSearch={handleSearch}
        term={params.term}
        onSort={handleSort}
        sort={
          params.sortFields
            ? params.sortFields.split(",").map((sortField) => {
                const splits = sortField.split("__");
                return {
                  name: splits[0],
                  direction: splits[1] as MUISortDirection,
                };
              })
            : undefined
        }
      />

      <Modal
        open={showFileViewer}
        onClose={() => setShowFileViewer(false)}
        title={`${t("order.list.documents.title")} ${order?.ref}`}
        actions={
          <Button
            variant="contained"
            onClick={handleSubmitFiles}
            disabled={updatingFile.loading || !order?.files?.length}
          >
            {t("commons.completed")}
          </Button>
        }
      >
        {!order?.files?.length ? (
          <Typography>{t("order.list.documents.empty")}</Typography>
        ) : (
          <FileViewer
            files={order?.files || []}
            onStatusChange={handleUpdateFileStatus}
            loading={updatingFile.loading}
            disabled={order?.preparer ? false : true}
          />
        )}
      </Modal>
      {showOrderItemsModal && (
        <OrderItemsModal
          open={showOrderItemsModal}
          setOpen={setShowOrderItemsModal}
          order={order}
        />
      )}
      {showDeliveryCodeForm && (
        <OrderDeliveryCodeModal
          open={showDeliveryCodeForm}
          setOpen={setShowDeliveryCodeForm}
          onVerifyDeliveryCode={handleVerifyDeliveryCode}
          order={order}
          loading={verifyingDeliveryCode.loading}
        />
      )}
      {showPayzenForm && (
        <PayzenForm
          open={showPayzenForm}
          onClose={() => setShowPayzenForm(false)}
          order={order}
        />
      )}
      {showTransfertHistory && (
        <OrderTransfertHistory
          open={showTransfertHistory}
          onClose={() => setShowTransfertHistory(false)}
          order={order}
        />
      )}
    </Box>
  );
};

export { MyOrders };
