import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Stripe_Account_Statuses_CountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type Stripe_Account_Statuses_CountQuery = { __typename?: 'Query', stripeAccountStatusesCounts: any };


export const Stripe_Account_Statuses_CountDocument = gql`
    query STRIPE_ACCOUNT_STATUSES_COUNT {
  stripeAccountStatusesCounts
}
    `;

/**
 * __useStripe_Account_Statuses_CountQuery__
 *
 * To run a query within a React component, call `useStripe_Account_Statuses_CountQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripe_Account_Statuses_CountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripe_Account_Statuses_CountQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripe_Account_Statuses_CountQuery(baseOptions?: Apollo.QueryHookOptions<Stripe_Account_Statuses_CountQuery, Stripe_Account_Statuses_CountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Stripe_Account_Statuses_CountQuery, Stripe_Account_Statuses_CountQueryVariables>(Stripe_Account_Statuses_CountDocument, options);
      }
export function useStripe_Account_Statuses_CountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Stripe_Account_Statuses_CountQuery, Stripe_Account_Statuses_CountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Stripe_Account_Statuses_CountQuery, Stripe_Account_Statuses_CountQueryVariables>(Stripe_Account_Statuses_CountDocument, options);
        }
export type Stripe_Account_Statuses_CountQueryHookResult = ReturnType<typeof useStripe_Account_Statuses_CountQuery>;
export type Stripe_Account_Statuses_CountLazyQueryHookResult = ReturnType<typeof useStripe_Account_Statuses_CountLazyQuery>;
export type Stripe_Account_Statuses_CountQueryResult = Apollo.QueryResult<Stripe_Account_Statuses_CountQuery, Stripe_Account_Statuses_CountQueryVariables>;