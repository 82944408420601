import { CreateOrderState } from "@components/CreateOrderStepper";
import { Order, Mutuelle as MutuelleType, useMutuellesQuery } from "@graphql/";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { useStyles } from "./styles";
import { FileUploader } from "react-drag-drop-files";
import { useFilesMutations } from "@src/hooks";
import { useApplicationContext } from "@src/context";
import { getFileType } from "@utils/getFileType";
import { decryptFilePublicUrl } from "@utils/decryptFilePublicUrl";
import { PdfViewer } from "@components/PdfViewer/PdfViewer";

interface MutuelleProps {
  setState: Dispatch<SetStateAction<CreateOrderState | undefined>>;
  state?: CreateOrderState;
  disableNextStepBtn?: boolean;
  onPreviousStep: () => void;
  order?: Order;
  onNextStep: () => void;
}

export const Mutuelle: FC<MutuelleProps> = ({
  setState,
  state,
  onPreviousStep,
  disableNextStepBtn,
  order,
  onNextStep,
}) => {
  const classes = useStyles();
  const [file, setFile] = useState<File>();
  const mutuellesQuery = useMutuellesQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const mutuelle = data?.mutuelles?.nodes[0];
      if (mutuelle) {
        setState((prev) => ({
          ...prev,
          mutuelle: mutuelle as MutuelleType,
        }));
      }
    },
  });
  const { uploadFiles, isUploading } = useFilesMutations();
  const mutuelles = mutuellesQuery.data?.mutuelles.nodes || [];
  const mutuelle = mutuelles[0];
  const handleFileUploaderChange = (file: File) => {
    setState((prev) => ({ ...prev, mutuelle: undefined }));
    setFile(file);
  };
  const { connectedUser } = useApplicationContext();
  const idCustomer = connectedUser?.id;

  const fileTypes = ["JPEG", "PNG", "PDF", "JPG"];

  const uploadMutuelle = () => {
    if (!file) {
      throw "file is not defiend";
    }
    uploadFiles([
      {
        file: file,
        name: "MUTUELLE",
        filename: file?.name || "",
        type: "ORDER",
        idUser: idCustomer,
      },
    ]).then(() => {
      mutuellesQuery.refetch();
    });
  };
  return (
    <>
      {mutuellesQuery.loading ? (
        <CircularProgress />
      ) : (
        <Box>
          <Typography variant="h5" mb={1}>
            Mutuelle
          </Typography>
          {mutuelles.length ? (
            <Box>
              <Paper
                variant="outlined"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 1,
                  maxWidth: "100%",
                  mb: 1,
                }}
              >
                {getFileType(mutuelle.file.mimeType) === "pdf" && (
                  <PdfViewer
                    url={decryptFilePublicUrl(mutuelle.file.url)}
                    width={600}
                  />
                )}
                {getFileType(mutuelle.file.mimeType) === "image" && (
                  <img
                    className={classes.fileContainer}
                    src={decryptFilePublicUrl(mutuelle.file.url)}
                    alt={`carte-vitale-${mutuelle.file.id}`}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                )}
              </Paper>
            </Box>
          ) : (
            <Typography mb={2}>Télécharger une mutuelle valide:</Typography>
          )}
          <FileUploader
            multiple={false}
            label="Télécharger ou déposer votre mutuelle ici"
            handleChange={handleFileUploaderChange}
            types={fileTypes}
          />
        </Box>
      )}

      <Divider sx={{ my: 4 }} />
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3 }}>
        <Button
          variant="outlined"
          onClick={onPreviousStep}
          className={classes.createBtn}
          sx={{ marginRight: 2 }}
        >
          Précédent
        </Button>
        {state?.mutuelle ? (
          <Button
            variant="contained"
            onClick={onNextStep}
            className={classes.createBtn}
            disabled={disableNextStepBtn}
          >
            Suivant
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={uploadMutuelle}
            className={classes.createBtn}
            disabled={isUploading}
          >
            Télécharger mutuelle
          </Button>
        )}
      </Box>
    </>
  );
};
