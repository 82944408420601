import {
  useCreate_One_Stripe_Checkout_SessionMutation,
  useRefundMutation,
  useRetrieve_Checkout_SessionMutation,
} from "@graphql/";
import { useApplicationContext } from "@src/context";
export const useStripeCheckoutSessionMutations = () => {
  const { notify } = useApplicationContext();
  const [createOneStripeCheckoutSession, creatingOneStripeCheckoutSession] =
    useCreate_One_Stripe_Checkout_SessionMutation({
      onCompleted: (data) => {
        if (data.createOneStripeCheckoutSession) {
          window.open(
            data.createOneStripeCheckoutSession.url,
            "_blank",
            "noreferrer"
          );
        }
      },
      onError: () => {
        notify({
          message: "Une erreur s'est produite lors de la création de Checkout",
          type: "error",
        });
      },
    });

  const [retrieveCheckoutSession, retrievingCheckoutSession] =
    useRetrieve_Checkout_SessionMutation();

  const [refund, refunding] = useRefundMutation({
    refetchQueries: ["ORDERS"],
    onCompleted: (data) => {
      if (data.refund.status === "REFUNDED") {
        notify({
          message: "Votre commande a été remboursé avec succèes",
          type: "success",
        });
      }
    },
    onError: () => {
      notify({
        message:
          "Une erreur s'est produite lors du remboursement de votre commande",
        type: "error",
      });
    },
  });

  return {
    createOneStripeCheckoutSession,
    creatingOneStripeCheckoutSession,
    retrieveCheckoutSession,
    retrievingCheckoutSession,
    refund,
    refunding,
  };
};
