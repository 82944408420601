export const phoneRegExp = /^(?:\+33|0033|0[67])[0-9]{8}$/;

export const passwordRegExp =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+!?*$@%_])([-+!?*$@%_\w]{10,50})$/;

export const ibanRegExp = /^[A-Z]{2}\d{2}[A-Z0-9]{4}\d{7}([A-Z0-9]?){0,16}$/;

export const userStatuses = [
  {
    value: "EMAIL_VALIDATION",
    label: "En attente de validation d'email",
    color: "#D32F2F",
  },
  {
    value: "EMAIL_VERIFIED",
    label: "Email vérifié",
    color: "#31956B",
  },
  {
    value: "SUSPENDED",
    label: "Suspendu",
    color: "#1246B8",
  },
  {
    value: "CHANGE_PASSWORD",
    label: "Changement de mot de passe",
    color: "#636363",
  },
  {
    value: "BEING_DELETED",
    label: "En cours de suppression",
    color: "#1246B8",
  }
];

export const entities = {
  PHARMACY: "Pharmacie",
  LIVREUR: "Livreur",
  INDIVIDUAL: "Particulier",
  PROFESSIONNAL: "Professionnel",
};

export const stripeAccountStatuses = {
  NOT_CREATED: "A créer",
  RESTRICTED: "Limité",
  COMPLETED: "Activé",
  PENDING: "Suspendu",
  DELETED: "Supprimé",
};

export const roles = {
  USER: "Utilisateur",
  ADMIN: "Administrateur",
  SUPERADMIN: "Super Administrateur",
};

export type Entity = keyof typeof entities;
export type StripeAccountStatus = keyof typeof stripeAccountStatuses;
export type Role = keyof typeof roles;

export const addressComponentTypes = {
  street_number: "Numéro de route",
  route: "Route",
  country: "Pays",
  postal_code: "Code postale",
  locality: "Ville",
};
