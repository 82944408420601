import React, { ChangeEventHandler, FC } from "react";
import { File as FileType } from "@graphql/";
import { Box, Button, Chip, Typography } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface FileInputProps {
  label: string;
  name: string;
  onFileChange: ChangeEventHandler<HTMLInputElement>;
  file: File | FileType | undefined;
}

export const FileInput: FC<FileInputProps> = ({
  label,
  name,
  onFileChange,
  file,
}) => {
  const { t } = useTranslation();

  const statuses = {
    NOT_VERIFIED: {
      label: t("file.statuses.NOT_VERIFIED"),
      color: "#9e9e9e",
    },
    VALID: {
      label: t("file.statuses.VALID"),
      color: "#4caf50",
    },
    INVALID: {
      label: t("file.statuses.INVALID"),
      color: "#f44336",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mt: 2,
        justifyContent: "space-between",
      }}
    >
      <Box display="flex">
        <Typography fontWeight="bold" mr={1}>
          {label}:
        </Typography>
        {file?.name}
        <input
          style={{ display: "none" }}
          id={name}
          name={name}
          onChange={onFileChange}
          type="file"
        />
      </Box>
      <Box>
        {file && (file as FileType).status && (
          <Chip
            label={
              statuses[(file as FileType).status as keyof typeof statuses].label
            }
            sx={{
              color: "#FFFFFF",
              background:
                statuses[(file as FileType).status as keyof typeof statuses]
                  .color,
            }}
          />
        )}

        <label htmlFor={name}>
          <Button component="span">
            <FileUpload />
          </Button>
        </label>
      </Box>
    </Box>
  );
};
