import {
  useCreate_One_TarificationMutation,
  useUpdate_One_TarificationMutation,
  useDelete_One_TarificationMutation,
} from "@graphql/";
import { useApplicationContext } from "@src/context";

export const useTarificationMutations = () => {
  const { notify } = useApplicationContext();
  const [createOneTarification, creatingOneTarification] =
    useCreate_One_TarificationMutation({
      refetchQueries: ["TARIFICATIONS"],
      onCompleted: () => {
        notify({
          message: "Votre tarification a été ajouté avec succès",
          type: "success",
        });
      },
      onError: () => {
        notify({
          message:
            "Une erreur s'est produite lors de l'ajout de votre tarification",
          type: "error",
        });
      },
    });

  const [updateOneTarification, updatingOneTarification] =
    useUpdate_One_TarificationMutation({
      refetchQueries: ["TARIFICATIONS"],
      onCompleted: () => {
        notify({
          message: "Votre tarification a été modifié avec succès",
          type: "success",
        });
      },
      onError: () => {
        notify({
          message:
            "Une erreur s'est produite lors de la moditication de votre tarification",
          type: "error",
        });
      },
    });

  const [deleteOneTarification, deletingOneTarification] =
    useDelete_One_TarificationMutation({
      refetchQueries: ["TARIFICATIONS"],
      onCompleted: () => {
        notify({
          message: "Votre tarification a été supprimé avec succès",
          type: "success",
        });
      },
      onError: () => {
        notify({
          message:
            "Une erreur s'est produite lors de la suppression de votre tarification",
          type: "error",
        });
      },
    });

  const loading =
    deletingOneTarification.loading ||
    creatingOneTarification.loading ||
    updatingOneTarification.loading;

  return {
    createOneTarification,
    updateOneTarification,
    deleteOneTarification,
    deletingOneTarification,
    loading,
  };
};
