import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(2),
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    content: {
      [theme.breakpoints.down("sm")]: {
        // padding: `0 !important`,
      },
    },
  })
);
