import * as Types from '../../types';

import { gql } from '@apollo/client';
import { NotificationInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotificationsQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.NotificationFilter>;
  sorting?: Types.InputMaybe<Array<Types.NotificationSort> | Types.NotificationSort>;
}>;


export type NotificationsQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationConnection', totalCount: number, nodes: Array<{ __typename?: 'Notification', id: string, idUser: string, status: string, category: string, action: string, data: any, createdAt: any, updatedAt?: any | null }> } };

export type Notifications_CountQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.NotificationFilter>;
  sorting?: Types.InputMaybe<Array<Types.NotificationSort> | Types.NotificationSort>;
}>;


export type Notifications_CountQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationConnection', totalCount: number } };


export const NotificationsDocument = gql`
    query NOTIFICATIONS($paging: OffsetPaging, $filter: NotificationFilter, $sorting: [NotificationSort!]) {
  notifications(paging: $paging, filter: $filter, sorting: $sorting) {
    nodes {
      ...NotificationInfo
    }
    totalCount
  }
}
    ${NotificationInfoFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const Notifications_CountDocument = gql`
    query NOTIFICATIONS_COUNT($paging: OffsetPaging, $filter: NotificationFilter, $sorting: [NotificationSort!]) {
  notifications(paging: $paging, filter: $filter, sorting: $sorting) {
    totalCount
  }
}
    `;

/**
 * __useNotifications_CountQuery__
 *
 * To run a query within a React component, call `useNotifications_CountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotifications_CountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotifications_CountQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useNotifications_CountQuery(baseOptions?: Apollo.QueryHookOptions<Notifications_CountQuery, Notifications_CountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Notifications_CountQuery, Notifications_CountQueryVariables>(Notifications_CountDocument, options);
      }
export function useNotifications_CountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Notifications_CountQuery, Notifications_CountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Notifications_CountQuery, Notifications_CountQueryVariables>(Notifications_CountDocument, options);
        }
export type Notifications_CountQueryHookResult = ReturnType<typeof useNotifications_CountQuery>;
export type Notifications_CountLazyQueryHookResult = ReturnType<typeof useNotifications_CountLazyQuery>;
export type Notifications_CountQueryResult = Apollo.QueryResult<Notifications_CountQuery, Notifications_CountQueryVariables>;