import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Superadmin_Dashboard_Periodic_StatsQueryVariables = Types.Exact<{
  period: Types.Scalars['String'];
}>;


export type Superadmin_Dashboard_Periodic_StatsQuery = { __typename?: 'Query', superadminDashboardPeriodicStats: any };

export type Superadmin_Dashboard_Global_StatsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type Superadmin_Dashboard_Global_StatsQuery = { __typename?: 'Query', superadminDashboardGlobalStats: any };

export type Pharmacy_Dashboard_Periodic_StatsQueryVariables = Types.Exact<{
  idVendor: Types.Scalars['String'];
  period: Types.Scalars['String'];
}>;


export type Pharmacy_Dashboard_Periodic_StatsQuery = { __typename?: 'Query', pharmacyDashboardPeriodicStats: any };

export type Livreur_Dashboard_Periodic_StatsQueryVariables = Types.Exact<{
  idDeliveryMan: Types.Scalars['String'];
  period: Types.Scalars['String'];
}>;


export type Livreur_Dashboard_Periodic_StatsQuery = { __typename?: 'Query', livreurDashboardPeriodicStats: any };


export const Superadmin_Dashboard_Periodic_StatsDocument = gql`
    query SUPERADMIN_DASHBOARD_PERIODIC_STATS($period: String!) {
  superadminDashboardPeriodicStats(period: $period)
}
    `;

/**
 * __useSuperadmin_Dashboard_Periodic_StatsQuery__
 *
 * To run a query within a React component, call `useSuperadmin_Dashboard_Periodic_StatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuperadmin_Dashboard_Periodic_StatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuperadmin_Dashboard_Periodic_StatsQuery({
 *   variables: {
 *      period: // value for 'period'
 *   },
 * });
 */
export function useSuperadmin_Dashboard_Periodic_StatsQuery(baseOptions: Apollo.QueryHookOptions<Superadmin_Dashboard_Periodic_StatsQuery, Superadmin_Dashboard_Periodic_StatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Superadmin_Dashboard_Periodic_StatsQuery, Superadmin_Dashboard_Periodic_StatsQueryVariables>(Superadmin_Dashboard_Periodic_StatsDocument, options);
      }
export function useSuperadmin_Dashboard_Periodic_StatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Superadmin_Dashboard_Periodic_StatsQuery, Superadmin_Dashboard_Periodic_StatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Superadmin_Dashboard_Periodic_StatsQuery, Superadmin_Dashboard_Periodic_StatsQueryVariables>(Superadmin_Dashboard_Periodic_StatsDocument, options);
        }
export type Superadmin_Dashboard_Periodic_StatsQueryHookResult = ReturnType<typeof useSuperadmin_Dashboard_Periodic_StatsQuery>;
export type Superadmin_Dashboard_Periodic_StatsLazyQueryHookResult = ReturnType<typeof useSuperadmin_Dashboard_Periodic_StatsLazyQuery>;
export type Superadmin_Dashboard_Periodic_StatsQueryResult = Apollo.QueryResult<Superadmin_Dashboard_Periodic_StatsQuery, Superadmin_Dashboard_Periodic_StatsQueryVariables>;
export const Superadmin_Dashboard_Global_StatsDocument = gql`
    query SUPERADMIN_DASHBOARD_GLOBAL_STATS {
  superadminDashboardGlobalStats
}
    `;

/**
 * __useSuperadmin_Dashboard_Global_StatsQuery__
 *
 * To run a query within a React component, call `useSuperadmin_Dashboard_Global_StatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuperadmin_Dashboard_Global_StatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuperadmin_Dashboard_Global_StatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuperadmin_Dashboard_Global_StatsQuery(baseOptions?: Apollo.QueryHookOptions<Superadmin_Dashboard_Global_StatsQuery, Superadmin_Dashboard_Global_StatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Superadmin_Dashboard_Global_StatsQuery, Superadmin_Dashboard_Global_StatsQueryVariables>(Superadmin_Dashboard_Global_StatsDocument, options);
      }
export function useSuperadmin_Dashboard_Global_StatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Superadmin_Dashboard_Global_StatsQuery, Superadmin_Dashboard_Global_StatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Superadmin_Dashboard_Global_StatsQuery, Superadmin_Dashboard_Global_StatsQueryVariables>(Superadmin_Dashboard_Global_StatsDocument, options);
        }
export type Superadmin_Dashboard_Global_StatsQueryHookResult = ReturnType<typeof useSuperadmin_Dashboard_Global_StatsQuery>;
export type Superadmin_Dashboard_Global_StatsLazyQueryHookResult = ReturnType<typeof useSuperadmin_Dashboard_Global_StatsLazyQuery>;
export type Superadmin_Dashboard_Global_StatsQueryResult = Apollo.QueryResult<Superadmin_Dashboard_Global_StatsQuery, Superadmin_Dashboard_Global_StatsQueryVariables>;
export const Pharmacy_Dashboard_Periodic_StatsDocument = gql`
    query PHARMACY_DASHBOARD_PERIODIC_STATS($idVendor: String!, $period: String!) {
  pharmacyDashboardPeriodicStats(idVendor: $idVendor, period: $period)
}
    `;

/**
 * __usePharmacy_Dashboard_Periodic_StatsQuery__
 *
 * To run a query within a React component, call `usePharmacy_Dashboard_Periodic_StatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePharmacy_Dashboard_Periodic_StatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePharmacy_Dashboard_Periodic_StatsQuery({
 *   variables: {
 *      idVendor: // value for 'idVendor'
 *      period: // value for 'period'
 *   },
 * });
 */
export function usePharmacy_Dashboard_Periodic_StatsQuery(baseOptions: Apollo.QueryHookOptions<Pharmacy_Dashboard_Periodic_StatsQuery, Pharmacy_Dashboard_Periodic_StatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Pharmacy_Dashboard_Periodic_StatsQuery, Pharmacy_Dashboard_Periodic_StatsQueryVariables>(Pharmacy_Dashboard_Periodic_StatsDocument, options);
      }
export function usePharmacy_Dashboard_Periodic_StatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Pharmacy_Dashboard_Periodic_StatsQuery, Pharmacy_Dashboard_Periodic_StatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Pharmacy_Dashboard_Periodic_StatsQuery, Pharmacy_Dashboard_Periodic_StatsQueryVariables>(Pharmacy_Dashboard_Periodic_StatsDocument, options);
        }
export type Pharmacy_Dashboard_Periodic_StatsQueryHookResult = ReturnType<typeof usePharmacy_Dashboard_Periodic_StatsQuery>;
export type Pharmacy_Dashboard_Periodic_StatsLazyQueryHookResult = ReturnType<typeof usePharmacy_Dashboard_Periodic_StatsLazyQuery>;
export type Pharmacy_Dashboard_Periodic_StatsQueryResult = Apollo.QueryResult<Pharmacy_Dashboard_Periodic_StatsQuery, Pharmacy_Dashboard_Periodic_StatsQueryVariables>;
export const Livreur_Dashboard_Periodic_StatsDocument = gql`
    query LIVREUR_DASHBOARD_PERIODIC_STATS($idDeliveryMan: String!, $period: String!) {
  livreurDashboardPeriodicStats(idDeliveryMan: $idDeliveryMan, period: $period)
}
    `;

/**
 * __useLivreur_Dashboard_Periodic_StatsQuery__
 *
 * To run a query within a React component, call `useLivreur_Dashboard_Periodic_StatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLivreur_Dashboard_Periodic_StatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLivreur_Dashboard_Periodic_StatsQuery({
 *   variables: {
 *      idDeliveryMan: // value for 'idDeliveryMan'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useLivreur_Dashboard_Periodic_StatsQuery(baseOptions: Apollo.QueryHookOptions<Livreur_Dashboard_Periodic_StatsQuery, Livreur_Dashboard_Periodic_StatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Livreur_Dashboard_Periodic_StatsQuery, Livreur_Dashboard_Periodic_StatsQueryVariables>(Livreur_Dashboard_Periodic_StatsDocument, options);
      }
export function useLivreur_Dashboard_Periodic_StatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Livreur_Dashboard_Periodic_StatsQuery, Livreur_Dashboard_Periodic_StatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Livreur_Dashboard_Periodic_StatsQuery, Livreur_Dashboard_Periodic_StatsQueryVariables>(Livreur_Dashboard_Periodic_StatsDocument, options);
        }
export type Livreur_Dashboard_Periodic_StatsQueryHookResult = ReturnType<typeof useLivreur_Dashboard_Periodic_StatsQuery>;
export type Livreur_Dashboard_Periodic_StatsLazyQueryHookResult = ReturnType<typeof useLivreur_Dashboard_Periodic_StatsLazyQuery>;
export type Livreur_Dashboard_Periodic_StatsQueryResult = Apollo.QueryResult<Livreur_Dashboard_Periodic_StatsQuery, Livreur_Dashboard_Periodic_StatsQueryVariables>;