import React, { FC, useEffect, useState } from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/styles";

import { availableThemes } from "./config";
import { createTheme, Theme } from "@mui/material";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { useApplicationContext } from "@src/context";

const ThemeProvider: FC<any> = (props: any) => {
  const { children } = props;
  const { connectedUserType } = useApplicationContext();
  const theme: Theme = React.useMemo(() => {
    const nextTheme = createTheme(availableThemes[connectedUserType || "PHARMACY"]);
    return nextTheme;
  }, [connectedUserType]);

  return (
    <EmotionThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </EmotionThemeProvider>
  );
};

export default ThemeProvider;
