import * as Types from '../../types';

import { gql } from '@apollo/client';
import { SupportTicketHistoryInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Support_Ticket_HistoriesQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.SupportTicketHistoryFilter>;
  sorting?: Types.InputMaybe<Array<Types.SupportTicketHistorySort> | Types.SupportTicketHistorySort>;
}>;


export type Support_Ticket_HistoriesQuery = { __typename?: 'Query', supportTicketHistories: { __typename?: 'SupportTicketHistoryConnection', totalCount: number, nodes: Array<{ __typename?: 'SupportTicketHistory', id: string, idSupportTicket: string, action: string, createdAt: any, updatedAt?: any | null }> } };


export const Support_Ticket_HistoriesDocument = gql`
    query SUPPORT_TICKET_HISTORIES($paging: OffsetPaging, $filter: SupportTicketHistoryFilter, $sorting: [SupportTicketHistorySort!]) {
  supportTicketHistories(paging: $paging, filter: $filter, sorting: $sorting) {
    nodes {
      ...SupportTicketHistoryInfo
    }
    totalCount
  }
}
    ${SupportTicketHistoryInfoFragmentDoc}`;

/**
 * __useSupport_Ticket_HistoriesQuery__
 *
 * To run a query within a React component, call `useSupport_Ticket_HistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupport_Ticket_HistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupport_Ticket_HistoriesQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSupport_Ticket_HistoriesQuery(baseOptions?: Apollo.QueryHookOptions<Support_Ticket_HistoriesQuery, Support_Ticket_HistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Support_Ticket_HistoriesQuery, Support_Ticket_HistoriesQueryVariables>(Support_Ticket_HistoriesDocument, options);
      }
export function useSupport_Ticket_HistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Support_Ticket_HistoriesQuery, Support_Ticket_HistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Support_Ticket_HistoriesQuery, Support_Ticket_HistoriesQueryVariables>(Support_Ticket_HistoriesDocument, options);
        }
export type Support_Ticket_HistoriesQueryHookResult = ReturnType<typeof useSupport_Ticket_HistoriesQuery>;
export type Support_Ticket_HistoriesLazyQueryHookResult = ReturnType<typeof useSupport_Ticket_HistoriesLazyQuery>;
export type Support_Ticket_HistoriesQueryResult = Apollo.QueryResult<Support_Ticket_HistoriesQuery, Support_Ticket_HistoriesQueryVariables>;