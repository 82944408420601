import { FormWrapper } from "@components/Form/Form";
import {
  InfoLegale as InfoLegaleType,
  useVerify_Bearer_TokenQuery,
} from "@graphql/";
import { Box, Grid, Typography } from "@mui/material";
import { InputProps } from "@src/types";
import React, { FC, useState } from "react";
import * as yup from "yup";
import { useInfoLegaleMutations } from "./useInfoLegaleMutations";
import { useTranslation } from "react-i18next";

interface InfoLegaleProps {}

export const InfoLegale: FC<InfoLegaleProps> = ({}) => {
  const { t } = useTranslation();

  const [infoLegale, setInfoLegale] = useState<InfoLegaleType | null>(null);

  const { createOneInfoLegale, updateOneInfoLegale, loading } =
    useInfoLegaleMutations();

  useVerify_Bearer_TokenQuery({
    onCompleted: (data) => {
      if (data.verifyBearerToken.infoLegale)
        setInfoLegale(data.verifyBearerToken.infoLegale as InfoLegaleType);
    },
  });

  const handleSubmitInfoLegale = async (state: any) => {
    if (infoLegale) {
      await updateOneInfoLegale({
        variables: {
          input: {
            id: infoLegale.id,
            update: {
              name: state.name,
              numTva: state.numTva,
              sirenCode: state.sirenCode,
            },
          },
        },
      }).then(({ data }) => {
        if (data?.updateOneInfoLegale)
          setInfoLegale(data?.updateOneInfoLegale as InfoLegaleType);
      });
    } else {
      await createOneInfoLegale({
        variables: {
          input: {
            infoLegale: { ...state },
          },
        },
      });
    }
  };

  const initialCreateInput = {
    name: "",
    numTva: "",
    sirenCode: "",
  };

  const schema = yup
    .object({
      name: yup
        .string()
        .required(t("infoLegales.errors.denominationIsRequired")),
      numTva: yup
        .string()
        .required(t("infoLegales.errors.vatNumberIsRequired")),
      sirenCode: yup.string().required(t("infoLegales.errors.sirenIsRequired")),
    })
    .required();

  const inputs: InputProps[] = [
    {
      name: "name",
      label: t("infoLegales.denomination"),
      type: "text",
      placeholder: t("infoLegales.denomination"),
    },
    {
      name: "numTva",
      label: t("infoLegales.vatNumber"),
      type: "text",
      placeholder: t("infoLegales.vatNumber"),
    },
    {
      name: "sirenCode",
      label: "SIREN",
      type: "text",
      placeholder: "SIREN",
    },
  ];

  return (
    <Grid container display="flex" justifyContent="center">
      <Grid item xs={12} sm={6}>
        <Box>
          <Typography variant="h4" textAlign="center" color="primary" mb={2}>
            {t("infoLegales.title")}
          </Typography>
          <FormWrapper
            element={infoLegale}
            initialCreateInput={initialCreateInput}
            onSubmit={handleSubmitInfoLegale}
            schema={schema}
            loading={loading}
            submitBtnLabel={
              infoLegale ? t("commons.edit") : t("commons.save")
            }
            inputs={inputs}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
