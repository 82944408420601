import { useOrderMutations } from "@components/CreateOrderStepper/useOrderMutations";
import { Column, Table } from "@components/Table";
import { Order, OrderItem, useOrder_HistoriesQuery } from "@graphql/";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Card, CardContent, CardHeader } from "@mui/material";
import moment from "moment";
import React, { FC } from "react";
import { Modal } from "@components/Modal";

interface OrderTransfertHistoryProps {
  open: boolean;
  onClose: () => void;
  order?: Order;
}

export const OrderTransfertHistory: FC<OrderTransfertHistoryProps> = ({
  order,
  open,
  onClose,
}) => {
  const stripeTransfert = order?.stripeTransferts && order?.stripeTransferts[0];
  const stripePayouts = order?.stripePayouts || [];
  const status = {
    transfert: "Transfert",
    failed: "Echec",
    pending: "En attente",
    in_transit: "En transit",
    paid: "Envoyé",
  };
  return (
    <Modal
      open={open}
      maxWidth="lg"
      title={`Détails de la commande N° ${order?.ref}`}
      onClose={onClose}
    >
      <Card>
        <CardHeader title="Historique" />
        <CardContent>
          <Timeline>
            {[...stripePayouts, stripeTransfert].map((el, index) => (
              <TimelineItem key={`timeline-item-${index}`}>
                <TimelineOppositeContent color="textSecondary">
                  {moment(el?.createdAt).format("DD/MM/YYYY à HH:mm")}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {status[(el?.data.status || "transfert") as keyof typeof status]}
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </CardContent>
      </Card>
    </Modal>
  );
};
