import { FormWrapper } from "@components/Form/Form";
import { Modal } from "@components/Modal";
import { Order } from "@graphql/";
import { InputProps } from "@src/types";
import React, { FC } from "react";
import * as yup from "yup";

interface OrderDeliveryCodeModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  order?: Order;
  onVerifyDeliveryCode: (deliveryCode: string) => void;
  loading: boolean;
}

export const OrderDeliveryCodeModal: FC<OrderDeliveryCodeModalProps> = ({
  open,
  setOpen,
  order,
  onVerifyDeliveryCode,
  loading,
}) => {
  const initialCreateInput = {
    deliveryCode: "",
  };
  const schema = yup
    .object({
      deliveryCode: yup.string().required("Code de livraison est requis"),
    })
    .required();

  const handleVerifyDeliveryCode = (state: any) => {
    onVerifyDeliveryCode(state.deliveryCode);
  };

  const inputs: InputProps[] = [
    {
      name: "deliveryCode",
      label: "Code de livraison",
      type: "text",
      placeholder: "Code de livraison",
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      maxWidth="md"
      title={`Code de livraison de la commande N° ${order?.ref}`}
      onClose={handleClose}
    >
      <FormWrapper
        initialCreateInput={initialCreateInput}
        onSubmit={handleVerifyDeliveryCode}
        onCancel={handleClose}
        schema={schema}
        loading={loading}
        submitBtnLabel="Verifier le code"
        inputs={inputs}
      />
    </Modal>
  );
};
