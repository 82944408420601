import * as Types from '../../types';

import { gql } from '@apollo/client';
export type EntityInfoFragment = { __typename?: 'Entity', id: string, name: string, code: string };

export const EntityInfoFragmentDoc = gql`
    fragment EntityInfo on Entity {
  id
  name
  code
}
    `;