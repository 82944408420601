import { Calendar } from "@components/Calendar/Calendar";
import { Box } from "@mui/material";
import React, { FC } from "react";

interface TarificationProps {
}

export const Tarification: FC<TarificationProps> = ({
}) => {
  return (
    <Box>
      <Calendar />
    </Box>
  );
};
