import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 52,
      display: "flex",
      justifyContent: "space-between",
      position: "absolute",
      zIndex: 9999,
      paddingInline: theme.spacing(2),
      bottom: 0,
      background: "#ff3333",
      width: "100%",
      color: theme.palette.common.white,
      alignItems: "center",
    },
  })
);
