import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    createBtn: {
      marginBottom: theme.spacing(2),
    },
    fileContainer: {
      maxHeight: 300
    },
    pdf: {
      width: 600,
      height: 780
    }
  })
);
