import * as Types from '../../types';

import { gql } from '@apollo/client';
import { BankAccountInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_One_Bank_AccountMutationVariables = Types.Exact<{
  input: Types.CreateOneBankAccountInput;
}>;


export type Create_One_Bank_AccountMutation = { __typename?: 'Mutation', createOneBankAccount: { __typename?: 'BankAccount', id: string, iban: string, swift: string, payoutFrequency: string, createdAt: any, updatedAt?: any | null, files: Array<{ __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null }> } };

export type Update_One_Bank_AccountMutationVariables = Types.Exact<{
  input: Types.UpdateOneBankAccountInput;
}>;


export type Update_One_Bank_AccountMutation = { __typename?: 'Mutation', updateOneBankAccount: { __typename?: 'BankAccount', id: string, iban: string, swift: string, payoutFrequency: string, createdAt: any, updatedAt?: any | null, files: Array<{ __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null }> } };


export const Create_One_Bank_AccountDocument = gql`
    mutation CREATE_ONE_BANK_ACCOUNT($input: CreateOneBankAccountInput!) {
  createOneBankAccount(input: $input) {
    ...BankAccountInfo
  }
}
    ${BankAccountInfoFragmentDoc}`;
export type Create_One_Bank_AccountMutationFn = Apollo.MutationFunction<Create_One_Bank_AccountMutation, Create_One_Bank_AccountMutationVariables>;

/**
 * __useCreate_One_Bank_AccountMutation__
 *
 * To run a mutation, you first call `useCreate_One_Bank_AccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_One_Bank_AccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneBankAccountMutation, { data, loading, error }] = useCreate_One_Bank_AccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_One_Bank_AccountMutation(baseOptions?: Apollo.MutationHookOptions<Create_One_Bank_AccountMutation, Create_One_Bank_AccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_One_Bank_AccountMutation, Create_One_Bank_AccountMutationVariables>(Create_One_Bank_AccountDocument, options);
      }
export type Create_One_Bank_AccountMutationHookResult = ReturnType<typeof useCreate_One_Bank_AccountMutation>;
export type Create_One_Bank_AccountMutationResult = Apollo.MutationResult<Create_One_Bank_AccountMutation>;
export type Create_One_Bank_AccountMutationOptions = Apollo.BaseMutationOptions<Create_One_Bank_AccountMutation, Create_One_Bank_AccountMutationVariables>;
export const Update_One_Bank_AccountDocument = gql`
    mutation UPDATE_ONE_BANK_ACCOUNT($input: UpdateOneBankAccountInput!) {
  updateOneBankAccount(input: $input) {
    ...BankAccountInfo
  }
}
    ${BankAccountInfoFragmentDoc}`;
export type Update_One_Bank_AccountMutationFn = Apollo.MutationFunction<Update_One_Bank_AccountMutation, Update_One_Bank_AccountMutationVariables>;

/**
 * __useUpdate_One_Bank_AccountMutation__
 *
 * To run a mutation, you first call `useUpdate_One_Bank_AccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_One_Bank_AccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneBankAccountMutation, { data, loading, error }] = useUpdate_One_Bank_AccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_One_Bank_AccountMutation(baseOptions?: Apollo.MutationHookOptions<Update_One_Bank_AccountMutation, Update_One_Bank_AccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_One_Bank_AccountMutation, Update_One_Bank_AccountMutationVariables>(Update_One_Bank_AccountDocument, options);
      }
export type Update_One_Bank_AccountMutationHookResult = ReturnType<typeof useUpdate_One_Bank_AccountMutation>;
export type Update_One_Bank_AccountMutationResult = Apollo.MutationResult<Update_One_Bank_AccountMutation>;
export type Update_One_Bank_AccountMutationOptions = Apollo.BaseMutationOptions<Update_One_Bank_AccountMutation, Update_One_Bank_AccountMutationVariables>;