import {
  useCreate_One_Order_ItemMutation,
  useDelete_One_Order_ItemMutation,
  useUpdate_One_Order_ItemMutation,
} from "@graphql/";
import { useApplicationContext } from "@src/context";
export const useOrderItemMutations = () => {
  const { notify } = useApplicationContext();
  const [createOneOrderItem] = useCreate_One_Order_ItemMutation({
    refetchQueries: ["ORDERS", "ORDER_ITEMS"],
    onCompleted: () => {
      notify({
        message: "L'article a été ajouté avec succès",
        type: "success",
      });
    },
    onError: () => {
      notify({
        message: "Une erreur s'est produite lors de l'ajout d'article'",
        type: "error",
      });
    },
  });

  const [updateOneOrderItem] = useUpdate_One_Order_ItemMutation({
    refetchQueries: ["ORDERS", "ORDER_ITEMS"],
    onCompleted: () => {
      notify({
        message: "L'article a été modifié avec succès",
        type: "success",
      });
    },
    onError: () => {
      notify({
        message:
          "Une erreur s'est produite lors de la moditication de l'article",
        type: "error",
      });
    },
  });

  const [deleteOneOrderItem, deletingOneOrderItem] =
    useDelete_One_Order_ItemMutation({
      refetchQueries: ["ORDERS", "ORDER_ITEMS"],
      onCompleted: (data) => {
        notify({
          message: "L'article a été supprimé avec succès",
          type: "success",
        });
      },
      onError: () => {
        notify({
          message:
            "Une erreur s'est produite lors de la suppression de l'article",
          type: "error",
        });
      },
    });

  return {
    createOneOrderItem,
    deleteOneOrderItem,
    updateOneOrderItem,
    deletingOneOrderItem,
  };
};
