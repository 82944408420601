import * as Types from '../../types';

import { gql } from '@apollo/client';
export type StripeAccountHistoryInfoFragment = { __typename?: 'StripeAccountHistory', id: string, action: string, createdAt: any, updatedAt: any, user: { __typename?: 'User', id: string, firstName: string, lastName: string } };

export const StripeAccountHistoryInfoFragmentDoc = gql`
    fragment StripeAccountHistoryInfo on StripeAccountHistory {
  id
  user {
    id
    firstName
    lastName
  }
  action
  createdAt
  updatedAt
}
    `;