import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bubbleLeft: {
      color: "#fff",
      background: theme.palette.primary.main,
      position: "relative",
      padding: 20,
      borderRadius: 10,
      maxWidth: 200,
      marginBottom: theme.spacing(3),
      "&::after": {
        content: '""',
        border: "20px solid transparent",
        position: "absolute",
        borderRightColor: theme.palette.primary.main,
        borderLeft: 0,
        left: -20,
        top: "50%",
        marginTop: -20,
      },
    },
    bubbleRight: {
      color: "#000",
      background: "#e3e3e3",
      position: "relative",
      padding: 20,
      borderRadius: 10,
      maxWidth: 200,
      marginBottom: theme.spacing(3),
      "&::after": {
        content: '""',
        border: "20px solid transparent",
        position: "absolute",
        borderLeftColor: "#e3e3e3",
        borderRight: 0,
        right: -20,
        top: "50%",
        marginTop: -20,
      },
    },
  })
);
