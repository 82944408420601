import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindDeliveryManPositionSubscriptionVariables = Types.Exact<{
  idUser: Types.Scalars['String'];
}>;


export type FindDeliveryManPositionSubscription = { __typename?: 'Subscription', findDeliveryManPosition: any };

export type ReceiveApplicationSupportSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type ReceiveApplicationSupportSubscription = { __typename?: 'Subscription', receiveApplicationSupport: any };


export const FindDeliveryManPositionDocument = gql`
    subscription findDeliveryManPosition($idUser: String!) {
  findDeliveryManPosition(idUser: $idUser)
}
    `;

/**
 * __useFindDeliveryManPositionSubscription__
 *
 * To run a query within a React component, call `useFindDeliveryManPositionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFindDeliveryManPositionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDeliveryManPositionSubscription({
 *   variables: {
 *      idUser: // value for 'idUser'
 *   },
 * });
 */
export function useFindDeliveryManPositionSubscription(baseOptions: Apollo.SubscriptionHookOptions<FindDeliveryManPositionSubscription, FindDeliveryManPositionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<FindDeliveryManPositionSubscription, FindDeliveryManPositionSubscriptionVariables>(FindDeliveryManPositionDocument, options);
      }
export type FindDeliveryManPositionSubscriptionHookResult = ReturnType<typeof useFindDeliveryManPositionSubscription>;
export type FindDeliveryManPositionSubscriptionResult = Apollo.SubscriptionResult<FindDeliveryManPositionSubscription>;
export const ReceiveApplicationSupportDocument = gql`
    subscription receiveApplicationSupport {
  receiveApplicationSupport
}
    `;

/**
 * __useReceiveApplicationSupportSubscription__
 *
 * To run a query within a React component, call `useReceiveApplicationSupportSubscription` and pass it any options that fit your needs.
 * When your component renders, `useReceiveApplicationSupportSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceiveApplicationSupportSubscription({
 *   variables: {
 *   },
 * });
 */
export function useReceiveApplicationSupportSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ReceiveApplicationSupportSubscription, ReceiveApplicationSupportSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ReceiveApplicationSupportSubscription, ReceiveApplicationSupportSubscriptionVariables>(ReceiveApplicationSupportDocument, options);
      }
export type ReceiveApplicationSupportSubscriptionHookResult = ReturnType<typeof useReceiveApplicationSupportSubscription>;
export type ReceiveApplicationSupportSubscriptionResult = Apollo.SubscriptionResult<ReceiveApplicationSupportSubscription>;