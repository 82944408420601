import * as Types from '../../types';

import { gql } from '@apollo/client';
import { StripeAccountHistoryInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Stripe_Account_HistoriesQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.StripeAccountHistoryFilter>;
  sorting?: Types.InputMaybe<Array<Types.StripeAccountHistorySort> | Types.StripeAccountHistorySort>;
}>;


export type Stripe_Account_HistoriesQuery = { __typename?: 'Query', stripeAccountHistories: { __typename?: 'StripeAccountHistoryConnection', totalCount: number, nodes: Array<{ __typename?: 'StripeAccountHistory', id: string, action: string, createdAt: any, updatedAt: any, user: { __typename?: 'User', id: string, firstName: string, lastName: string } }> } };


export const Stripe_Account_HistoriesDocument = gql`
    query STRIPE_ACCOUNT_HISTORIES($paging: OffsetPaging, $filter: StripeAccountHistoryFilter, $sorting: [StripeAccountHistorySort!]) {
  stripeAccountHistories(paging: $paging, filter: $filter, sorting: $sorting) {
    nodes {
      ...StripeAccountHistoryInfo
    }
    totalCount
  }
}
    ${StripeAccountHistoryInfoFragmentDoc}`;

/**
 * __useStripe_Account_HistoriesQuery__
 *
 * To run a query within a React component, call `useStripe_Account_HistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripe_Account_HistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripe_Account_HistoriesQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useStripe_Account_HistoriesQuery(baseOptions?: Apollo.QueryHookOptions<Stripe_Account_HistoriesQuery, Stripe_Account_HistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Stripe_Account_HistoriesQuery, Stripe_Account_HistoriesQueryVariables>(Stripe_Account_HistoriesDocument, options);
      }
export function useStripe_Account_HistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Stripe_Account_HistoriesQuery, Stripe_Account_HistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Stripe_Account_HistoriesQuery, Stripe_Account_HistoriesQueryVariables>(Stripe_Account_HistoriesDocument, options);
        }
export type Stripe_Account_HistoriesQueryHookResult = ReturnType<typeof useStripe_Account_HistoriesQuery>;
export type Stripe_Account_HistoriesLazyQueryHookResult = ReturnType<typeof useStripe_Account_HistoriesLazyQuery>;
export type Stripe_Account_HistoriesQueryResult = Apollo.QueryResult<Stripe_Account_HistoriesQuery, Stripe_Account_HistoriesQueryVariables>;