import { useApplicationContext } from "@src/context";
import { UrlParams } from "@src/types";
import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

export const useOrdersFilter = (params: UrlParams) => {
  const { connectedUser, connectedUserType } = useApplicationContext();
  const term = params.term;
  const orderRef = params.orderRef;
  const getOrdersFilter = () => {
    if (orderRef) {
      return {
        ref: { eq: orderRef },
      };
    }
    switch (connectedUserType) {
      case "INDIVIDUAL":
      case "PROFESSIONNAL":
        return _getCustomerFilter();
      case "LIVREUR":
        return _getLivreurFilter();
      case "PHARMACY":
        return _getPharmacyFilter();
      case "SUPERADMIN":
        return _getSuperadminFilter();
      case "PREPARER":
        return _getPreparerFilter();

      default:
        break;
    }
  };

  const _getCustomerFilter = () => {
    let filter = {
      idCustomer: { eq: connectedUser?.id },
      status:
        params.status === "ALL_ORDERS" ? undefined : { eq: params.status },
      datetime: {
        between: {
          lower: moment(params.from).startOf("d").toISOString(),
          upper: moment(params.to).endOf("d").toISOString(),
        },
      },
    };
    if (term) {
      filter = { ...filter, ..._getTermFilter() };
    }
    return filter;
  };

  const _getLivreurFilter = () => {
    let filter = {
      idDeliveryMan:
        params.status && ["ASSIGNED", "DELIVERED"].includes(params.status)
          ? { eq: connectedUser?.id }
          : undefined,
      status: { eq: params.status },
      datetime: {
        between: {
          lower: moment(params.from).startOf("d").toISOString(),
          upper: moment(params.to).endOf("d").toISOString(),
        },
      },
    };
    if (term) {
      filter = { ...filter, ..._getTermFilter() };
    }
    return filter;
  };

  const _getPharmacyFilter = () => {
    let filter = {
      idVendor: { eq: connectedUser?.id },
      status:
        params.status === "ALL_ORDERS"
          ? params.tab === "NEW"
            ? {
                eq: "NEW",
              }
            : { neq: "NEW" }
          : {
              eq: params.status,
            },
      datetime: {
        between: {
          lower: moment(params.from).startOf("d").toISOString(),
          upper: moment(params.to).endOf("d").toISOString(),
        },
      },
    };
    if (term) {
      filter = { ...filter, ..._getTermFilter() };
    }

    return filter;
  };

  const _getSuperadminFilter = () => {
    let filter = {
      datetime: {
        between: {
          lower: moment(params.from).startOf("d").toISOString(),
          upper: moment(params.to).endOf("d").toISOString(),
        },
      },
    };
    if (term) {
      filter = { ...filter, ..._getTermFilter() };
    }
    return filter;
  };

  const _getPreparerFilter = () => {
    let filter = {
      idVendor: { eq: connectedUser?.admin?.id },
      preparer:
        params.status === "NEW" ||
        (params.tab === "NEW" && params.status === "ALL_ORDERS")
          ? undefined
          : {
              eq: `${connectedUser?.firstName as string} ${
                connectedUser?.lastName as string
              }`,
            },
      status:
        params.status === "ALL_ORDERS"
          ? params.tab === "NEW"
            ? {
                eq: "NEW",
              }
            : { neq: "NEW" }
          : {
              eq: params.status,
            },
      datetime: {
        between: {
          lower: moment(params.from).startOf("d").toISOString(),
          upper: moment(params.to).endOf("d").toISOString(),
        },
      },
    };
    if (term) {
      filter = { ...filter, ..._getTermFilter() };
    }
    return filter;
  };

  const _customerTermFilter = () => {
    return {
      or: [
        {
          deliveryCode: {
            eq: term,
          },
        },
        // {
        //   vendor: {
        //     infoLegale: {
        //       name: {
        //         iLike: `%${term}%`,
        //       },term?: string | null
        //     },
        //   },
        // },
      ],
    };
  };

  const _pharmacyTermFilter = () => {
    return {
      or: [
        {
          customer: {
            or: [
              {
                firstName: {
                  iLike: `%${term}%`,
                },
              },
              {
                lastName: {
                  iLike: `%${term}%`,
                },
              },
            ],
          },
        },
      ],
    };
  };

  const _deliveryManTermFilter = () => {
    return {
      or: [
        {
          customer: {
            or: [
              {
                firstName: {
                  iLike: `%${term}%`,
                },
              },
              {
                lastName: {
                  iLike: `%${term}%`,
                },
              },
            ],
          },
        },
      ],
    };
  };

  const _getTermFilter = () => {
    if (!term || term.length < 3) {
      return {};
    }
    if (moment(term, "DD/MM/YYYY", true).isValid()) {
      return {
        datetime: {
          between: {
            lower: moment(term, "DD/MM/YYYY").startOf("d").toISOString(),
            upper: moment(term, "DD/MM/YYYY").endOf("d").toISOString(),
          },
        },
      };
    }
    const filter = {
      or: [
        {
          ref: {
            iLike: `%${term}%`,
          },
        },
        {
          address: {
            or: [
              {
                name: {
                  iLike: `%${term}%`,
                },
              },
              {
                city: {
                  iLike: `%${term}%`,
                },
              },
              {
                zipcode: {
                  iLike: `%${term}%`,
                },
              },
            ],
          },
        },
      ],
    };
    if (["INDIVIDUAL", "PROFESSIONNAL"].includes(connectedUserType)) {
      return { ...filter, or: [...filter.or, ..._customerTermFilter().or] };
    } else if (["PHARMACY", "PREPARER"]) {
      return { ...filter, or: [...filter.or, ..._pharmacyTermFilter().or] };
    } else if (connectedUserType === "LIVREUR") {
      return { ...filter, or: [...filter.or, ..._deliveryManTermFilter().or] };
    } else if (connectedUserType === "SERVICE_CUSTOMER") {
      return filter;
    } else {
      return filter;
    }
  };
  return { getOrdersFilter };
};
