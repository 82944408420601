import * as Types from '../../types';

import { gql } from '@apollo/client';
export type AddressInfoFragment = { __typename?: 'Address', id: string, name: string, zipcode: string, country: string, city: string, latitude: number, longitude: number, orders?: { __typename?: 'AddressOrdersConnection', totalCount: number } | null };

export const AddressInfoFragmentDoc = gql`
    fragment AddressInfo on Address {
  id
  name
  zipcode
  country
  city
  latitude
  longitude
  orders {
    totalCount
  }
}
    `;