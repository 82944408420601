import { OrderItem } from "@graphql/*";
import { List, ListItem, ListItemText } from "@mui/material";
import React, { FC } from "react";
import lodash from "lodash";
import { useStyles } from "./styles";

export interface FacturationProps {
  orderItems: OrderItem[];
}

const Facturation: FC<FacturationProps> = ({ orderItems }) => {
  const classes = useStyles();
  const totalPrice = lodash.sum(
    orderItems.map((orderItem) => orderItem.totalPrice)
  );
  const refunds = lodash.sum(orderItems.map((orderItem) => orderItem.refund));
  const billing = totalPrice - refunds;

  return (
    <List>
      <ListItem className={classes.facturation}>
        <ListItemText primary="Facturation" />
      </ListItem>
      <ListItem secondaryAction={`+${Number(totalPrice).toFixed(2)}€`}>
        <ListItemText primary="Prix des médicaments" />
      </ListItem>
      <ListItem
        secondaryAction={`${
          refunds > 0 ? `-${Number(refunds).toFixed(2)}` : 0
        }€`}
      >
        <ListItemText primary="Remboursement: Mutuelle/CPAM" />
      </ListItem>
      <ListItem
        secondaryAction={`${
          billing > 0 ? `+${Number(billing).toFixed(2)}` : 0
        }€`}
        className={classes.billing}
      >
        <ListItemText primary="Facturation Finale" />
      </ListItem>
    </List>
  );
};

export default Facturation;
