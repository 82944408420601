import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileContainer: {
      width: 600
    },
    pdf: {
      width: 600,
      height: 780
    }
  })
);
