import * as Types from '../../types';

import { gql } from '@apollo/client';
export type OpeningTimeInfoFragment = { __typename?: 'OpeningTime', id: string, days: any };

export const OpeningTimeInfoFragmentDoc = gql`
    fragment OpeningTimeInfo on OpeningTime {
  id
  days
}
    `;