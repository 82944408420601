import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Update_Many_NotificationsMutationVariables = Types.Exact<{
  input: Types.UpdateManyNotificationsInput;
}>;


export type Update_Many_NotificationsMutation = { __typename?: 'Mutation', updateManyNotifications: { __typename?: 'UpdateManyResponse', updatedCount: number } };


export const Update_Many_NotificationsDocument = gql`
    mutation UPDATE_MANY_NOTIFICATIONS($input: UpdateManyNotificationsInput!) {
  updateManyNotifications(input: $input) {
    updatedCount
  }
}
    `;
export type Update_Many_NotificationsMutationFn = Apollo.MutationFunction<Update_Many_NotificationsMutation, Update_Many_NotificationsMutationVariables>;

/**
 * __useUpdate_Many_NotificationsMutation__
 *
 * To run a mutation, you first call `useUpdate_Many_NotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Many_NotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyNotificationsMutation, { data, loading, error }] = useUpdate_Many_NotificationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_Many_NotificationsMutation(baseOptions?: Apollo.MutationHookOptions<Update_Many_NotificationsMutation, Update_Many_NotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_Many_NotificationsMutation, Update_Many_NotificationsMutationVariables>(Update_Many_NotificationsDocument, options);
      }
export type Update_Many_NotificationsMutationHookResult = ReturnType<typeof useUpdate_Many_NotificationsMutation>;
export type Update_Many_NotificationsMutationResult = Apollo.MutationResult<Update_Many_NotificationsMutation>;
export type Update_Many_NotificationsMutationOptions = Apollo.BaseMutationOptions<Update_Many_NotificationsMutation, Update_Many_NotificationsMutationVariables>;