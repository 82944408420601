import { Box, Button, ButtonProps, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import { useStyles } from "./styles";

interface MessageBoxProps {
  message: string;
  actionBtn?: ButtonProps;
}

export const MessageBox: FC<MessageBoxProps> = ({ message, actionBtn }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      sx={{ justifyContent: "center", alignItems: "center" }}
    >
      <Paper elevation={12} sx={{ height: 300, padding: 3 }}>
        <Typography
          variant="h4"
          textAlign="justify"
          className={classes.secondary}
          mb={3}
        >
          {message}
        </Typography>
        {actionBtn && (
          <Button {...actionBtn} color="secondary" variant="outlined" />
        )}
      </Paper>
    </Box>
  );
};
