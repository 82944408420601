import React, { FC } from "react";
import {
  Alert,
  Snackbar as MuiSnackbar,
} from "@mui/material";
import { CheckCircle, Warning, Error, Info } from "@mui/icons-material";

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
};

export interface SnackbarContentWrapperProps {
  className?: string;
  message?: string;
  onClose?: () => void;
  variant: keyof typeof variantIcon;
}

interface SnackBarProps {
  open?: boolean;
  type?: "success" | "warning" | "error" | "info";
  message: string;
  onClose: () => void;
}

const SnackBar: FC<SnackBarProps> = ({
  open,
  message,
  onClose,
  type = "info",
}) => {
  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={type}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
};

export default SnackBar;
