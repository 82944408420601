import * as Types from '../../types';

import { gql } from '@apollo/client';
import { TarificationInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TarificationsQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.TarificationFilter>;
  sorting?: Types.InputMaybe<Array<Types.TarificationSort> | Types.TarificationSort>;
}>;


export type TarificationsQuery = { __typename?: 'Query', tarifications: { __typename?: 'TarificationConnection', nodes: Array<{ __typename?: 'Tarification', id: string, dateDebut: any, dateFin: any, tva: number, prixKmJour: number, prixKmSoir: number, tarifMinimum: number, deliveryManPercentage: number }> } };


export const TarificationsDocument = gql`
    query TARIFICATIONS($paging: OffsetPaging, $filter: TarificationFilter, $sorting: [TarificationSort!]) {
  tarifications(paging: $paging, filter: $filter, sorting: $sorting) {
    nodes {
      ...TarificationInfo
    }
  }
}
    ${TarificationInfoFragmentDoc}`;

/**
 * __useTarificationsQuery__
 *
 * To run a query within a React component, call `useTarificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTarificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTarificationsQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useTarificationsQuery(baseOptions?: Apollo.QueryHookOptions<TarificationsQuery, TarificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TarificationsQuery, TarificationsQueryVariables>(TarificationsDocument, options);
      }
export function useTarificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TarificationsQuery, TarificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TarificationsQuery, TarificationsQueryVariables>(TarificationsDocument, options);
        }
export type TarificationsQueryHookResult = ReturnType<typeof useTarificationsQuery>;
export type TarificationsLazyQueryHookResult = ReturnType<typeof useTarificationsLazyQuery>;
export type TarificationsQueryResult = Apollo.QueryResult<TarificationsQuery, TarificationsQueryVariables>;