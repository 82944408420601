import * as Types from '../../types';

import { gql } from '@apollo/client';
export type UserHistoryInfoFragment = { __typename?: 'UserHistory', id: string, idUser: string, action: string, description: string };

export const UserHistoryInfoFragmentDoc = gql`
    fragment UserHistoryInfo on UserHistory {
  id
  idUser
  action
  description
}
    `;