import { InfoLegale } from "@graphql/*";
import { LocationCity, Place } from "@mui/icons-material";
import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import React, { FC } from "react";
import { useStyles } from "./styles";

interface VendorMobileRowProps {
  infoLegale?: InfoLegale;
  onRowClick?: (row: InfoLegale) => void;
  selected?: any;
}

export const VendorMobileRow: FC<VendorMobileRowProps> = ({
  infoLegale,
  onRowClick,
  selected,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const address = infoLegale?.addresses && infoLegale?.addresses[0];
  return (
    <Card
      className={classes.root}
      sx={{
        mb: 3,
        border:
          infoLegale?.id === selected?.id
            ? `2px solid ${theme.palette.primary.main}`
            : "unset",
      }}
      onClick={() => onRowClick && infoLegale && onRowClick(infoLegale)}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            {infoLegale?.name.split("(")[0]}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography>
            <Place sx={{ mr: 1 }} />
            {address?.address}
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {address?.zipcode}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography>
            <LocationCity sx={{ mr: 1 }} />
            {address?.city}
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {infoLegale?.name?.split("(")[1].replace(")", "")}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
