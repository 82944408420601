import {
  useCreate_One_User_HistoryMutation,
  useUpdate_One_Order_AssignmentMutation,
} from "@graphql/";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
} from "@mui/material";
import React, { ChangeEvent, FC, useState } from "react";
import { useApplicationContext } from "@src/context";
import { Modal } from "@components/Modal";

interface OrderAssignmentRejectFormProps {
  show: boolean;
  onClose: () => void;
  onCancel: () => void;
  orderRef: string;
  idOrderAssignment: string;
}

export const OrderAssignmentRejectForm: FC<OrderAssignmentRejectFormProps> = ({
  show,
  onClose,
  onCancel,
  orderRef,
  idOrderAssignment,
}) => {
  const { notify } = useApplicationContext();
  const [motif, setMotif] = useState<string>("Sur une autre livraison");
  const [rejectMessage, setRejectMessage] = useState<string>("");
  const [createOneUserHistory] = useCreate_One_User_HistoryMutation({
    onCompleted: () => {
      notify({
        message: "Votre réponse a été enrégistré",
        type: "success",
      });
      onClose();
    },
  });
  const [updateOneOrderAssignment] = useUpdate_One_Order_AssignmentMutation();

  const handleRejectFieldChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setRejectMessage(e.target.value);
    setMotif("");
  };

  const handleMotifChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMotif(e.target.value);
    setRejectMessage("");
  };

  const handleRejectDelivery = () => {
    createOneUserHistory({
      variables: {
        input: {
          userHistory: {
            action: "REJECT_APPLICATION_SUPPORT",
            description: motif || rejectMessage,
          },
        },
      },
    });
    if (idOrderAssignment) {
      updateOneOrderAssignment({
        variables: {
          input: {
            id: idOrderAssignment,
            update: {
              status: "REJECTED",
            },
          },
        },
      });
    }
    onClose();
  };

  return (
    <Modal
      open={show}
      maxWidth="xl"
      title={`Motif de refus de la prise en charge de la commande N° ${orderRef}`}
      onClose={onClose}
      disableCloseBtn
      actions={
        <>
          <Button onClick={onCancel}>Annuler</Button>
          <Button onClick={handleRejectDelivery}>Envoyer</Button>
        </>
      }
    >
      <FormControl>
        <FormLabel>Motif</FormLabel>
        <RadioGroup
          defaultValue="Sur une autre livraison"
          value={motif}
          onChange={handleMotifChange}
        >
          <FormControlLabel
            value="Sur une autre livraison"
            control={<Radio />}
            label="Sur une autre livraison"
          />
          <FormControlLabel
            value="Problème technique"
            control={<Radio />}
            label="Problème technique"
          />
          <FormControlLabel
            value="Problème de papiers"
            control={<Radio />}
            label="Problème de papiers"
          />
        </RadioGroup>
      </FormControl>
      <TextareaAutosize
        onChange={handleRejectFieldChange}
        placeholder="Autre"
        minRows={4}
        style={{ width: "100%" }}
        value={rejectMessage}
      />
    </Modal>
  );
};
