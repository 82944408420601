import * as Types from '../../types';

import { gql } from '@apollo/client';
import { OrdonnanceInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrdonnancesQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.OrdonnanceFilter>;
  sorting?: Types.InputMaybe<Array<Types.OrdonnanceSort> | Types.OrdonnanceSort>;
}>;


export type OrdonnancesQuery = { __typename?: 'Query', ordonnances: { __typename?: 'OrdonnanceConnection', totalCount: number, nodes: Array<{ __typename?: 'Ordonnance', id: string, patient?: string | null, info?: string | null, status: string, validationError?: string | null, file: { __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null } }> } };


export const OrdonnancesDocument = gql`
    query ORDONNANCES($paging: OffsetPaging, $filter: OrdonnanceFilter, $sorting: [OrdonnanceSort!]) {
  ordonnances(paging: $paging, filter: $filter, sorting: $sorting) {
    nodes {
      ...OrdonnanceInfo
    }
    totalCount
  }
}
    ${OrdonnanceInfoFragmentDoc}`;

/**
 * __useOrdonnancesQuery__
 *
 * To run a query within a React component, call `useOrdonnancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdonnancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdonnancesQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useOrdonnancesQuery(baseOptions?: Apollo.QueryHookOptions<OrdonnancesQuery, OrdonnancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdonnancesQuery, OrdonnancesQueryVariables>(OrdonnancesDocument, options);
      }
export function useOrdonnancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdonnancesQuery, OrdonnancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdonnancesQuery, OrdonnancesQueryVariables>(OrdonnancesDocument, options);
        }
export type OrdonnancesQueryHookResult = ReturnType<typeof useOrdonnancesQuery>;
export type OrdonnancesLazyQueryHookResult = ReturnType<typeof useOrdonnancesLazyQuery>;
export type OrdonnancesQueryResult = Apollo.QueryResult<OrdonnancesQuery, OrdonnancesQueryVariables>;