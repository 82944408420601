import { gql } from '@apollo/client';

export const _CLIENT_NOTIFICATION = gql`
  query _clientNotification {
    _clientNotification @client {
      open
      message
      type
    }
  }
`;
