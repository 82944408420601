import {
  Box,
  Typography,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Address } from "@pages/Settings/Address";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import {
  Address as AddressType,
  InfoLegale as InfoLegaleType,
  File as FileType,
  Order,
  Ordonnance as OrdonnanceType,
  Mutuelle as MutuelleType,
  CarteVitale as CarteVitaleType,
} from "@graphql/";
import { VendorSelection } from "./VendorSelection";
import { useOrderMutations } from "./useOrderMutations";
import { useApplicationContext } from "@src/context";
import { CarteVitale } from "./CarteVitale";
import { Mutuelle } from "./Mutuelle";
import { Ordonnance } from "./Ordonnance";

interface FileState {
  ORDONNANCE?: File | FileType | undefined;
  CARTE_VITALE?: File | FileType | undefined;
  MUTUELLE?: File | FileType | undefined;
}

export interface CreateOrderState {
  address?: AddressType;
  vendor?: InfoLegaleType;
  files?: FileState;
  datetime?: string | null;
  ordonnance?: OrdonnanceType;
  mutuelle?: MutuelleType;
  carteVitale?: CarteVitaleType;
}

interface CreateOrderStepperProps {
  order?: Order;
  onClose: () => void;
}

export const CreateOrderStepper: FC<CreateOrderStepperProps> = ({
  order,
  onClose,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState<number>(0);
  const theme = useTheme();
  const onlyMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = useState<CreateOrderState | undefined>({
    address: order?.address ?? undefined,
    vendor: order?.vendor?.infoLegale || undefined,
    files: order?.files
      ? order?.files.reduce(
          (obj, file) => ({
            ...obj,
            [file.name]: { ...file, name: file.filename },
          }),
          {}
        )
      : undefined,
    datetime: order?.datetime,
    carteVitale: order?.carteVitale || undefined,
    mutuelle: order?.mutuelle || undefined,
    ordonnance: order?.ordonnance || undefined,
  });
  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const { connectedUser } = useApplicationContext();

  const idCustomer = connectedUser?.id;

  const { createOneOrder, updateOneOrder, deleteOneOrder, loading } =
    useOrderMutations();

  const handleSubmit = async () => {
    if (order) {
      await updateOneOrder({
        variables: {
          input: {
            id: order.id,
            update: {
              idCustomer,
              idAddress: state?.address?.id,
              datetime: state?.datetime,
              idVendor: state?.vendor?.idUser,
              idCarteVitale: state?.carteVitale?.id,
              idMutuelle: state?.mutuelle?.id,
              idOrdonnance: state?.ordonnance?.id,
              status:
                order.idVendor !== state?.vendor?.idUser ? "NEW" : undefined,
              preparer:
                order.idVendor === state?.vendor?.idUser ? "" : undefined,
            },
          },
        },
      });
    } else {
      await createOneOrder({
        variables: {
          input: {
            order: {
              idCustomer,
              idCarteVitale: state?.carteVitale?.id as string,
              idMutuelle: state?.mutuelle?.id as string,
              idOrdonnance: state?.ordonnance?.id as string,
              idAddress: state?.address?.id as string,
              datetime: state?.datetime,
              idVendor: state?.vendor?.user?.id as string,
            },
          },
        },
      });
    }
    onClose();
  };

  const steps = [
    {
      label: t("order.form.chooseDeliveryAddress"),
      content: (
        <Address
          setState={setState}
          onNextStep={handleNextStep}
          disableNextStepBtn={!state?.address}
          selectedRow={state?.address}
        />
      ),
    },
    {
      label: t("order.form.choiceOfPharmacy"),
      content: (
        <VendorSelection
          state={state}
          setState={setState}
          onNextStep={handleNextStep}
          onPreviousStep={handlePreviousStep}
          disableNextStepBtn={!state?.vendor}
          selectedRow={state?.vendor}
          datetime={state?.datetime || null}
        />
      ),
    },
    {
      label: t("order.list.carteVitale.title"),
      content: (
        <CarteVitale
          state={state}
          setState={setState}
          onPreviousStep={handlePreviousStep}
          disableNextStepBtn={false}
          order={order}
          onNextStep={handleNextStep}
        />
      ),
    },
    {
      label: t("order.list.mutuelle.title"),
      content: (
        <Mutuelle
          state={state}
          setState={setState}
          onPreviousStep={handlePreviousStep}
          disableNextStepBtn={false}
          order={order}
          onNextStep={handleNextStep}
        />
      ),
    },
    {
      label: t("order.list.ordonnance.title"),
      content: (
        <Ordonnance
          state={state}
          setState={setState}
          onPreviousStep={handlePreviousStep}
          disableNextStepBtn={false}
          order={order}
          onNextStep={handleSubmit}
        />
      ),
    },
  ];
  console.log("state", state)
  return (
    <Box className={classes.root}>
      <Box className={classes.stepperBox}>
        {onlyMobile ? (
          <Typography textAlign="center" variant="h4">
            {steps[activeStep].label}
          </Typography>
        ) : (
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step) => (
              <Step key={`step-label-${step.label}`}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        <Box mt={4}>{steps[activeStep].content}</Box>
      </Box>
    </Box>
  );
};
