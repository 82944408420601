import * as Types from '../../types';

import { gql } from '@apollo/client';
import { BankAccountInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Bank_AccountsQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.BankAccountFilter>;
  sorting?: Types.InputMaybe<Array<Types.BankAccountSort> | Types.BankAccountSort>;
}>;


export type Bank_AccountsQuery = { __typename?: 'Query', bankAccounts: { __typename?: 'BankAccountConnection', nodes: Array<{ __typename?: 'BankAccount', id: string, iban: string, swift: string, payoutFrequency: string, createdAt: any, updatedAt?: any | null, files: Array<{ __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null }> }> } };


export const Bank_AccountsDocument = gql`
    query BANK_ACCOUNTS($paging: OffsetPaging, $filter: BankAccountFilter, $sorting: [BankAccountSort!]) {
  bankAccounts(paging: $paging, filter: $filter, sorting: $sorting) {
    nodes {
      ...BankAccountInfo
    }
  }
}
    ${BankAccountInfoFragmentDoc}`;

/**
 * __useBank_AccountsQuery__
 *
 * To run a query within a React component, call `useBank_AccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBank_AccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBank_AccountsQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useBank_AccountsQuery(baseOptions?: Apollo.QueryHookOptions<Bank_AccountsQuery, Bank_AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Bank_AccountsQuery, Bank_AccountsQueryVariables>(Bank_AccountsDocument, options);
      }
export function useBank_AccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Bank_AccountsQuery, Bank_AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Bank_AccountsQuery, Bank_AccountsQueryVariables>(Bank_AccountsDocument, options);
        }
export type Bank_AccountsQueryHookResult = ReturnType<typeof useBank_AccountsQuery>;
export type Bank_AccountsLazyQueryHookResult = ReturnType<typeof useBank_AccountsLazyQuery>;
export type Bank_AccountsQueryResult = Apollo.QueryResult<Bank_AccountsQuery, Bank_AccountsQueryVariables>;