import React, { FC } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { IconButton } from "@mui/material";
import { Download } from "@mui/icons-material";

interface PdfDownloaderBtnProps {
  id?: string;
  rootElementId: string;
  fileName: string;
  ignoredElementIds?: string[];
}

export const PdfDownloaderBtn: FC<PdfDownloaderBtnProps> = ({
  rootElementId,
  fileName,
  ignoredElementIds,
}) => {
  const isIgnoredElement = (element: Element) => {
    if (!ignoredElementIds) {
      return false;
    }
    return ignoredElementIds.includes(element.id);
  };
  const downloadPdfDocument = () => {
    const input = document.getElementById(rootElementId);
    if (!input) {
      throw "rootElementNotFound";
    }
    html2canvas(input, {
      ignoreElements: isIgnoredElement,
      scale: 1.2,

      windowWidth: 1440,
      windowHeight: 1024,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        format: "a4",
        orientation: "l",
        unit: "mm",
        precision: 3,
      });
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      pdf.save(fileName);
    });
  };
  return (
    <IconButton onClick={downloadPdfDocument} id="download-pdf-btn">
      <Download />
    </IconButton>
  );
};
