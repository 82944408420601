import * as Types from '../../types';

import { gql } from '@apollo/client';
import { AddressInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Create_One_AddressMutationVariables = Types.Exact<{
  input: Types.CreateOneAddressInput;
}>;


export type Create_One_AddressMutation = { __typename?: 'Mutation', createOneAddress: { __typename?: 'Address', id: string, name: string, zipcode: string, country: string, city: string, latitude: number, longitude: number, orders?: { __typename?: 'AddressOrdersConnection', totalCount: number } | null } };

export type Delete_One_AddressMutationVariables = Types.Exact<{
  input: Types.DeleteOneAddressInput;
}>;


export type Delete_One_AddressMutation = { __typename?: 'Mutation', deleteOneAddress: { __typename?: 'AddressDeleteResponse', id?: string | null } };

export type Update_One_AddressMutationVariables = Types.Exact<{
  input: Types.UpdateOneAddressInput;
}>;


export type Update_One_AddressMutation = { __typename?: 'Mutation', updateOneAddress: { __typename?: 'Address', id: string, name: string, zipcode: string, country: string, city: string, latitude: number, longitude: number, orders?: { __typename?: 'AddressOrdersConnection', totalCount: number } | null } };


export const Create_One_AddressDocument = gql`
    mutation CREATE_ONE_ADDRESS($input: CreateOneAddressInput!) {
  createOneAddress(input: $input) {
    ...AddressInfo
  }
}
    ${AddressInfoFragmentDoc}`;
export type Create_One_AddressMutationFn = Apollo.MutationFunction<Create_One_AddressMutation, Create_One_AddressMutationVariables>;

/**
 * __useCreate_One_AddressMutation__
 *
 * To run a mutation, you first call `useCreate_One_AddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_One_AddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneAddressMutation, { data, loading, error }] = useCreate_One_AddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_One_AddressMutation(baseOptions?: Apollo.MutationHookOptions<Create_One_AddressMutation, Create_One_AddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Create_One_AddressMutation, Create_One_AddressMutationVariables>(Create_One_AddressDocument, options);
      }
export type Create_One_AddressMutationHookResult = ReturnType<typeof useCreate_One_AddressMutation>;
export type Create_One_AddressMutationResult = Apollo.MutationResult<Create_One_AddressMutation>;
export type Create_One_AddressMutationOptions = Apollo.BaseMutationOptions<Create_One_AddressMutation, Create_One_AddressMutationVariables>;
export const Delete_One_AddressDocument = gql`
    mutation DELETE_ONE_ADDRESS($input: DeleteOneAddressInput!) {
  deleteOneAddress(input: $input) {
    id
  }
}
    `;
export type Delete_One_AddressMutationFn = Apollo.MutationFunction<Delete_One_AddressMutation, Delete_One_AddressMutationVariables>;

/**
 * __useDelete_One_AddressMutation__
 *
 * To run a mutation, you first call `useDelete_One_AddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_One_AddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneAddressMutation, { data, loading, error }] = useDelete_One_AddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDelete_One_AddressMutation(baseOptions?: Apollo.MutationHookOptions<Delete_One_AddressMutation, Delete_One_AddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Delete_One_AddressMutation, Delete_One_AddressMutationVariables>(Delete_One_AddressDocument, options);
      }
export type Delete_One_AddressMutationHookResult = ReturnType<typeof useDelete_One_AddressMutation>;
export type Delete_One_AddressMutationResult = Apollo.MutationResult<Delete_One_AddressMutation>;
export type Delete_One_AddressMutationOptions = Apollo.BaseMutationOptions<Delete_One_AddressMutation, Delete_One_AddressMutationVariables>;
export const Update_One_AddressDocument = gql`
    mutation UPDATE_ONE_ADDRESS($input: UpdateOneAddressInput!) {
  updateOneAddress(input: $input) {
    ...AddressInfo
  }
}
    ${AddressInfoFragmentDoc}`;
export type Update_One_AddressMutationFn = Apollo.MutationFunction<Update_One_AddressMutation, Update_One_AddressMutationVariables>;

/**
 * __useUpdate_One_AddressMutation__
 *
 * To run a mutation, you first call `useUpdate_One_AddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_One_AddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneAddressMutation, { data, loading, error }] = useUpdate_One_AddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_One_AddressMutation(baseOptions?: Apollo.MutationHookOptions<Update_One_AddressMutation, Update_One_AddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Update_One_AddressMutation, Update_One_AddressMutationVariables>(Update_One_AddressDocument, options);
      }
export type Update_One_AddressMutationHookResult = ReturnType<typeof useUpdate_One_AddressMutation>;
export type Update_One_AddressMutationResult = Apollo.MutationResult<Update_One_AddressMutation>;
export type Update_One_AddressMutationOptions = Apollo.BaseMutationOptions<Update_One_AddressMutation, Update_One_AddressMutationVariables>;