import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: theme.palette.primary.main,      
    },
    card: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: theme.palette.primary.light,
      borderColor: theme.palette.secondary.main,
      border: `2px solid ${theme.palette.secondary.main} !important`,
      borderRadius: `10px !important`,
      padding: 10,
      boxShadow: `0px 0px 10px ${theme.palette.secondary.main} !important`
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    logo: {
      width: 100,
      marginTop: theme.spacing(3),
    },
  })
);
