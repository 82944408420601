import {
  useStripe_Account_Statuses_CountQuery,
  useUsers_CountQuery,
} from "@graphql/";
import {
  AccountBalanceWallet,
  Addchart,
  DeliveryDining,
  LocalHospital,
  MedicalInformation,
  PaymentsOutlined,
  PersonSearchOutlined,
  ShoppingCart,
  SupportAgent,
} from "@mui/icons-material";
import { useApplicationContext } from "@src/context";
import { DEFAULT_PAGING_LIMIT } from "@utils/constants";
import moment from "moment";
import { useTranslation } from "react-i18next";
import React from "react";

export const useSuperadmin = () => {
  const { t } = useTranslation();
  const { connectedUser } = useApplicationContext();
  const beginningOfMonth = moment().startOf("M").format("YYYY/MM/DD");
  const endOfMonth = moment().endOf("M").format("YYYY/MM/DD");

  const userTypes = [
    { type: "INDIVIDUAL", icon: <PaymentsOutlined /> },
    { type: "PROFESSIONNAL", icon: <PaymentsOutlined /> },
    { type: "PHARMACY", icon: <LocalHospital /> },
    { type: "PREPARER", icon: <MedicalInformation /> },
    { type: "LIVREUR", icon: <DeliveryDining /> },
    { type: "SERVICE_CUSTOMER", icon: <SupportAgent /> },
  ];

  const stripeAccountTypes = [
    "NOT_CREATED",
    "COMPLETED",
    "RESTRICTED",
    "PENDING",
  ];

  const stripeAccountStatusesCountsQuery =
    useStripe_Account_Statuses_CountQuery({
      fetchPolicy: "network-only",
      skip: connectedUser?.role.code !== "SUPERADMIN",
    });

  const usersCountQuery = useUsers_CountQuery({
    fetchPolicy: "network-only",
    skip: connectedUser?.role.code !== "SUPERADMIN",
  });

  const stripeAccountStatusesCounts =
    stripeAccountStatusesCountsQuery.data?.stripeAccountStatusesCounts;

  const usersCount = usersCountQuery.data?.usersCount || {};

  const superadminItems =
    connectedUser?.role?.code === "SUPERADMIN"
      ? [
          {
            name: "SUPERADMIN_DASHBOARD",
            pathname: `/dashboard/superadmin-dashboard?status=SUPERADMIN_DASHBOARD&dateRange=week`,
            label: t("sideNav.dashboard"),
            count: 0,
            icon: <Addchart />,
          },
          {
            name: "ALL_USERS",
            pathname: "/dashboard/users?status=ALL_USERS",
            label: t("sideNav.superadmin.users.ALL_USERS"),
            count: usersCount?.ALL_USERS,
            icon: <PersonSearchOutlined />,
            subItems: userTypes.map((userType) => {
              return {
                name: userType.type,
                pathname: `/dashboard/users?status=${userType.type}&collapsed=ALL_USERS`,
                label: t(`sideNav.superadmin.users.${userType.type}`),
                count: usersCount?.[userType.type],
                icon: userType.icon,
              };
            }),
          },
          {
            name: "",
            pathname: "/dashboard/tarifications",
            label: t("sideNav.superadmin.PRICING"),
            count: 0,
            icon: <PaymentsOutlined />,
          },
          {
            name: "ORDERS",
            pathname: `/dashboard/orders?status=ORDERS&limit=${DEFAULT_PAGING_LIMIT}&offset=0&from=${beginningOfMonth}&to=${endOfMonth}`,
            label: t("sideNav.superadmin.ORDERS"),
            count: 0,
            icon: <ShoppingCart />,
          },
          {
            name: "ALL_STRIPE_ACCOUNTS",
            pathname:
              "/dashboard/stripe-accounts?status=NOT_CREATED&collapsed=ALL_STRIPE_ACCOUNTS",
            label: t("sideNav.superadmin.stripeAccounts.ALL_STRIPE_ACCOUNTS"),
            count: 0,
            icon: <AccountBalanceWallet />,
            subItems: stripeAccountTypes.map((stripeAccountType) => {
              return {
                name: stripeAccountType,
                pathname: `/dashboard/stripe-accounts?status=${stripeAccountType}&collapsed=ALL_STRIPE_ACCOUNTS`,
                label: t(
                  `sideNav.superadmin.stripeAccounts.${stripeAccountType}`
                ),
                count: stripeAccountStatusesCounts?.[stripeAccountType] || 0,
              };
            }),
          },
        ]
      : [];
  return { superadminItems };
};
