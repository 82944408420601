import React, { FC } from "react";
import { ApolloClient, useApolloClient, useQuery } from "@apollo/client";
import { _CLIENT_NOTIFICATION } from "./_clientNotification";
import { SnackBar } from "../components/SnackBar";

export interface Notification {
  open?: boolean;
  type?: "success" | "warning" | "error" | "info";
  message?: string;
}

const useNotification = (
  providedClient?: ApolloClient<object>
): [Notification, (notification: Notification) => void] => {
  const contextClient = useApolloClient();
  const client = providedClient ?? contextClient;
  const { data } = useQuery<{
    _clientNotification: Notification;
  }>(_CLIENT_NOTIFICATION, {
    client,
  });

  const notify = (notification: Notification) => {
    client.writeQuery({
      query: _CLIENT_NOTIFICATION,
      data: {
        _clientNotification: notification,
      },
    });
  };

  return [data?._clientNotification || { open: false }, notify];
};

interface NotificationSnackBarProps {
  client?: ApolloClient<object>;
}

export const NotificationSnackBar: FC<NotificationSnackBarProps> = ({
  client,
}) => {
  const [notification, notify] = useNotification(client);

  return (
    <SnackBar
      type={notification?.type}
      open={notification.open}
      message={notification.message || ""}
      onClose={() => notify({ ...notification, open: false })}
    />
  );
};

export const useDisplayNotification = (
  client?: ApolloClient<object>
): ((notification: Omit<Notification, "open">) => void) => {
  const [, notify] = useNotification(client);

  return (notification) => notify({ ...notification, open: true });
};
