import { Box, Card, TextField } from "@mui/material";
import {
  Autocomplete,
  DirectionsRenderer,
  DirectionsService,
  DistanceMatrixService,
  GoogleMap,
  MarkerF,
} from "@react-google-maps/api";
import React, { FC, useEffect, useState } from "react";

interface MapProps {
  id: string;
  onSelect?: (place: google.maps.places.PlaceResult) => void;
  selectedPlace?: google.maps.places.PlaceResult | null;
  withAutoComplete?: boolean;
  depart?: google.maps.LatLngLiteral;
  arrive?: google.maps.LatLngLiteral;
  onDistanceMatrixResponse?: (
    response: google.maps.DistanceMatrixResponse | null,
    status: google.maps.DistanceMatrixStatus
  ) => void;
  latLng?: google.maps.LatLngLiteral;
}

const containerStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = {
  lat: -3.745,
  lng: -38.523,
};

const Map: FC<MapProps> = ({
  withAutoComplete,
  id,
  selectedPlace,
  onSelect,
  depart,
  arrive,
  onDistanceMatrixResponse,
  latLng,
}) => {
  const [textFieldValue, setTextFieldValue] = useState<string>("");
  const [center, setCenter] =
    useState<google.maps.LatLngLiteral>(defaultCenter);
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);
  const [directionsResult, setDirectionsResult] =
    useState<google.maps.DirectionsResult | null>(null);
  const [distanceMatrixResult, setDistanceMatrixResult] =
    useState<google.maps.DistanceMatrixResponse | null>(null);

  const onPlaceChanged = () => {
    const place = autocomplete?.getPlace();
    if (place) {
      onSelect && onSelect(place);
      place.geometry?.location && setCenter(place.geometry.location.toJSON());
    }
  };

  useEffect(() => {
    if (selectedPlace) {
      setTextFieldValue(selectedPlace?.formatted_address || "");
      selectedPlace.geometry?.location &&
        setCenter(selectedPlace.geometry.location.toJSON());
    } else {
      setCenter(defaultCenter);
      setTextFieldValue("");
    }
  }, [id, selectedPlace, map, marker, autocomplete]);

  useEffect(() => {
    if (latLng) {
      setCenter(latLng);
    }
  }, [latLng, map, marker, id]);

  return (
    <>
      {withAutoComplete && (
        <Box marginBottom={2}>
          <Autocomplete
            onLoad={(autocomplete) => setAutocomplete(autocomplete)}
            onPlaceChanged={onPlaceChanged}
            restrictions={{ country: ["fr"] }}
            types={["premise", "route"]}
          >
            <TextField
              value={textFieldValue}
              onChange={(e) => setTextFieldValue(e.target.value)}
            />
          </Autocomplete>
        </Box>
      )}
      <Card sx={{ width: "100%" }}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          onLoad={(map) => setMap(map)}
          id={id}
        >
          {map && (
            <>
              {(selectedPlace || latLng) && (
                <MarkerF
                  onLoad={(marker) => setMarker(marker)}
                  position={center}
                />
              )}
              {depart && arrive && !withAutoComplete && (
                <>
                  {directionsResult && (
                    <DirectionsRenderer directions={directionsResult} />
                  )}
                  {!directionsResult && (
                    <DirectionsService
                      options={{
                        origin: depart,
                        destination: arrive,
                        travelMode: google.maps.TravelMode.DRIVING,
                      }}
                      callback={(response, status) => {
                        if (response) {
                          setDirectionsResult(response);
                        }
                      }}
                    />
                  )}
                  {!distanceMatrixResult && (
                    <DistanceMatrixService
                      options={{
                        destinations: [arrive],
                        origins: [depart],
                        travelMode: google.maps.TravelMode.DRIVING,
                      }}
                      callback={(response, status) => {
                        onDistanceMatrixResponse &&
                          onDistanceMatrixResponse(response, status);
                        setDistanceMatrixResult(response);
                      }}
                    />
                  )}
                </>
              )}
            </>
          )}
        </GoogleMap>
      </Card>
    </>
  );
};

export { Map };
