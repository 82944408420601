import { ApolloError } from "@apollo/client/errors";

interface ExtensionsInterface {
  originalError?: {
    message: string[];
  };
}
export const formatErrorMessage = (e: ApolloError) => {
  const badRequestErrors = e.graphQLErrors.filter(
    (error) => error.message === "Bad Request Exception"
  );
  if (badRequestErrors.length) {
    return badRequestErrors
      .map((error) => {
        return (
          error.extensions as ExtensionsInterface
        ).originalError?.message.map((message) =>
          message.replace("input.input.", "").replace("input.update.", "")
        );
      })
      .join(",");
  }
  return "Une erreur s'est produite";
};
