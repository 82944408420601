import {
  useCreate_One_Info_LegaleMutation,
  useUpdate_One_Info_LegaleMutation,
} from "@graphql/";
import { useApplicationContext } from "@src/context";
import { useTranslation } from "react-i18next";

export const useInfoLegaleMutations = () => {
  const { t } = useTranslation();

  const { notify } = useApplicationContext();
  const [createOneInfoLegale, creatingOneInfoLegale] =
    useCreate_One_Info_LegaleMutation({
      refetchQueries: ["VERIFY_BEARER_TOKEN"],
      onCompleted: () => {
        notify({
          message: t("infoLegales.success.create"),
          type: "success",
        });
      },
      onError: (err) => {
        notify({
          message: `Une erreur s'est produite lors de l'ajout de vos informations: ${
            err.message.includes("SIREN_CODE_IS_INVALID")
              ? "Le SIREN est invalide"
              : ""
          }`,
          type: "error",
        });
      },
    });

  const [updateOneInfoLegale, updatingOneInfoLegale] =
    useUpdate_One_Info_LegaleMutation({
      refetchQueries: ["VERIFY_BEARER_TOKEN"],
      onCompleted: () => {
        notify({
          message: t("infoLegales.success.update"),
          type: "success",
        });
      },
      onError: (err) => {
        notify({
          message: `Une erreur s'est produite lors de la moditication de vos informations: ${
            err.message.includes("SIREN_CODE_IS_INVALID")
              ? "Le SIREN est invalide"
              : ""
          }`,
          type: "error",
        });
      },
    });

  const loading =
    creatingOneInfoLegale.loading || updatingOneInfoLegale.loading;

  return {
    createOneInfoLegale,
    updateOneInfoLegale,
    loading,
  };
};
