import { Address } from "@pages/Settings/Address";
import { BankAccount } from "@pages/Settings/BankAccount";
import { InfoLegale } from "@pages/Settings/InfoLegale";
import { OpeningTime } from "@pages/Settings/OpeningTime";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { Vehicle } from "./Vehicle";

export const Settings: FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const page = query.get("status") as keyof typeof settings;
  const settings = {
    ADDRESS: <Address />,
    BANK_ACCOUNT: <BankAccount/>,
    OPENING_TIME: <OpeningTime/>,
    INFO_LEGALE: <InfoLegale/>,
    VEHICLE: <Vehicle/>
  };
  return <div>{settings[page]}</div>;
};
