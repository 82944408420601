import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Regenerate_TokenMutationVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type Regenerate_TokenMutation = { __typename?: 'Mutation', regenerateToken: string };

export type Generate_User_TokenMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type Generate_User_TokenMutation = { __typename?: 'Mutation', generateUserToken: boolean };


export const Regenerate_TokenDocument = gql`
    mutation REGENERATE_TOKEN($token: String!) {
  regenerateToken(token: $token)
}
    `;
export type Regenerate_TokenMutationFn = Apollo.MutationFunction<Regenerate_TokenMutation, Regenerate_TokenMutationVariables>;

/**
 * __useRegenerate_TokenMutation__
 *
 * To run a mutation, you first call `useRegenerate_TokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerate_TokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateTokenMutation, { data, loading, error }] = useRegenerate_TokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRegenerate_TokenMutation(baseOptions?: Apollo.MutationHookOptions<Regenerate_TokenMutation, Regenerate_TokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Regenerate_TokenMutation, Regenerate_TokenMutationVariables>(Regenerate_TokenDocument, options);
      }
export type Regenerate_TokenMutationHookResult = ReturnType<typeof useRegenerate_TokenMutation>;
export type Regenerate_TokenMutationResult = Apollo.MutationResult<Regenerate_TokenMutation>;
export type Regenerate_TokenMutationOptions = Apollo.BaseMutationOptions<Regenerate_TokenMutation, Regenerate_TokenMutationVariables>;
export const Generate_User_TokenDocument = gql`
    mutation GENERATE_USER_TOKEN($email: String!) {
  generateUserToken(email: $email)
}
    `;
export type Generate_User_TokenMutationFn = Apollo.MutationFunction<Generate_User_TokenMutation, Generate_User_TokenMutationVariables>;

/**
 * __useGenerate_User_TokenMutation__
 *
 * To run a mutation, you first call `useGenerate_User_TokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerate_User_TokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUserTokenMutation, { data, loading, error }] = useGenerate_User_TokenMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGenerate_User_TokenMutation(baseOptions?: Apollo.MutationHookOptions<Generate_User_TokenMutation, Generate_User_TokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Generate_User_TokenMutation, Generate_User_TokenMutationVariables>(Generate_User_TokenDocument, options);
      }
export type Generate_User_TokenMutationHookResult = ReturnType<typeof useGenerate_User_TokenMutation>;
export type Generate_User_TokenMutationResult = Apollo.MutationResult<Generate_User_TokenMutation>;
export type Generate_User_TokenMutationOptions = Apollo.BaseMutationOptions<Generate_User_TokenMutation, Generate_User_TokenMutationVariables>;