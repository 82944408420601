import * as Types from '../../types';

import { gql } from '@apollo/client';
import { OrderItemInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Order_ItemsQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.OrderItemFilter>;
  sorting?: Types.InputMaybe<Array<Types.OrderItemSort> | Types.OrderItemSort>;
}>;


export type Order_ItemsQuery = { __typename?: 'Query', orderItems: { __typename?: 'OrderItemConnection', totalCount: number, nodes: Array<{ __typename?: 'OrderItem', id: string, idOrder: string, libelle: string, description: string, quantity: number, unitPrice: number, totalPrice: number, refund: number, createdAt: any, updatedAt: any }> } };


export const Order_ItemsDocument = gql`
    query ORDER_ITEMS($paging: OffsetPaging, $filter: OrderItemFilter, $sorting: [OrderItemSort!]) {
  orderItems(paging: $paging, filter: $filter, sorting: $sorting) {
    nodes {
      ...OrderItemInfo
    }
    totalCount
  }
}
    ${OrderItemInfoFragmentDoc}`;

/**
 * __useOrder_ItemsQuery__
 *
 * To run a query within a React component, call `useOrder_ItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrder_ItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrder_ItemsQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useOrder_ItemsQuery(baseOptions?: Apollo.QueryHookOptions<Order_ItemsQuery, Order_ItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Order_ItemsQuery, Order_ItemsQueryVariables>(Order_ItemsDocument, options);
      }
export function useOrder_ItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Order_ItemsQuery, Order_ItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Order_ItemsQuery, Order_ItemsQueryVariables>(Order_ItemsDocument, options);
        }
export type Order_ItemsQueryHookResult = ReturnType<typeof useOrder_ItemsQuery>;
export type Order_ItemsLazyQueryHookResult = ReturnType<typeof useOrder_ItemsLazyQuery>;
export type Order_ItemsQueryResult = Apollo.QueryResult<Order_ItemsQuery, Order_ItemsQueryVariables>;