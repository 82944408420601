import { SupportTicket } from "@graphql/*";
import { Add, AddCircle, Mail, RemoveCircle } from "@mui/icons-material";
import {
  Card,
  Box,
  Typography,
  CardContent,
  Badge,
  IconButton,
} from "@mui/material";
import { useApplicationContext } from "@src/context";
import React, { FC, useState } from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "@utils/getUserFullName";
import { getLongDateAndTime } from "@utils/dates";

interface SupportTicketMobileRowProps {
  supportTicket?: SupportTicket;
  actions?: (row: SupportTicket) => JSX.Element;
}

export const SupportTicketMobileRow: FC<SupportTicketMobileRowProps> = ({
  supportTicket,
  actions,
}) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState<boolean>(false);

  const { messagerie } = useApplicationContext();
  const classes = useStyles();

  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <Card className={classes.root} sx={{ mb: 3 }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            {supportTicket?.ref}
          </Typography>
          {supportTicket && actions && actions(supportTicket)}
        </Box>
        {showMore && (
          <Box>
            <Box sx={{ mb: 1 }}>
              <Typography>
                {t("supportTicket.list.label.customer")}:{" "}
                {getUserFullName(supportTicket?.customer)}
              </Typography>
            </Box>
            <Box sx={{ mb: 1 }}>
              <Typography>
                {t("supportTicket.list.label.dateAdded")}:{" "}
                {getLongDateAndTime(supportTicket?.createdAt)}
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography>{supportTicket?.order?.ref || '-'}</Typography>
          <Badge badgeContent={supportTicket?.unreadSupportTicketMessageCount}>
            <IconButton
              onClick={() => messagerie.setSupportTicket(supportTicket)}
            >
              <Mail />
            </IconButton>
          </Badge>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography>{supportTicket?.subject}</Typography>
          <IconButton onClick={handleShowMore}>
            {!showMore ? (
              <AddCircle color="success" />
            ) : (
              <RemoveCircle color="error" />
            )}
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};
