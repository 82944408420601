import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useState } from "react";
import { useOpeningTimeMutations } from "./useOpeningTimeMutations";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { useOpening_TimesQuery } from "@graphql/";
import { usePagination } from "@src/hooks";
import { Modal } from "@components/Modal";
import { useTranslation } from "react-i18next";

interface OpeningTimeProps {}

interface Day {
  value: number;
  label: string;
  closed: boolean;
  open: boolean;
  openingTime: string;
  closingTime: string;
}

export const OpeningTime: FC<OpeningTimeProps> = ({}) => {
  const { t } = useTranslation();

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [daysToApply, setDaysToApply] = useState<Day[]>([]);
  const [day, setDay] = useState({
    closed: true,
    open: false,
    openingTime: "",
    closingTime: "",
  });

  const [days, setDays] = useState<Day[]>([
    {
      value: 0,
      label: t("openingTimes.days.0"),
      closed: true,
      open: false,
      openingTime: "",
      closingTime: "",
    },
    {
      value: 1,
      label: t("openingTimes.days.1"),
      closed: true,
      open: false,
      openingTime: "",
      closingTime: "",
    },
    {
      value: 2,
      label: t("openingTimes.days.2"),
      closed: true,
      open: false,
      openingTime: "",
      closingTime: "",
    },
    {
      value: 3,
      label: t("openingTimes.days.3"),
      closed: true,
      open: false,
      openingTime: "",
      closingTime: "",
    },
    {
      value: 4,
      label: t("openingTimes.days.4"),
      closed: true,
      open: false,
      openingTime: "",
      closingTime: "",
    },
    {
      value: 5,
      label: t("openingTimes.days.5"),
      closed: true,
      open: false,
      openingTime: "",
      closingTime: "",
    },
    {
      value: 6,
      label: t("openingTimes.days.6"),
      closed: true,
      open: false,
      openingTime: "",
      closingTime: "",
    },
  ]);

  const pagination = usePagination();
  useOpening_TimesQuery({
    variables: {
      paging: pagination.offsetPaging,
    },
    onCompleted: (data) => {
      if (data.openingTimes.totalCount) {
        setDays(
          data.openingTimes.nodes[0].days.map((el: any) => ({
            value: el.day,
            label: el.label,
            closed: !el.isOpen,
            open: el.isOpen,
            openingTime: el.times.length ? el.times[0].openingTime : "",
            closingTime: el.times.length ? el.times[0].closingTime : "",
          }))
        );
      }
      pagination.setTotalCount(data.openingTimes.totalCount);
    },
  });

  const { createOneOpeningTime } = useOpeningTimeMutations();

  const handleSubmitOpeningTime = async () => {
    await createOneOpeningTime({
      variables: {
        input: {
          openingTime: {
            days: days.map((day) => ({
              day: day.value,
              value: day.value,
              label: day.label,
              isOpen: day.open,
              times:
                day.openingTime && day.closingTime
                  ? [
                      {
                        openingTime: day.openingTime,
                        closingTime: day.closingTime,
                      },
                    ]
                  : [],
            })),
          },
        },
      },
    });
  };

  const handleOpenForm = (day: Day) => {
    setDaysToApply([day]);
    setDay({
      closed: day.closed,
      open: day.open,
      openingTime: day.openingTime,
      closingTime: day.closingTime,
    });
    setOpenForm(!openForm);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSave = () => {
    setDays((prev) =>
      prev.map((el) => {
        const currentDay = daysToApply.find((day) => day.value === el.value);
        return currentDay ? { ...currentDay, ...day } : el;
      })
    );
    handleCloseForm();
  };

  const toggleDayToApply = (currentDay: Day) => {
    if (daysToApply.some((el) => el.value === currentDay.value)) {
      setDaysToApply((prev) =>
        prev
          .filter((el) => el.value != currentDay.value)
          .map((d) => ({ ...d, ...day }))
      );
    } else {
      setDaysToApply((prev) =>
        [...prev, currentDay].map((d) => ({ ...d, ...day }))
      );
    }
  };

  return (
    <Grid container display="flex" justifyContent="center">
      <Grid item xs={12} sm={6}>
        <Box>
          <Typography variant="h4" textAlign="center" color="primary" mb={2}>
            {t("openingTimes.title")}
          </Typography>
          <List>
            {days.map((day) => (
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => handleOpenForm(day)}
                  >
                    <Edit />
                  </IconButton>
                }
                key={`day-${day.value}`}
              >
                <ListItemText>
                  <Typography>{day.label}</Typography>
                </ListItemText>
                <ListItemText>
                  {day.openingTime
                    ? `${moment(day.openingTime).format("HH:mm")} - ${moment(
                        day.closingTime
                      ).format("HH:mm")}`
                    : day.closed
                    ? t("openingTimes.closed")
                    : t("openingTimes.opened")}
                </ListItemText>
              </ListItem>
            ))}
          </List>
          <Divider sx={{ my: 4 }} />
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}>
            <Button variant="contained" onClick={handleSubmitOpeningTime}>
              {t("commons.save")}
            </Button>
          </Box>
          <Modal
            open={openForm}
            maxWidth="md"
            title={t("openingTimes.edit")}
            actions={
              <>
                <Button onClick={handleCloseForm}>
                  {t("commons.cancel")}
                </Button>
                <Button variant="contained" onClick={handleSave}>
                  {t("commons.save")}
                </Button>
              </>
            }
            onClose={handleCloseForm}
          >
            <Grid container spacing={2} mb={3}>
              {days.map((day) => {
                return (
                  <Grid item key={`day-${day.value}`}>
                    <Button
                      variant={
                        daysToApply.some((el) => el.value === day.value)
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => toggleDayToApply(day)}
                    >
                      {day.label}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
            <FormControl>
              <RadioGroup
                value={day.open ? "open" : day.closed ? "closed" : "set"}
                onChange={(e) => {
                  setDay({
                    open: e.target.value === "open",
                    closed: e.target.value === "closed",
                    openingTime: "",
                    closingTime: "",
                  });
                }}
              >
                <FormControlLabel
                  value="open"
                  control={<Radio />}
                  label={t("openingTimes.opened")}
                />
                <FormControlLabel
                  value="closed"
                  control={<Radio />}
                  label={t("openingTimes.closed")}
                />
                <FormControlLabel
                  value="set"
                  control={<Radio />}
                  label={t("openingTimes.toBeDefined")}
                />
              </RadioGroup>
              {!day.closed && !day.open && (
                <FormGroup row>
                  <TimePicker
                    label={t("openingTimes.startHour")}
                    ampm={false}
                    value={day.openingTime}
                    onChange={(value) => {
                      setDay({
                        open: false,
                        closed: false,
                        openingTime: value || "",
                        closingTime: day.closingTime,
                      });
                    }}
                    disabled={day.closed}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ marginRight: 3 }} />
                    )}
                  />
                  <TimePicker
                    ampm={false}
                    label={t("openingTimes.endHour")}
                    value={day.closingTime}
                    onChange={(value) => {
                      setDay({
                        open: false,
                        closed: false,
                        openingTime: day.openingTime,
                        closingTime: value || "",
                      });
                    }}
                    minTime={day.openingTime}
                    disabled={day.closed}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormGroup>
              )}
            </FormControl>
          </Modal>
        </Box>
      </Grid>
    </Grid>
  );
};
