import * as Types from '../../types';

import { gql } from '@apollo/client';
import { FileInfoFragmentDoc } from '../../File/__generated__/fragment';
export type BankAccountInfoFragment = { __typename?: 'BankAccount', id: string, iban: string, swift: string, payoutFrequency: string, createdAt: any, updatedAt?: any | null, files: Array<{ __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null }> };

export const BankAccountInfoFragmentDoc = gql`
    fragment BankAccountInfo on BankAccount {
  id
  iban
  swift
  payoutFrequency
  files {
    ...FileInfo
  }
  createdAt
  updatedAt
}
    ${FileInfoFragmentDoc}`;