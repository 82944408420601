import { useTranslation } from "react-i18next";
import { FormWrapper } from "@components/Form/Form";
import { SupportTicket } from "@graphql/";

import { InputProps } from "@src/types";
import * as yup from "yup";
import React, { FC } from "react";

interface SupportTicketOrderFormProps {
  supportTicket?: SupportTicket;
  onSubmit: (state: any, id?: string) => void;
  onCancel: () => void;
  orders: {
    __typename?: "Order";
    id: string;
    ref: string;
  }[];
  loading: boolean
}

export const SupportTicketOrderForm: FC<SupportTicketOrderFormProps> = ({
  supportTicket,
  onSubmit,
  onCancel,
  orders,
  loading
}) => {
  const { t } = useTranslation();

  const initialCreateInput = {
    subject: "",
    description: "",
    idOrder: "",
  };
  const ordersSelectOptions = orders.map((order) => ({
    label: order.ref,
    value: order.id,
  }));

  const schema = yup
    .object({
      subject: yup.string().required(t("supportTicket.form.errors.subjectRequired")),
      idOrder: yup.string().required(t("supportTicket.form.errors.refRequired")),
    })
    .required();

  const inputs: InputProps[] = [
    {
      name: "subject",
      label: t("supportTicket.form.subject"),
      type: "text",
      placeholder: t("supportTicket.form.subject"),
    },
    {
      name: "description",
      label: t("supportTicket.form.description"),
      type: "textarea",
      placeholder: t("supportTicket.form.description"),
    },
    {
      name: "idOrder",
      label: t("supportTicket.form.orderRef"),
      type: "select",
      options: ordersSelectOptions,
      value: supportTicket?.idOrder,
    },
  ];

  const handleSubmit = (state: any, id?: string) => {
    onSubmit(state, id);
  };
  return (
    <FormWrapper
      initialCreateInput={initialCreateInput}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      schema={schema}
      loading={loading}
      submitBtnLabel={
        supportTicket ? t("commons.edit") : t("commons.add")
      }
      inputs={inputs}
      element={supportTicket}
    />
  );
};
