import { ChipSelect } from "@components/ChipSelect";
import { UserMobileRow } from "@components/MobileTableRows/UserMobileRow";
import { MoreHorizMenu } from "@components/MoreHorizMenu";
import { Column, Table } from "@components/Table";
import { UserForm } from "@components/UserForm";
import { User, useUsersQuery } from "@graphql/";
import { Box, Button, Grid } from "@mui/material";
import { Role, roles, userStatuses } from "@src/constants";
import { useApplicationContext } from "@src/context";
import { usePagination, useUsersMutations } from "@src/hooks";
import moment from "moment";
import React, { FC, useState } from "react";
export const Users: FC = () => {
  const { connectedUser } = useApplicationContext();
  const pagination = usePagination();
  const usersQuery = useUsersQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      paging: pagination.offsetPaging,
      filter: {
        idAdmin: { eq: connectedUser?.id },
        idRole: { neq: connectedUser?.idRole },
      },
    },
    skip: !connectedUser?.id,
    onCompleted: (data) => {
      pagination.setTotalCount(data.users.totalCount);
    },
  });
  const users = usersQuery.data?.users.nodes || [];
  const { updateUser } = useUsersMutations();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const handleUpdateUserStatus = (value: string, id: string) => {
    updateUser({ variables: { input: { id, update: { status: value } } } });
  };

  const actions = (row: User) => {
    const moreHorizMenus = [
      {
        label: "Modifier",
        value: "UPDATE",
      },
      {
        label: "Suspendre",
        value: "SUSPEND",
        disabled: row?.status === "SUSPENDED",
      },
      {
        label: "Activer",
        value: "ACTIVATE",
        disabled: row?.status !== "SUSPENDED",
      },
    ];
    const handleSelect = (selected: string) => {
      setUser({
        ...row,
        birthDate: moment(row.birthDate).format("YYYY-MM-DD"),
      });
      switch (selected) {
        case "UPDATE":
          setShowForm(true);
          break;
        case "SUSPEND":
          updateUser({
            variables: {
              input: { id: row.id, update: { status: "SUSPENDED" } },
            },
          })
          break;
        case "ACTIVATE":
          updateUser({
            variables: {
              input: { id: row.id, update: { status: "EMAIL_VERIFIED" } },
            },
          })
          break;
      }
    };
    return (
      <MoreHorizMenu
        disabled={!moreHorizMenus.length}
        items={moreHorizMenus}
        onSelect={handleSelect}
      />
    );
  };

  const columns: Column[] = [
    {
      label: "Nom",
      name: "firstName",
    },
    {
      label: "Prénom",
      name: "lastName",
    },
    {
      label: "Email",
      name: "email",
    },
    {
      label: "Role",
      name: "",
      renderer: (row: User) => {
        return roles[row.role.code as Role];
      },
    },
    {
      label: "Status",
      name: "status",
      renderer: (row: User) => {
        const handleChange = (value: string) => {
          handleUpdateUserStatus(value, row.id);
        };
        return (
          <ChipSelect
            menuItems={userStatuses}
            onChange={handleChange}
            value={row.status}
            width={230}
            disabled={true}
          />
        );
      },
    },
    {
      label: "Actions",
      name: "",
      renderer: actions,
    },
  ];

  const handleCloseForm = () => {
    setUser(undefined);
    setShowForm(false);
  };

  return (
    <Box>
      <Table
        columns={columns}
        data={users}
        title="Liste des utilisateurs"
        pagination={pagination}
        toolbar={
          <Grid container spacing={2} justifyContent="end">
            <Grid item xs={12} lg={3}>
              <Button
                variant="contained"
                onClick={() => setShowForm(true)}
                fullWidth
              >
                Ajouter un utilisateur
              </Button>
            </Grid>
          </Grid>
        }
        mobileTableRow={<UserMobileRow actions={actions} user={user} />}
        model="user"
      />
      {showForm && (
        <UserForm open={showForm} onClose={handleCloseForm} user={user} />
      )}
    </Box>
  );
};
