import * as Types from '../../types';

import { gql } from '@apollo/client';
export type OrderHistoryInfoFragment = { __typename?: 'OrderHistory', id: string, idOrder: string, action: string, createdAt: any, updatedAt: any };

export const OrderHistoryInfoFragmentDoc = gql`
    fragment OrderHistoryInfo on OrderHistory {
  id
  idOrder
  action
  createdAt
  updatedAt
}
    `;