import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 150,
      padding: 24,
      [theme.breakpoints.down("sm")]: {
        height: 100,
        "& .MuiTypography-root": {
          fontSize: "1rem",
        },
      },
    },
    arrowIcon: {
      [theme.breakpoints.down("sm")]: {
        "& .MuiSvgIcon-root": {
          fontSize: 20,
        },
      },
    },
  })
);
