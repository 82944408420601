import { Box, Card, Typography } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { useStyles } from "./styles";

interface StatsCardProps {
  label: string;
  value: string;
  icon: ReactNode;
  color: string;
}

export const StatsCard: FC<StatsCardProps> = ({
  label,
  value,
  color,
  icon,
}) => {
  const classes = useStyles();
  return (
    <Card
      elevation={0}
      className={classes.root}
      sx={{
        background: color,
        color: "#fff",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        paddingX={3}
      >
        <Box>
          <Typography variant="h5" fontSize="1.25em">
            {label}
          </Typography>
          {icon}
        </Box>
        <Typography variant="h5" fontSize="2em">
          {value}
        </Typography>
      </Box>
    </Card>
  );
};
