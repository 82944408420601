import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: theme.palette.primary.main,
    },
    card: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: theme.palette.primary.light,
      border: "3px solid",
      borderColor: theme.palette.secondary.main,
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    logo: {
      width: 100,
      marginTop: theme.spacing(3),
    },
  })
);
