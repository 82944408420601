import * as Types from '../../types';

import { gql } from '@apollo/client';
import { CarteVitaleInfoFragmentDoc } from './fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Carte_VitalesQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.CarteVitaleFilter>;
  sorting?: Types.InputMaybe<Array<Types.CarteVitaleSort> | Types.CarteVitaleSort>;
}>;


export type Carte_VitalesQuery = { __typename?: 'Query', carteVitales: { __typename?: 'CarteVitaleConnection', totalCount: number, nodes: Array<{ __typename?: 'CarteVitale', id: string, patient?: string | null, number?: string | null, status: string, validationError?: string | null, createdAt: any, updatedAt?: any | null, file: { __typename?: 'File', id: string, idType?: string | null, idUser: string, filename: string, mimeType: string, name: string, status: string, type: string, url: string, createdAt: any, updatedAt?: any | null } }> } };


export const Carte_VitalesDocument = gql`
    query CARTE_VITALES($paging: OffsetPaging, $filter: CarteVitaleFilter, $sorting: [CarteVitaleSort!]) {
  carteVitales(paging: $paging, filter: $filter, sorting: $sorting) {
    nodes {
      ...CarteVitaleInfo
    }
    totalCount
  }
}
    ${CarteVitaleInfoFragmentDoc}`;

/**
 * __useCarte_VitalesQuery__
 *
 * To run a query within a React component, call `useCarte_VitalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarte_VitalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarte_VitalesQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCarte_VitalesQuery(baseOptions?: Apollo.QueryHookOptions<Carte_VitalesQuery, Carte_VitalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Carte_VitalesQuery, Carte_VitalesQueryVariables>(Carte_VitalesDocument, options);
      }
export function useCarte_VitalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Carte_VitalesQuery, Carte_VitalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Carte_VitalesQuery, Carte_VitalesQueryVariables>(Carte_VitalesDocument, options);
        }
export type Carte_VitalesQueryHookResult = ReturnType<typeof useCarte_VitalesQuery>;
export type Carte_VitalesLazyQueryHookResult = ReturnType<typeof useCarte_VitalesLazyQuery>;
export type Carte_VitalesQueryResult = Apollo.QueryResult<Carte_VitalesQuery, Carte_VitalesQueryVariables>;