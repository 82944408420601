import { tableCellClasses } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [`& .${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: theme.spacing(6)
      },
    },
    mobileRoot: {
      overflow: "auto"
    }
  })
);
