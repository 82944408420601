import { StripeStatCard } from "@components/StripeStatCard";
import { Block, Euro, HourglassTop, ShoppingCart } from "@mui/icons-material";
import { Box, CardContent, Divider, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface StripeTransfertStatsProps {
  periodicStats: any;
}

export const StripeTransfertStats: FC<StripeTransfertStatsProps> = ({
  periodicStats,
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ my: 3 }}>
      <Typography fontWeight="bold" variant="subtitle1">
        {t("dashboard.stripeTransfers")}
      </Typography>
      <Divider/>
      <Box sx={{ mt: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <StripeStatCard
              label={t("dashboard.total")}
              value={`€ ${
                periodicStats?.globalTransfertStats?.TOTAL?.current || 0
              }`}
              icon={
                <ShoppingCart
                  sx={{
                    textAlign: "center",
                    lineHeight: 50,
                    marginLeft: 15,
                    color: "#000",
                    position: "absolute",
                    right: -5,
                    top: { xs: 10, sm: 20 },
                    opacity: 0.1,
                    fontSize: { xs: 85, sm: 110 },
                  }}
                />
              }
              color="linear-gradient(to right, #373b44, #4286f4) !important"
              percentage={
                periodicStats?.globalTransfertStats?.TOTAL?.percentage || 0
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StripeStatCard
              label={t("dashboard.sent")}
              value={`€ ${
                periodicStats?.globalTransfertStats?.PAID?.current || 0
              }`}
              icon={
                <Euro
                  sx={{
                    textAlign: "center",
                    lineHeight: 50,
                    marginLeft: 15,
                    color: "#000",
                    position: "absolute",
                    right: -5,
                    top: { xs: 10, sm: 20 },
                    opacity: 0.1,
                    fontSize: { xs: 85, sm: 110 },
                  }}
                />
              }
              color="linear-gradient(to right, #0a504a, #38ef7d) !important"
              percentage={
                periodicStats?.globalTransfertStats?.PAID?.percentage || 0
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StripeStatCard
              label={t("dashboard.onHold")}
              value={`€ ${
                periodicStats?.globalTransfertStats?.PENDING?.current || 0
              }`}
              icon={
                <HourglassTop
                  sx={{
                    textAlign: "center",
                    lineHeight: 50,
                    marginLeft: 15,
                    color: "#000",
                    position: "absolute",
                    right: -5,
                    top: { xs: 10, sm: 20 },
                    opacity: 0.1,
                    fontSize: { xs: 85, sm: 110 },
                  }}
                />
              }
              color="linear-gradient(to right, #a86008, #ffba56) !important"
              percentage={
                periodicStats?.globalTransfertStats?.PENDING?.percentage || 0
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StripeStatCard
              label={t("dashboard.failure")}
              value={`€ ${
                periodicStats?.globalTransfertStats?.FAILED?.current || 0
              }`}
              icon={
                <Block
                  sx={{
                    textAlign: "center",
                    lineHeight: 50,
                    marginLeft: 15,
                    color: "#000",
                    position: "absolute",
                    right: -5,
                    top: { xs: 10, sm: 20 },
                    opacity: 0.1,
                    fontSize: { xs: 85, sm: 110 },
                  }}
                />
              }
              color="linear-gradient(to right, #493240, #ff0044) !important"
              percentage={
                periodicStats?.globalTransfertStats?.FAILED?.percentage || 0
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
