import { ConfirmDeleteModal } from "@components/ConfirmDeleteModal";
import { Delete } from "@mui/icons-material";
import { IconButton, MenuItem } from "@mui/material";
import React, { FC, useEffect, useState } from "react";

interface DeleteIconBtnProps {
  loading: boolean;
  deleted?: string | null;
  onDelete: () => void;
  disabled?: boolean;
  menuItemKey?: string;
}

const DeleteIconBtn: FC<DeleteIconBtnProps> = ({
  loading,
  onDelete,
  deleted,
  disabled,
  menuItemKey,
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const handleToggleOpenConfirmDialog = () => {
    setOpenConfirmDialog(!openConfirmDialog);
  };

  useEffect(() => {
    if (!loading && deleted) {
      setOpenConfirmDialog(false);
    }
  }, [loading, deleted]);

  return (
    <>
      {menuItemKey ? (
        <MenuItem
          key={`more-horiz-menu-item-${menuItemKey}`}
          onClick={handleToggleOpenConfirmDialog}
        >
          Supprimer
        </MenuItem>
      ) : (
        <IconButton onClick={handleToggleOpenConfirmDialog} disabled={disabled}>
          <Delete />
        </IconButton>
      )}
      <ConfirmDeleteModal
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        loading={loading}
        deleted={deleted}
        onDelete={onDelete}
      />
    </>
  );
};
export { DeleteIconBtn };
